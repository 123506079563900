import { PageLoginOut } from "bbw-common" ;

export const checkLoginFailed = async () => {
    const MessageBox = await require ( `element-ui/lib/message-box` ).default ;
    MessageBox.alert (
        `异地登录或登录过期，请重新登录`,
        `提示`,
        {
            confirmButtonText: `确定`,
            callback: async ( ) => {
                if ( this.auth ) {
                    // 如果需要认证，则再去获取一次是否登录成功，不成功就跳转到指定页面
                    const res = await this.$store.dispatch ( `UserInfo/FETCH_CHECK_BROWSER_UNIQUE`, { source: this.source } ) ;
                    console.dir ( res ) ;
                    if ( res.data?.status.toString() !== `200` ) {
                        console.log ( `检查失败onLoginFail` ) ;
                        this.$store.commit ( `UserInfo/resetUserInfo` ) ;
                        window.location.href = this.loginPath || PageLoginOut ;
                    } else {
                        window.location.reload () ;
                    }
                } else {
                    window.location.reload () ;
                }
            }
        } ) ;
} ;
