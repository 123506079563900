<template>
    <tool-tip v-bind="{ ...$attrs, ...exOptions }">
        <template slot="content">
            <slot name="content"></slot>
        </template>
        <div @mouseover="handleMouseEnter" @mouseout="handleMouseLeave">
            <slot></slot>
        </div>
    </tool-tip>
</template>
<script lang="ts">
const ToolTip = require(`element-ui/lib/tooltip`).default;
export default {
    name: `BbwToolTip`,
    components: {ToolTip},
    props: {
        /** 是否立即显示 */
        immediately: {
            type: Boolean,
            default: true
        },
//      是否常显
        alwaysShow: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            show: false
        };
    },
    mounted() {
        if (this.alwaysShow) {
            setTimeout(() => {
                this.show = true
            }, 10)
        }
    },
    activated() {
        if (this.alwaysShow) {
            this.show=false
            setTimeout(() => {
                this.show = true
            }, 10)
        }
    },
    computed: {
        exOptions() {
            if (this.immediately) {
                return {
                    transition: `bbw-immediately`,
                    manual: true,
                    value: this.show
                };
            } else {
                return {};
            }
        }
    },
    methods: {
        handleMouseEnter() {
            if (this.alwaysShow) return
            this.show = true;
        },
        handleMouseLeave() {
            if (this.alwaysShow) return
            this.show = false;
        }
    },
    // render ( h ) {
    //   return (
    //       <tool-tip {...{ attrs: { ...this.$attrs, ...this.exOptions } } } >
    //         <template slot="content">
    //           { this.$scopedSlots.content () }
    //         </template>
    //         <div onMouseover={ () => { this.handleMouseEnter () ; }} onMouseout={ () => { this.handleMouseLeave () ; }} >
    //           { this.$scopedSlots.default () }
    //         </div>
    //       </tool-tip>
    //   ) ;
    // }
};
</script>