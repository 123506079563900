<template>
  <div :class="[$style['header-vip-tips'], ani ]" v-if="$store.state.Message.reserve1">
    {{ $store.state.Message.reserve1 }}
  </div>
</template>
<script>
  export default {
    name: "HeaderVipTips",
    mounted() {
        this.timmer = window.setInterval( ()=> {
          this.ani = '';
          window.setTimeout(()=>{
            // this.ani = this.$style['ani-shakeX'];
            this.ani = this.$style['ani-scale'];
          },100)
        }, this.interval );
    },
    destroyed() {
      window.clearInterval( this.timer );
    },
    data(){
      return {
        interval: 5000,
        timer:null,
        ani:'',
      }
    }
  }
</script>
<style lang="scss" module src="./HeaderVipTips.scss"></style>
