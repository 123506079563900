var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.userTypeBox] }, [
    _c("div", { class: [_vm.$style.dropdownMain, "dropdownMain"] }, [
      _c(
        "div",
        {
          class: [_vm.$style["top-content"]],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.buyVip.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { class: _vm.$style["activityLogo"] }),
          _vm._v(" "),
          _c("div", { class: _vm.$style.usetype }, [
            _c("p", {
              staticClass: "font-medium",
              domProps: { innerHTML: _vm._s(_vm.vipName) },
            }),
            _vm._v(" "),
            !_vm.userInfo.personalVipDays
              ? _c(
                  "p",
                  {
                    class: [
                      _vm.$style.smallTip,
                      _vm.userInfo.personalVipDays
                        ? _vm.$style.smallTip_team
                        : "",
                      "font-regular",
                    ],
                  },
                  [
                    _vm._v("您的大作团队VIP特权："),
                    _c("span", { class: _vm.$style.blueFont }, [
                      _vm._v(_vm._s(_vm.maxTeamInfo.vip_day)),
                    ]),
                    _vm._v(" 天"),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.userInfo.personalVipDays
              ? _c(
                  "p",
                  {
                    class: [
                      _vm.$style.smallTip,
                      _vm.userInfo.personalVipDays
                        ? _vm.$style.smallTip_team
                        : "",
                      "font-regular",
                    ],
                  },
                  [
                    _vm._v("您的团队版大作VIP特权："),
                    _c("span", { class: _vm.$style.blueFont }, [
                      _vm._v(_vm._s(_vm.maxTeamInfo.vip_day)),
                    ]),
                    _vm._v(" 天"),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.userInfo.personalVipDays
              ? _c(
                  "div",
                  { class: [_vm.$style.smallTip_pesonal, "font-regular"] },
                  [
                    _c("div", { class: _vm.$style.team_tips }, [
                      _vm._v(
                        "\n              个人版大作VIP特权：" +
                          _vm._s(_vm.userInfo.personalVipDays) +
                          " 天\n              "
                      ),
                      _c(
                        "div",
                        { class: _vm.$style.vip_help_box },
                        [
                          _c(
                            "ToolTip",
                            {
                              class: [_vm.$style.vip_help],
                              attrs: {
                                placement: "bottom",
                                "popper-class": "viptooltip",
                                enterable: false,
                                transition: "bbw-immediately",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: ["font-medium"],
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c(
                                    "span",
                                    { class: [_vm.$style.downVipTip] },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "团队VIP生效后，个人VIP天数自动冻结,"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v("不再消耗个人VIP剩余天数。"),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "团队VIP失效后，将自动恢复个人VIP。"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { class: _vm.$style.help_icon }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("button", [
            _c("p", { staticClass: "font-medium" }, [
              _vm._v("\n            续费团队VIP\n            "),
              _vm.maxTeamInfo.vip_day <= 60
                ? _c("a", [_vm._v("即将到期")])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.$style["bottom-content"],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.buyVip.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { class: [_vm.$style.title, "font-regular"] }, [
            _c("p", { staticClass: "font-medium" }, [
              _vm._v("团队VIP尊享特权"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.wrapList }, [
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg1] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("快捷协作")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("成员一键加入协作"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg2] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("实时共享")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("协作图片集图片"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg3] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("讨论、投票")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("高效选方案、定设计"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg4] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("资料保障")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("图片集归属团队"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg5] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("无限高速下载")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("专属宽带下载原图"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg6] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("赠送")]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.small_text }, [_vm._v("原生网站")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("免工具Pinterest搜图"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg7] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("赠送")]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.small_text }, [_vm._v("原生网站")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("同等天数"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg8] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("28项特权")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("成员VIP尊享"),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { class: [_vm.$style.more, "font-regular"] }, [
        _c(
          "a",
          {
            attrs: { href: _vm.PageVersionDiff + "?type=2", target: "_blank" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.goTo.apply(null, arguments)
              },
            },
          },
          [_vm._v("查看团队VIP所有特权 >")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }