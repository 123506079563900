var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name: _vm.isCenter ? "fadeupCenter" : "fadeup",
        "leave-to-class": _vm.leaveToType ? _vm.leaveToType : "fadeup-leave-to",
      },
    },
    [
      _vm.isShow
        ? _c(
            "div",
            {
              staticClass: "hitbox",
              class: _vm.theme,
              style: _vm.cursor,
              on: {
                click: function ($event) {
                  return _vm.openNew(_vm.PropsData)
                },
                mouseleave: function ($event) {
                  return _vm.mouseleave()
                },
                mouseenter: _vm.mouseenter,
              },
            },
            [
              _vm.image
                ? _c("span", { staticClass: "collection-tip-title" }, [
                    _c("img", { attrs: { src: _vm.url } }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(_vm.child, {
                tag: "component",
                attrs: { PropsData: _vm.PropsData },
              }),
              _vm._v(" "),
              _vm.content
                ? _c("div", {
                    staticClass: "collection-tip-content",
                    domProps: { innerHTML: _vm._s(_vm.content) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.PropsData.isshow
                ? _c("div", [
                    _c("div", { staticClass: "collection-tip-content" }, [
                      _vm._v(
                        _vm._s(
                          _vm.PropsData.title || "已成功收藏到我的设计云"
                        ) + "\n                "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "name toolong",
                          class: _vm.$style.hoverUnderline,
                        },
                        [_vm._v(" " + _vm._s(_vm.PropsData.board.title) + " ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }