<template>
    <a @click="userClick" :class="['bbw-user-area',styleName, themeColor]" target="_blank" :href="dz">
        <img class="avatar" alt="头像" :src="avaterImg"/>
        <span :class="['desc', 'font-medium']">我的设计云</span>
        <GuideCircle v-if="isShowUserGui" :class="$style.guide_box" :options="userGuideOptions"></GuideCircle>
    </a>
</template>
<script lang="ts">
    import {PageSearchEngineNew, toRelativeProtocol, BigBigWork, getAuthUrl,emptyImg} from "bbw-common";
    import GuideCircle from '../GuideCircle'
    export default {
        name: "cHeaderUserArea",
        components:{GuideCircle},
        props: {
            /** 样式类型(small | normal) */
            type: {
                type: String,
                required: false,
                default: 'normal'
            },
            /**主题色*/
            themeColor: {
                type: String,
                required: false,
                default: 'grey'
            },
            /** 是否显示新手引导*0*/
            isShowUserGui:{
                type: Boolean,
                required:false,
                default:false
            },
            /** 是否要通过auth页面登录跳转 */
            auth: {
              type: Boolean,
              required:false,
              default:false
            }
        },
        data: function () {
            return {
                userGuideOptions:{
                    showGuide: true,
                    showGuideWord: true,
                    tipWord: '您刚刚收藏的图片<br>保存在这里啦',
                },
                changeNum:1
            }
        },
        methods:{
            userClick(){
                this.$emit('userClick')
            }
        },
        computed: {
            /** 我的设计云页面 */
            dz: function () {
                if(!this.$store.getters["UserInfo/userReady"]){ return ""; }
                const url = `${PageSearchEngineNew}${this.$store.state.UserInfo.id}/sy`;
                if( this.auth ){
                  return getAuthUrl(this.$store, url );
                }else{
                  return url;
                }
            },
            /** 根据props.type决定样式名 */
            styleName: function () {
                let custom = "";
                switch (this.type) {
                    case 'xs'  :
                        custom = 'userAreaXS';
                        break;
                    case 'small' :
                        custom = 'userAreaS';
                        break;
                    case 'normal' :
                        custom = 'userAreaM';
                        break;
                    default:
                        custom = 'userAreaM';
                }
                return ['userArea', custom];
            },
            avaterImg() {
                if(!this.$store.getters["UserInfo/userReady"]) return emptyImg;
                return toRelativeProtocol(this.$store.state.UserInfo.avatar)
            },
        }
    };
</script>
<!--<style lang=scss module src="./HeaderUserArea.scss"></style>-->
