var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-vipBlock" }, [
    _vm.isVip
      ? _c(
          "div",
          { class: ["vip", "bk"] },
          [
            _c("img", {
              staticClass: "vipIcon",
              attrs: {
                src: require("../../../assets/images/icon_vip3.png"),
                alt: "VIP",
              },
            }),
            _vm._v(" "),
            _vm._t("default"),
            _vm._v(" "),
            _vm._t("tips"),
            _vm._v(" "),
            _c("div", { staticClass: "con" }, [
              _c(
                "svg",
                {
                  class: ["font", "fontVip"],
                  attrs: {
                    width: "26",
                    height: "12",
                    viewBox: "0 0 26 12",
                    xmlns: "http://www.w3.org/2000/svg",
                    "svg-inline": "",
                    role: "presentation",
                    focusable: "false",
                    tabindex: "-1",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M3.726 12L0 0h2.555l1.616 5.838c.38 1.33.627 2.53 1.022 3.876h.083c.379-1.346.66-2.546 1.039-3.876L7.897 0h2.473L6.628 12H3.726zm8.046 0V0h2.44v12h-2.44zm5.44 0V0h4.007C23.923 0 26 .924 26 3.762c0 2.74-2.094 3.973-4.715 3.973h-1.632V12h-2.44zm2.44-6.162h1.468c1.682 0 2.49-.697 2.49-2.076 0-1.394-.89-1.865-2.573-1.865h-1.384v3.94z",
                      "fill-rule": "nonzero",
                    },
                  }),
                ]
              ),
            ]),
          ],
          2
        )
      : _c(
          "div",
          { class: ["noVip", "bk"] },
          [
            _c("div", { class: ["vipIcon", "red"] }, [
              _c("img", {
                attrs: {
                  src: require("../../../assets/images/icon_vip3.png"),
                  alt: "非VIP",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { class: ["vipIcon", "gray"] }, [
              _c("img", {
                attrs: {
                  src: require("../../../assets/images/icon_novip3.png"),
                  alt: "非VIP",
                },
              }),
            ]),
            _vm._v(" "),
            _vm._t("default"),
            _vm._v(" "),
            _vm._t("tips"),
            _vm._v(" "),
            _c("div", { staticClass: "con" }, [
              _c(
                "svg",
                {
                  class: ["font", "fontChengwei"],
                  attrs: {
                    width: "26",
                    height: "13",
                    viewBox: "0 0 26 13",
                    xmlns: "http://www.w3.org/2000/svg",
                    "svg-inline": "",
                    role: "presentation",
                    focusable: "false",
                    tabindex: "-1",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M10.447 4.465l1.642.395c-.566 1.77-1.38 3.348-2.374 4.669.4.98.842 1.552 1.284 1.552.331 0 .497-.681.566-2.383.372.382.952.736 1.394.912-.263 2.519-.815 3.118-2.098 3.118-.966 0-1.725-.68-2.332-1.797a11.67 11.67 0 01-2.333 1.851 10.717 10.717 0 00-1.186-1.334 10.52 10.52 0 002.815-2.205c-.47-1.538-.773-3.444-.952-5.486H2.87v1.457h3.41s-.015.422-.015.612c-.041 2.859-.124 4.07-.441 4.465-.262.354-.538.477-.952.545-.36.054-.953.054-1.587.04-.028-.476-.208-1.102-.456-1.51.497.04.966.054 1.187.054.193 0 .304-.027.414-.163.138-.191.193-.844.235-2.56H2.87C2.815 8.685 2.525 11.34 1.394 13c-.29-.313-1.021-.912-1.394-1.103 1.049-1.633 1.145-4.002 1.145-5.771V2.15h5.631A48.423 48.423 0 016.721.136h1.725c-.014.68 0 1.348.027 2.015h2.112c-.51-.409-1.2-.844-1.767-1.185L9.812 0c.76.381 1.767 1.007 2.25 1.457l-.663.694h1.435v1.606H8.57c.124 1.361.317 2.64.552 3.73.538-.912.994-1.92 1.325-3.022zM18.12 2.49l-1.518.626c-.22-.544-.8-1.443-1.284-2.082l1.436-.586c.51.613 1.118 1.457 1.366 2.042zm2.263.79H26s-.014.558-.028.776c-.179 5.227-.358 7.187-.897 7.854-.386.504-.786.667-1.366.749-.524.081-1.38.068-2.208.04-.028-.503-.262-1.225-.566-1.701.842.068 1.629.068 2.001.068.276 0 .428-.04.594-.218.372-.38.552-2.055.69-5.921h-4.002c-.387 2.6-1.532 5.54-4.79 7.65-.261-.368-.896-.94-1.31-1.198 2.884-1.783 3.974-4.233 4.388-6.452h-4.043V3.28H18.7c.027-.463.041-.899.041-1.307V.136h1.67v1.851c0 .409-.014.83-.028 1.294zm2.747 5.39l-1.477.722c-.29-.695-.98-1.77-1.587-2.573l1.38-.626c.62.748 1.352 1.797 1.684 2.477z",
                      "fill-rule": "nonzero",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "svg",
                {
                  class: ["font", "fontVip"],
                  attrs: {
                    width: "26",
                    height: "12",
                    viewBox: "0 0 26 12",
                    xmlns: "http://www.w3.org/2000/svg",
                    "svg-inline": "",
                    role: "presentation",
                    focusable: "false",
                    tabindex: "-1",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M3.726 12L0 0h2.555l1.616 5.838c.38 1.33.627 2.53 1.022 3.876h.083c.379-1.346.66-2.546 1.039-3.876L7.897 0h2.473L6.628 12H3.726zm8.046 0V0h2.44v12h-2.44zm5.44 0V0h4.007C23.923 0 26 .924 26 3.762c0 2.74-2.094 3.973-4.715 3.973h-1.632V12h-2.44zm2.44-6.162h1.468c1.682 0 2.49-.697 2.49-2.076 0-1.394-.89-1.865-2.573-1.865h-1.384v3.94z",
                      "fill-rule": "nonzero",
                    },
                  }),
                ]
              ),
            ]),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }