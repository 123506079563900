var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.cpuIsShow,
        "append-to-body": true,
        "custom-class": _vm.$style.dialogBehance,
        width: "520px",
        top: "0",
      },
      on: { close: _vm.handleClose, open: _vm.onOpen },
    },
    [
      _c("div", { class: _vm.$style.ToBehance }, [
        _vm.hasBindPhone
          ? _c("div", [
              _c("div", { class: _vm.$style["icon-box"] }, [
                _c("img", {
                  class: _vm.$style["pinterest-logo"],
                  attrs: {
                    src: require("../../../assets/images/tobehance.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  class: _vm.$style["pinterest-word"],
                  attrs: {
                    src: require("../../../assets/images/behance-logo.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("span", { class: _vm.$style.Versionfont }, [
                _vm._v("请登录大作客户端后，点击下方按钮"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: _vm.$style.Versionbuttonbox,
                  staticStyle: { "padding-top": "29px" },
                },
                [
                  _c(
                    "a",
                    {
                      class: [_vm.$style.Versionbutton, _vm.$style.IsOk],
                      staticStyle: {
                        display: "inline-block",
                        width: "200px",
                        height: "50px",
                        "line-height": "50px",
                      },
                      attrs: { target: "_blank" },
                      on: { click: _vm.gotoPage },
                    },
                    [_vm._v("打开Behance")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: _vm.$style.gb,
                      staticStyle: {
                        "font-size": "12px",
                        color: "#AAAAAA",
                        "line-height": "12px",
                        "margin-top": "12px",
                        "text-align": "center",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    关闭或退出大作将无法访问Behance\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        color: "#7f8c8d",
                        "font-size": "12px",
                        "line-height": "30px",
                      },
                    },
                    [
                      _vm._v("Behance使用特权（剩余"),
                      _c(
                        "span",
                        {
                          class: _vm.$style.pinusedays,
                          staticStyle: { color: "#fe4365" },
                        },
                        [_vm._v(_vm._s(_vm.pinterestDays))]
                      ),
                      _c("span", [_vm._v("天")]),
                      _vm._v("）\n                "),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: _vm.$style["loading_button"],
                  staticStyle: {
                    width: "97px",
                    height: "30px",
                    background: "rgba(240,52,91,1)",
                    margin: "29px auto 0",
                    "border-radius": "5px",
                    display: "none",
                  },
                },
                [
                  _c("img", {
                    class: _vm.$style.loading_run,
                    staticStyle: { width: "18px", height: "18px" },
                    attrs: {
                      src:
                        _vm.process.env.CDN_WIMG_PATH +
                        "/p/images/pinterest_loading.png",
                      alt: "loading",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticStyle: { color: "#fff", "line-height": "30px" } },
                    [_vm._v("正在开启...")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style["down-word"] }, [
                _c(
                  "a",
                  {
                    staticClass: "fl",
                    attrs: {
                      href:
                        _vm.process.env.CDN_WIMG_PATH +
                        "/client/DZClientSetup-x64.zip",
                    },
                  },
                  [_vm._v("下载 Windows 客户端")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fr",
                    attrs: {
                      href:
                        _vm.process.env.CDN_WIMG_PATH +
                        "/client/DZClientSetup-mac.zip",
                    },
                  },
                  [_vm._v("下载 Mac 客户端")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.hasBindPhone
          ? _c("div", [
              _c("div", { class: _vm.$style["icon-box"] }, [
                _c("img", {
                  class: _vm.$style["pinterest-logo"],
                  attrs: {
                    src: require("../../../assets/images/tobehance.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  class: _vm.$style["pinterest-word"],
                  attrs: {
                    src: require("../../../assets/images/behance-logo.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  class: _vm.$style.hoverbd,
                  staticStyle: {
                    "text-align": "center",
                    "padding-top": "9px",
                    "margin-top": "46px",
                    "font-size": "18px",
                  },
                },
                [
                  _vm._v(
                    "\n                绑定手机号才能打开Behance哦~\n                "
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { "text-decoration": "underline" },
                      attrs: { href: _vm.PageCenter },
                    },
                    [_vm._v("现在去绑定")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: _vm.$style.Versionbuttonbox,
                  staticStyle: { "padding-top": "29px" },
                },
                [
                  _c(
                    "a",
                    {
                      class: [_vm.$style.Versionbuttons, _vm.$style.radius],
                      attrs: { disabled: "" },
                    },
                    [_vm._v("打开Behance")]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }