var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.short ? "navBarR_short" : "",
        "navBarR",
        "headerNavRightItems",
      ],
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.showList.includes("pinliteBackToSearch")
        ? _c(
            "div",
            { class: _vm.$style.cHeaderLi },
            [
              _c(
                "cHeaderNavItem",
                { attrs: { type: "xs", href: _vm.homeLink, name: "bbwHome" } },
                [_vm._v("返回大作首页")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("pinliteBackToSearch")
        ? _c(
            "div",
            { class: [_vm.$style.cHeaderLi, _vm.$style.ai_tool] },
            [
              _c(
                "cHeaderNavItem",
                {
                  attrs: { type: "xs", href: _vm.aiLink, name: "bbwHome" },
                  on: {
                    click: function ($event) {
                      return _vm.statsAi("pinlite导航栏")
                    },
                  },
                },
                [_vm._v("AI绘画")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("industrySearch")
        ? _c(
            "div",
            { class: _vm.$style.cHeaderLi },
            [
              _c(
                "cHeaderNavItem",
                {
                  attrs: { type: "xs", href: _vm.industryLink },
                  on: {
                    click: function ($event) {
                      return _vm.stats("大作", "pl")
                    },
                  },
                },
                [_vm._v("\n                分类搜索")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("metaSearch")
        ? _c(
            "div",
            { class: _vm.$style.cHeaderLi },
            [
              _c(
                "cHeaderNavItem",
                {
                  attrs: { type: "xs", href: _vm.metaLink },
                  on: {
                    click: function ($event) {
                      return _vm.stats("聚合", "pl")
                    },
                  },
                },
                [_vm._v("全球网站聚合")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("backToSearch")
        ? _c(
            "div",
            { class: _vm.$style.cHeaderLi },
            [
              _c(
                "cHeaderNavItem",
                { attrs: { type: "xs", href: _vm.PageHome, name: "bbwHome" } },
                [_vm._v("返回大作")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("search")
        ? _c(
            "div",
            { class: "cHeaderLi" },
            [
              _c(
                "cHeaderNavItem",
                {
                  attrs: { type: "xs", href: _vm.PageSearchEngine },
                  on: {
                    click: function ($event) {
                      return _vm.stats("大作", "dt")
                    },
                  },
                },
                [_vm._v("搜灵感\n            ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("tuyi")
        ? _c(
            "div",
            { class: "cHeaderLi" },
            [
              _c(
                "cHeaderNavItem",
                {
                  attrs: { type: "xs", href: _vm.PageTuYiEngine },
                  on: {
                    click: function ($event) {
                      return _vm.stats("图意", "dt")
                    },
                  },
                },
                [_vm._v("图意搜索\n            ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("pinlite")
        ? _c(
            "div",
            { class: ["cHeaderLi"] },
            [
              _c(
                "cHeaderNavItem",
                {
                  attrs: { capture: true, type: "xs", href: _vm.pinLiteHref },
                  on: { click: _vm.toPinlite },
                },
                [_vm._v("\n                Pinlite极速版\n            ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("aiTool") &&
      (_vm.$route.name === "Home" ||
        _vm.$route.name === "TuYiSearchImgs" ||
        _vm.showAiTool) &&
      _vm.aiConfig
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.short,
                  expression: "!short",
                },
              ],
              class: [
                "cHeaderLi",
                "dropdownOuter",
                _vm.$style.fanli,
                _vm.$style.aiTool,
              ],
            },
            [
              _c(
                "cHeaderNavItem",
                {
                  staticClass: "onlyIconMax_1280",
                  attrs: {
                    themeColor: "navItem_grey150",
                    type: "xs",
                    "icon-type": "aiTool",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleAITool(_vm.aiConfig)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.aiConfig.show_name) + "\n            ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.short,
                  expression: "!short",
                },
              ],
              class: ["cHeaderLi", "dropdownOuter", _vm.$style.fanli],
              on: { mouseenter: _vm.extEnter },
            },
            [
              _c(
                "cHeaderNavItem",
                {
                  staticClass: "onlyIconMax_1280",
                  attrs: {
                    themeColor: "navItem_grey150",
                    type: "xs",
                    "icon-type": "caijiqi",
                  },
                  on: { click: _vm.handleExtClick },
                },
                [_vm._v("智能插件\n            ")]
              ),
              _vm._v(" "),
              !_vm.$store.state.server
                ? _c("header-ext", { staticClass: "dropdownWrapper" })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("tools")
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.short,
                  expression: "!short",
                },
              ],
              class: ["cHeaderLi", "dropdownOuter"],
            },
            [
              _c(
                "cHeaderNavItem",
                {
                  staticClass: "onlyIconMax_1280",
                  attrs: {
                    themeColor: "navItem_grey150",
                    type: "xs",
                    "icon-type": "tools",
                  },
                },
                [_vm._v("更多功能\n            ")]
              ),
              _vm._v(" "),
              !_vm.$store.state.server
                ? _c("cHeaderDropdownTools", {
                    staticClass: "dropdownWrapper",
                    attrs: { "tool-list": _vm.toolList },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("vip")
        ? _c(
            "div",
            {
              class: ["cHeaderLi", "dropdownOuter"],
              staticStyle: { "letter-spacing": "1px" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.buyVip.apply(null, arguments)
                },
                mouseenter: _vm.invitedLeave,
                mouseleave: _vm.invitedEnter,
                mousemove: _vm.invitedLeave,
              },
            },
            [
              _c(
                "VipBlock",
                { attrs: { "is-vip": _vm.$store.getters["UserInfo/isVip"] } },
                [_vm.showVipTips ? _c("header-vip-tips") : _vm._e()],
                1
              ),
              _vm._v(" "),
              _vm.showUser && _vm.showDropdownVip && !_vm.maxTeamInfo.vip_day
                ? _c("HeaderDropdownVip", {
                    staticClass: "dropdownWrapper",
                    attrs: {
                      isBBW: _vm.isBBW,
                      "from-text": _vm.statsVip.fromText,
                      "from-type": _vm.statsVip.fromType,
                      "from-text-privilege": _vm.statsVip.fromTextPrivilege,
                      "from-type-privilege": _vm.statsVip.fromTypePrivilege,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showUser && _vm.showDropdownVip && _vm.maxTeamInfo.vip_day
                ? _c("HeaderDropdownVipTeam", {
                    staticClass: "dropdownWrapper",
                    attrs: {
                      isBBW: _vm.isBBW,
                      "from-text": _vm.statsVip.fromText,
                      "from-type": _vm.statsVip.fromType,
                      "from-text-privilege": _vm.statsVip.fromTextPrivilege,
                      "from-type-privilege": _vm.statsVip.fromTypePrivilege,
                      maxTeamInfo: _vm.maxTeamInfo,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.$route.name === "Home" && _vm.showInvitedTips
                ? _c("cHeaderInvitedTips", {
                    attrs: { isFixed: _vm.isFixed },
                    nativeOn: {
                      mouseenter: function ($event) {
                        return _vm.invitedEnter.apply(null, arguments)
                      },
                      mouseleave: function ($event) {
                        return _vm.invitedLeave.apply(null, arguments)
                      },
                      click: function ($event) {
                        return _vm.toPayPage.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showList.includes("myCloud")
        ? _c(
            "div",
            {
              class: [
                "cHeaderLi",
                "dropdownOuter",
                _vm.personStatus === 1 ? "notifiHeaderLi" : "",
              ],
            },
            [
              _vm.total
                ? _c("div", { class: ["msg-total-count", "font-blod"] }, [
                    _c("span", [_vm._v(_vm._s(_vm.total))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("cHeaderUserArea", {
                attrs: { type: "xs", isShowUserGui: _vm.isShowUserGui },
                on: { userClick: _vm.userClick },
              }),
              _vm._v(" "),
              _vm.showUser && _vm.personStatus === 0
                ? _c("cHeaderDropdownUser", {
                    staticClass: "dropdownWrapper notifiDropdown",
                    attrs: {
                      auth: !_vm.isBBW,
                      "notifi-total": _vm.total,
                      redirect: _vm.PageSearchUnlogin,
                    },
                    on: {
                      userClick: _vm.userClick,
                      notification: function ($event) {
                        return _vm.changePersonMode(1)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showUser && _vm.personStatus === 1
                ? _c("cHeaderNotification", {
                    staticClass: "dropdownWrapper notifiDropdown",
                    attrs: {
                      auth: !_vm.isBBW,
                      msgShow: _vm.msgShow,
                      designUrl: _vm.designUrl,
                    },
                    on: {
                      updateUnread: _vm.updateUnread,
                      close: function ($event) {
                        return _vm.changePersonMode(0)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }