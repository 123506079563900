<style lang="scss" module src="./GuideCircle.scss"></style>
<template>
    <div :class="$style['circle-guide-box-V1']" class="font-medium" v-if="options.showGuide || false">
        <div :class="$style['guide-content-word-v1']" :style="{bottom:options.bottom?`${options.bottom}px`:'-57px'}" v-show="(options.showGuideWord && options.tipWord) || false" v-html="options.tipWord || ''"></div>
        <div v-if="!$store.state.server" :class="[$style['circle-guide-v1'], $style['SingleSiteSearch-circle-guide']]"
             :style="{width:options.width?`${options.width}px`:'56px',
             height:options.height?`${options.height}px`:'56px'
             }"
             v-circleposition="options.idName || ''">
        </div>
    </div>
</template>

<script lang=ts>
    export default {
        name: "GuideCircle",
        directives: {
            'circleposition': {
                inserted: function (el, binding) {
                    let parentElement = document.getElementById(binding.value);
                    if(parentElement){
                        let computedleft = el.offsetWidth - parentElement.offsetWidth
                        let computedTop = el.offsetHeight - parentElement.offsetHeight
                        let left = (el.offsetWidth - parentElement.offsetWidth) / 2 + parentElement.clientLeft;
                        let top = (el.offsetHeight - parentElement.offsetHeight) / 2 + parentElement.clientTop;
                        if (computedleft > 0) {
                            left = -left
                        } else {
                            left = Math.abs(left)
                        }
                        if (computedTop > 0) {
                            top = -top
                        } else {
                            top = Math.abs(top)
                        }
                        el.style.left = `${left}px`;
                        el.style.top = `${top}px`
                    }
                }
            }
        },
        props: {
            options: {
                type: Object,
                data: () => {
                }
            },
            tipWord: {
                type: String,
            },
            showGuide: {
                type: Boolean,
                default: false
            },
            showGuideWord: {
                type: Boolean,
                default: false
            },
            idName: {
                type: String,
            }
        },
        data: function () {
            return {}
        }
    }
</script>
