<template>
  <div :class="[$style.userTypeBox]">
    <div :class="[$style.dropdownMain,'dropdownMain']">
      <div :class="[$style['top-content']]" @click.stop="buyVip" >
        <!-- 活动logo图片 -->
        <div :class="$style['activityLogo']"> </div>
        <div :class="$style.usetype">
          <p class="font-medium" v-html="vipName"></p>
          <!--受邀用户提示-->
          <div v-if="showBeInvitedUserTips" :class="[$style.beInviteInfo, 'font-regular']">
            <p>您是受邀用户，在<span :class="$style.keyWord">{{beInvitedUserOverdue}}</span>前成为VIP</p>
            <p>额外加送您<span :class="$style.keyWord">1个月VIP</span></p>
          </div>
          <p v-if="!showBeInvitedUserTips && userInfo.userType!=6 && userInfo.userType!=7 && userInfo.vipDays ==0 " :class="[$style.smallTip, 'font-regular']">您还不是VIP特权用户</p>
          <p v-if="!showBeInvitedUserTips && userInfo.userType==6 && userInfo.vipDays==0||userInfo.userType==7&& userInfo.vipDays==0"
             :class="[$style.smallTip, 'font-regular']" >您的大作VIP特权已到期</p>
          <p v-if="!showBeInvitedUserTips && userInfo.vipDays>90" :class="[$style.smallTip, 'font-regular']">
            您的大作VIP特权：<span>{{userInfo.vipDays}}</span>天</p>
          <p v-if="!showBeInvitedUserTips && userInfo.vipDays>0&&userInfo.vipDays<=90"
             :class="[$style.smallTip, 'font-regular']">
            您的大作VIP特权：<span>{{userInfo.vipDays}}天后</span>到期</p>
          <p></p>
        </div>
        <button>
          <p class="font-medium">{{userInfo.userType==6||userInfo.userType==7 ? "续费VIP" : "开通VIP"}}
            <a  v-if="$store.state.Message.vipTips">{{ $store.state.Message.vipTips }}</a>
          </p>
        </button>
      </div>
      <div :class="$style['bottom-content']" @click.stop="buyVip">
        <div :class="[$style.title, 'font-regular']">
          <p class="font-medium">大作VIP尊享28项特权</p>
<!--          <p :class="$style.smalltitle">专属客服1对1支持</p>-->
        </div>
        <div :class="$style.wrapList">
            <div :class="$style.item">
                <div>
                    <span :class="[$style.bg,$style.bg2]">
<!--                      <span>AI</span>-->
                    </span>
                </div>
                <p class="font-bold">无限使用</p>
                <p :class="[$style.constr, 'font-regular']">AI赋能图意搜索</p>
            </div>
            <div :class="$style.item">
                <div>
                    <span :class="[$style.bg,$style.bg10]">
                      <span>AI</span>
                    </span>
                </div>
                <p class="font-bold">尊享点数</p>
                <p :class="[$style.constr, 'font-regular']">AI设计工作流模板库</p>
            </div>
            <div :class="$style.item">
                <div>
                    <span :class="[$style.bg,$style.bg5]">
                      <span>AI</span>
                    </span>
                </div>
                <p class="font-bold">无限使用</p>
                <p :class="[$style.constr, 'font-regular']">300万诗词匹配图片</p>
            </div>
            <div :class="$style.item">
                <div>
                    <span :class="[$style.bg,$style.bg6]">
                      <span>AI</span>
                    </span>
                </div>
                <p class="font-bold">无限下载</p>
                <p :class="[$style.constr, 'font-regular']">3000万免费版权原图</p>
            </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg1]"></span>
            </div>
            <p class="font-bold">网页版</p>
            <p :class="$style.small_text">极速版</p>
            <p :class="[$style.constr, 'font-regular']">使用Pinlite极速版</p>
          </div>

<!--          <div :class="$style.item">-->
<!--            <div>-->
<!--              <span :class="[$style.bg,$style.bg3]"></span>-->
<!--            </div>-->
<!--            <p class="font-bold">赠送</p>-->
<!--            <p :class="$style.small_text">原生网站</p>-->
<!--            <p :class="[$style.constr, 'font-regular']">同等天数</p>-->
<!--          </div>-->
<!--          <div :class="$style.item">-->
<!--            <div>-->
<!--              <span :class="[$style.bg,$style.bg4]"></span>-->
<!--            </div>-->
<!--            <p class="font-bold">赠送</p>-->
<!--            <p :class="$style.small_text">原生网站</p>-->
<!--            <p :class="[$style.constr, 'font-regular']">同等天数</p>-->
<!--          </div>-->
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg7]"></span>
            </div>
            <p class="font-bold">全功能使用</p>
            <p :class="[$style.constr, 'font-regular']">AI查找相似图</p>
          </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg8]"></span>
            </div>
            <p class="font-bold">无限使用</p>
            <p :class="[$style.constr, 'font-regular']">AI扩大图</p>
          </div>
            <div :class="$style.item">
                <div>
                    <span :class="[$style.bg,$style.bg9]"></span>
                </div>
                <p class="font-bold">无限收藏</p>
                <p :class="[$style.constr, 'font-regular']">Pinlite极速版</p>
            </div>
        </div>
      </div>
      <div :class="[$style.more,'font-regular']">
        <a @click.prevent.stop="goTo" :href="PageVersionDiff+'?type=2'" target="_blank"> 查看大作VIP特权 ></a>
      </div>
    </div>
  </div>
</template>
<script lang=ts>
    import { PageVersionDiff,beInvitedUserOverdue, buyVip } from "bbw-common";
    export default {
        name:"HeaderDropdownVip",
        props:{
            isBBW: {
              type: Boolean,
              required: false,
              default: true
            },
            /** VIP下拉付费来源文字 */
            fromText:{
                type:String,
                required: false,
                default: "vip下拉"
            },
            /** VIP下拉付费来源数字 */
            fromType:{
                type:Number,
                required: false,
                default: 6
            },
            /** VIP查特权付费来源文字 */
            fromTextPrivilege:{
                type:String,
                required: false,
                default: "特权对比页按钮"
            },
            /** VIP查特权来源数字 */
            fromTypePrivilege:{
                type:Number,
                required: false,
                default: 7
            }
        },
        data(){
            return {
                PageVersionDiff,
            }
        },
        mounted() {

        },
        methods: {
            buyVip: function () {
                buyVip("bbw", this.$store, this.fromText, this.fromType, !this.isBBW )
            },
            goTo:function () {
                buyVip("bbw", this.$store, this.fromTextPrivilege, this.fromTypePrivilege, !this.isBBW)
            }
        },
        computed: {
          /** 受邀用户到期时间 注册时间+7天+1天(最后一天不生效) */
          beInvitedUserOverdue() {
            return beInvitedUserOverdue(this.$store)
          },
          /** 是否显示受邀用户信息 */
          showBeInvitedUserTips() {
            return !this.$store.state.UserInfo?.isUserPay && this.$store.state.UserInfo?.isInviteExpiry
          },
            vipName(){
              // if ( !this.$store.state.UserInfo.isUserPay && this.$store.state.UserInfo.isInviteExpiry) {
              //   return ''
              // }
                if(this.$store.state.UserInfo.vipDays>61){
                  return "VIP用户"
                }else if(this.$store.state.UserInfo.vipDays<61 && this.$store.state.UserInfo.vipDays>0){
                  return "<span style='color:#FF4D4D'>VIP即将到期</span>"
                }else{
                  return '普通用户'
                }
                //return this.$store.state.UserInfo.vipDays>0 ? "VIP用户" : "普通用户";
            },
            btnStyle(){
                return [ "VIPbtn", this.$store.state.UserInfo.isUserPay ? 'btnTrans':'btnPink'];
            },
            userInfo(){
                return this.$store.state.UserInfo;
            }
        }
    };
</script>
<style src="./HeaderDropdownVip.scss" module></style>
