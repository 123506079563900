var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.userTypeBox] }, [
    _c("div", { class: [_vm.$style.dropdownMain, "dropdownMain"] }, [
      _c(
        "div",
        {
          class: [_vm.$style["top-content"]],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.buyVip.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { class: _vm.$style["activityLogo"] }),
          _vm._v(" "),
          _c("div", { class: _vm.$style.usetype }, [
            _c("p", {
              staticClass: "font-medium",
              domProps: { innerHTML: _vm._s(_vm.vipName) },
            }),
            _vm._v(" "),
            _vm.showBeInvitedUserTips
              ? _c(
                  "div",
                  { class: [_vm.$style.beInviteInfo, "font-regular"] },
                  [
                    _c("p", [
                      _vm._v("您是受邀用户，在"),
                      _c("span", { class: _vm.$style.keyWord }, [
                        _vm._v(_vm._s(_vm.beInvitedUserOverdue)),
                      ]),
                      _vm._v("前成为VIP"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("额外加送您"),
                      _c("span", { class: _vm.$style.keyWord }, [
                        _vm._v("1个月VIP"),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.showBeInvitedUserTips &&
            _vm.userInfo.userType != 6 &&
            _vm.userInfo.userType != 7 &&
            _vm.userInfo.vipDays == 0
              ? _c("p", { class: [_vm.$style.smallTip, "font-regular"] }, [
                  _vm._v("您还不是VIP特权用户"),
                ])
              : _vm._e(),
            _vm._v(" "),
            (!_vm.showBeInvitedUserTips &&
              _vm.userInfo.userType == 6 &&
              _vm.userInfo.vipDays == 0) ||
            (_vm.userInfo.userType == 7 && _vm.userInfo.vipDays == 0)
              ? _c("p", { class: [_vm.$style.smallTip, "font-regular"] }, [
                  _vm._v("您的大作VIP特权已到期"),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.showBeInvitedUserTips && _vm.userInfo.vipDays > 90
              ? _c("p", { class: [_vm.$style.smallTip, "font-regular"] }, [
                  _vm._v("\n            您的大作VIP特权："),
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.vipDays))]),
                  _vm._v("天"),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.showBeInvitedUserTips &&
            _vm.userInfo.vipDays > 0 &&
            _vm.userInfo.vipDays <= 90
              ? _c("p", { class: [_vm.$style.smallTip, "font-regular"] }, [
                  _vm._v("\n            您的大作VIP特权："),
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.vipDays) + "天后")]),
                  _vm._v("到期"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p"),
          ]),
          _vm._v(" "),
          _c("button", [
            _c("p", { staticClass: "font-medium" }, [
              _vm._v(
                _vm._s(
                  _vm.userInfo.userType == 6 || _vm.userInfo.userType == 7
                    ? "续费VIP"
                    : "开通VIP"
                ) + "\n            "
              ),
              _vm.$store.state.Message.vipTips
                ? _c("a", [_vm._v(_vm._s(_vm.$store.state.Message.vipTips))])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.$style["bottom-content"],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.buyVip.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { class: [_vm.$style.title, "font-regular"] }, [
            _c("p", { staticClass: "font-medium" }, [
              _vm._v("大作VIP尊享28项特权"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style.wrapList }, [
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg2] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("无限使用")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("AI赋能图意搜索"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg10] }, [
                  _c("span", [_vm._v("AI")]),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("尊享点数")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("AI设计工作流模板库"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg5] }, [
                  _c("span", [_vm._v("AI")]),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("无限使用")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("300万诗词匹配图片"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg6] }, [
                  _c("span", [_vm._v("AI")]),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("无限下载")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("3000万免费版权原图"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg1] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("网页版")]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.small_text }, [_vm._v("极速版")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("使用Pinlite极速版"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg7] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("全功能使用")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("AI查找相似图"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg8] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("无限使用")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("AI扩大图"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.item }, [
              _c("div", [
                _c("span", { class: [_vm.$style.bg, _vm.$style.bg9] }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "font-bold" }, [_vm._v("无限收藏")]),
              _vm._v(" "),
              _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                _vm._v("Pinlite极速版"),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { class: [_vm.$style.more, "font-regular"] }, [
        _c(
          "a",
          {
            attrs: { href: _vm.PageVersionDiff + "?type=2", target: "_blank" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.goTo.apply(null, arguments)
              },
            },
          },
          [_vm._v(" 查看大作VIP特权 >")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }