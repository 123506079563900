var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "wrapper",
      class: _vm.$style.SearchWrapper,
      style: _vm.headerStyle,
      on: {
        "!click": function ($event) {
          return _vm.handleClick.apply(null, arguments)
        },
        "!mousedown": function ($event) {
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { class: _vm.style },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "wrapper",
              class: _vm.$style.SearchArea,
              attrs: { id: "searchInput" },
            },
            [
              _c("div", { staticClass: "center" }, [
                _c("a", {
                  class: [
                    _vm.$style.pinLiteLogo,
                    _vm.isSemH ? _vm.$style.da_logo : "",
                  ],
                  attrs: { href: "/" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.toHome.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: [_vm.$style.toHome, _vm.$style.nav],
                    attrs: { href: "/" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.toHome.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("主页")]
                ),
                _vm._v(" "),
                _c("div", { class: _vm.$style.searchBlock }, [
                  !_vm.$store.server
                    ? _c(
                        "div",
                        {
                          class: [
                            _vm.$style.SearchInputWrapper,
                            _vm.searchActive && _vm.$style.active,
                          ],
                        },
                        [
                          _c("div", { class: _vm.$style.searchPlaceHolder }, [
                            _c(
                              "svg",
                              {
                                class: _vm.$style.icon,
                                attrs: {
                                  height: "20",
                                  width: "20",
                                  viewBox: "0 0 24 24",
                                  role: "img",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M10 16c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6m13.12 2.88l-4.26-4.26A9.842 9.842 0 0 0 20 10c0-5.52-4.48-10-10-10S0 4.48 0 10s4.48 10 10 10c1.67 0 3.24-.41 4.62-1.14l4.26 4.26a3 3 0 0 0 4.24 0 3 3 0 0 0 0-4.24",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.kw,
                                expression: "kw",
                              },
                            ],
                            ref: "kwInput",
                            class: [_vm.$style.kwInput, "font-medium"],
                            attrs: {
                              placeholder: _vm.inputPlaceholder,
                              maxlength: "40",
                            },
                            domProps: { value: _vm.kw },
                            on: {
                              mousedown: function ($event) {
                                $event.stopPropagation()
                                return _vm.enterInputMode.apply(null, arguments)
                              },
                              focus: _vm.focusInputMode,
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.kw = $event.target.value
                                },
                                _vm.enterInputMode,
                              ],
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onKeydown.apply(null, arguments)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.keyword.length
                            ? _c(
                                "a",
                                {
                                  class: _vm.$style.reset,
                                  on: { click: _vm.reset },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "gUZ pBj U9O kVc",
                                      attrs: {
                                        height: "16",
                                        width: "16",
                                        viewBox: "0 0 24 24",
                                        "aria-hidden": "true",
                                        "aria-label": "",
                                        role: "img",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M15.18 12l7.16-7.16c.88-.88.88-2.3 0-3.18-.88-.88-2.3-.88-3.18 0L12 8.82 4.84 1.66c-.88-.88-2.3-.88-3.18 0-.88.88-.88 2.3 0 3.18L8.82 12l-7.16 7.16c-.88.88-.88 2.3 0 3.18.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66L12 15.18l7.16 7.16c.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66.88-.88.88-2.3 0-3.18L15.18 12z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { class: _vm.$style.keyword }, [
                            _vm._v(_vm._s(_vm.kw)),
                          ]),
                          _vm._v(" "),
                          _vm.translatedWords.length
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.searchActive,
                                      expression: "!searchActive",
                                    },
                                  ],
                                  class: _vm.$style.translateLabel,
                                },
                                [_vm._v("用英文搜索：")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.translatedWords, function (item, index) {
                            return _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.searchActive,
                                    expression: "!searchActive",
                                  },
                                ],
                                key: index,
                                class: _vm.$style.translateWord,
                                attrs: {
                                  to: { path: "/tupian", query: { kw: item } },
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      mousedown: function ($event) {
                                        return _vm.handleTranslateClick(item)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item))]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.searchActive,
                          expression: "searchActive",
                        },
                      ],
                      class: [_vm.$style.dropDown],
                      on: {
                        mousedown: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleWrapper.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.kw.length
                        ? _c("SearchHistory", {
                            attrs: { words: _vm.SearchHistory },
                            on: {
                              changeHistory: _vm.handleHistoryChange,
                              isHistory: _vm.isHistory,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.kw.length
                        ? _c("SearchHotWords", {
                            attrs: {
                              isHistoryVal: _vm.isHistoryVal,
                              keyword: _vm.kw,
                            },
                            on: { hotWordsList: _vm.hotWordsList },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.kw.length &&
                      (_vm.recommended.length || _vm.recommendBoardsList.length)
                        ? _c("SearchRecommended", {
                            attrs: {
                              keyword: _vm.kw,
                              "search-word": _vm.recommendSearchWord,
                              recommendBoardsList: _vm.recommendBoardsList,
                              "recommended-words": _vm.recommended,
                            },
                            on: {
                              disableSearch: _vm.disableSearch,
                              changeKeyword: _vm.handleChangeKeyword,
                              onKeywordChange: _vm.onKeywordChange,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.isShowButton("pl_search_p")
                  ? _c(
                      "div",
                      {
                        class: _vm.$style.btnBox,
                        style: { "margin-left": "32px" },
                        attrs: { id: "p-button" },
                      },
                      [
                        _c("ButtonMask", {
                          attrs: {
                            icon: _vm.siteListOps.pinSrc,
                            "hover-icon": _vm.siteListOps.pinSrcHover,
                            mask: false,
                          },
                          on: { onBtnCli: _vm.pinCli },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (
                  _vm.loginState === undefined
                    ? _vm.$store.state.token
                    : _vm.loginState
                )
                  ? _c("div", { class: [_vm.$style.advice, "dropdownOuter"] }, [
                      _c("span", { class: _vm.$style.text }, [
                        _vm._v("\n            反馈建议\n          "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: ["dropdownWrapper", _vm.$style.qrcodeWrapper],
                        },
                        [
                          _c(
                            "div",
                            { class: [_vm.$style.qrcode, "dropdownMain"] },
                            [
                              _c("img", { attrs: { src: _vm.ServiceQR } }),
                              _vm._v(" "),
                              _c("span", [_vm._v("扫码添加客服")]),
                              _vm._v(" "),
                              _c("p", [_vm._v("有任何意见请")]),
                              _vm._v(" "),
                              _c("p", [_vm._v("随时联系我们")]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !(_vm.loginState === undefined
                  ? _vm.$store.state.token
                  : _vm.loginState)
                  ? _c(
                      "a",
                      {
                        class: [
                          _vm.$style.btn,
                          _vm.$style.register,
                          "font-bold",
                        ],
                        attrs: { href: _vm.registerUrl },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goPinliteRegister(
                              _vm.loginRedirect ||
                                _vm.$store.state.route.fullPath
                            )
                          },
                        },
                      },
                      [_vm._v("注册")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !(_vm.loginState === undefined
                  ? _vm.$store.state.token
                  : _vm.loginState)
                  ? _c(
                      "a",
                      {
                        class: [_vm.$style.btn, _vm.$style.login, "font-bold"],
                        attrs: { href: _vm.loginUrl },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goPinliteLogin(
                              _vm.loginRedirect ||
                                _vm.$store.state.route.fullPath
                            )
                          },
                        },
                      },
                      [_vm._v("登录")]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.searchActive ? _c("div", { class: _vm.$style.mask }) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }