var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.menu, _vm.styleName] }, [
    _c(
      "ul",
      { staticClass: "dropdownMain", class: [_vm.$style.menu, "font-regular"] },
      [
        _c("li", { class: _vm.$style.menuItem }, [
          _c(
            "a",
            {
              attrs: { href: _vm.dz, target: "_blank" },
              on: { click: _vm.userClick },
            },
            [
              _c("span", { class: [_vm.$style.icon, _vm.$style.hasBorder] }, [
                _c("img", { attrs: { src: _vm.avatar, alt: "" } }),
              ]),
              _vm._v("\n                    我的设计云"),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", { class: _vm.$style.menuItem }, [
          _c(
            "a",
            {
              attrs: { href: _vm.invite, target: "_blank" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleNotifi.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                class: [_vm.$style.icon, _vm.$style.invite_icon, _vm.$style.xx],
              }),
              _vm._v("\n                    消息\n                    "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.notifiTotal,
                      expression: "notifiTotal",
                    },
                  ],
                  class: _vm.$style.notifiNum,
                },
                [_vm._v(_vm._s(_vm.notifiTotal))]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("li", { class: _vm.$style.menuItem }, [
          _c("a", { attrs: { href: _vm.invite, target: "_blank" } }, [
            _c("span", {
              class: [_vm.$style.icon, _vm.$style.invite_icon, _vm.$style.yq],
            }),
            _vm._v("\n              邀好友赚现金\n            "),
          ]),
        ]),
        _vm._v(" "),
        _c("li", { class: _vm.$style.menuItem }, [
          _c("a", { attrs: { href: _vm.setting, target: "_blank" } }, [
            _c("span", { class: [_vm.$style.icon, _vm.$style.sz] }),
            _vm._v("\n                    个人设置"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", { class: _vm.$style.menuItem }, [
          _c("a", { attrs: { href: _vm.designer, target: "_blank" } }, [
            _c("span", { class: [_vm.$style.icon, _vm.$style.rz] }),
            _vm._v("\n                    认证设计师"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", { class: _vm.$style.menuItem }, [
          _c("a", { attrs: { href: _vm.help, target: "_blank" } }, [
            _c("span", { class: [_vm.$style.icon, _vm.$style.bz] }),
            _vm._v("\n                    帮助中心"),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "li",
          { class: _vm.$style.menuItem, staticStyle: { height: "44px" } },
          [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.goLoginOut(_vm.redirect, _vm.url)
                  },
                },
              },
              [
                _c("span", {
                  class: [_vm.$style.icon, _vm.$style.tc],
                  staticStyle: { top: "50%" },
                }),
                _vm._v("\n                    退出大作"),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }