var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShowVipBox,
            "custom-class": _vm.cusClass,
            "close-on-click-modal": false,
            "append-to-body": true,
            width: "810px",
            top: "0",
          },
          on: {
            open: _vm.onOpen,
            opened: _vm.onOpened,
            close: _vm.handleClose,
          },
        },
        [
          _c("div", { ref: "dialogVip_new", class: _vm.$style.con }, [
            _c("img", {
              staticStyle: { display: "none" },
              attrs: {
                src: _vm.process.env.CDN_XCX_PATH + "/pimg/offertwo.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticStyle: { display: "none" },
              attrs: {
                src: _vm.process.env.CDN_XCX_PATH + "/pimg/offerone.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { class: [_vm.$style.vipcon, _vm.$style.cl] }, [
              _c("div", { class: [_vm.$style.VIPSelectBox, _vm.$style.fl] }, [
                _c("h3", {
                  class: [_vm.$style.VIPtip, "font-medium"],
                  domProps: { innerHTML: _vm._s(_vm.title) },
                }),
                _vm._v(" "),
                _c("p", { class: [_vm.$style.payTitDec, "font-medium"] }, [
                  _vm._v(
                    _vm._s(
                      _vm.priceManager.currentPrice &&
                        _vm.priceManager.currentPrice.sec_title
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: [_vm.$style["num-designer"], "font-regular" + ""] },
                  [
                    _vm._v("\n              - 已有"),
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.num)),
                    ]),
                    _vm._v("万设计师使用VIP服务 -\n            "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    class: [
                      _vm.$style.VIPSelect,
                      _vm.$style.select,
                      _vm.$style.cl,
                    ],
                    attrs: { id: "VIPSelect" },
                  },
                  _vm._l(
                    _vm.priceManager.priceList,
                    function (priceItem, priceIndex) {
                      return _c(
                        "li",
                        {
                          class: [
                            _vm.$style.item,
                            priceIndex === _vm.priceManager.currentIndex
                              ? _vm.$style.active
                              : "",
                            priceIndex == 1 ? _vm.$style.isReduction : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.changePrice(priceIndex)
                            },
                          },
                        },
                        [
                          _c("div", { class: _vm.$style.reduction }),
                          _vm._v(" "),
                          _c("span", { class: _vm.$style.viptime }, [
                            _c("span", {
                              class: [_vm.$style.oTime, "font-medium"],
                              domProps: {
                                innerHTML: _vm._s(priceItem.name_bigword),
                              },
                            }),
                            _vm._v(" "),
                            _c("span", {
                              class: [_vm.$style.addTime, "font-medium"],
                              domProps: {
                                innerHTML: _vm._s(priceItem.name_smallword),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { class: [_vm.$style.yuan, _vm.$style.active_p] },
                            [
                              _c(
                                "span",
                                { class: [_vm.$style.priceVal, "font-medium"] },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.parseNumber(
                                        priceItem.price.price * 0.01
                                      )
                                    ) + "\n                 "
                                  ),
                                  priceItem.price.smallword
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            _vm.$style.fuli,
                                            "font-medium",
                                            _vm.$style.dafuli,
                                          ],
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                priceItem.price.smallword
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { class: [_vm.$style.rmb, "font-regular"] },
                                    [_vm._v("￥")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          priceItem.activityPrice.price
                            ? _c(
                                "span",
                                {
                                  class: [
                                    _vm.$style.yuan,
                                    _vm.$style.original_p_t,
                                    _vm.handleIsFour(
                                      priceItem.activityPrice.price
                                    )
                                      ? _vm.$style.original_p_t_four
                                      : "",
                                    "font-regular",
                                  ],
                                },
                                [
                                  priceItem.activityPrice.smallword
                                    ? _c(
                                        "div",
                                        {
                                          class: [
                                            _vm.$style.fuli,
                                            "font-regular",
                                          ],
                                          style: {
                                            right: _vm.handleIsFour(
                                              priceItem.activityPrice.price
                                            )
                                              ? "-20px"
                                              : "-14px",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                priceItem.activityPrice
                                                  .smallword
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.$style["rmb-1"],
                                        "font-regular",
                                      ],
                                    },
                                    [_vm._v("￥")]
                                  ),
                                  _c("span", { class: _vm.$style.oPriceVal }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseNumber(
                                          priceItem.activityPrice.price * 0.01
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          priceItem.price.price !== priceItem.maxprice
                            ? _c(
                                "span",
                                {
                                  class: [
                                    _vm.$style.yuan,
                                    _vm.$style.original_p_b,
                                    _vm.handleIsFour(
                                      priceItem.activityPrice.price
                                    )
                                      ? _vm.$style.original_p_b_four
                                      : "",
                                    "font-regular",
                                  ],
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.$style["rmb-1"],
                                        "font-regular",
                                      ],
                                    },
                                    [_vm._v("￥")]
                                  ),
                                  _c("span", { class: _vm.$style.oPriceVal }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseNumber(
                                          priceItem.maxprice * 0.01
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      class: [
                                        _vm.$style.oPriceValone,
                                        "font-regular",
                                      ],
                                      style: {
                                        right: _vm.handleIsFour(
                                          priceItem.maxprice
                                        )
                                          ? "-16px"
                                          : "-10px",
                                      },
                                    },
                                    [_vm._v("原价")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class: [
                                _vm.$style.perdayPriceCont,
                                "font-regular",
                              ],
                            },
                            [
                              _vm._v("低至 "),
                              _c(
                                "i",
                                {
                                  staticClass: "font-bold",
                                  class: [_vm.$style.perdayPrice],
                                },
                                [_vm._v(_vm._s(priceItem.day_price_desc))]
                              ),
                              _vm._v(" 元 / 天"),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.payArea }, [
              _vm.priceManager.currentPrice
                ? _c("div", { class: _vm.$style.payContent }, [
                    _c("div", { class: [_vm.$style.wexinqr, "fl"] }, [
                      _c(
                        "div",
                        {
                          class: _vm.$style.refreshPay,
                          style: {
                            opacity:
                              _vm.isShowRefresh || _vm.wxrefreshover ? 1 : 0,
                          },
                          on: {
                            click: _vm.refresh,
                            mouseover: function ($event) {
                              _vm.wxrefreshover = true
                            },
                            mouseout: function ($event) {
                              _vm.wxrefreshover = false
                            },
                          },
                        },
                        [_vm._v("刷新\n              ")]
                      ),
                      _vm._v(" "),
                      _c("div", { class: _vm.$style.qrcode }, [
                        _c(
                          "div",
                          {
                            class: _vm.$style.payqrcode,
                            attrs: { id: "wx_qr" + _vm.curTime },
                          },
                          [_c("canvas", { ref: "wx_qrcode_canvas" })]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          class: [
                            _vm.$style["usewexin"],
                            _vm.$style["paytype"],
                            "font-regular",
                          ],
                        },
                        [
                          _c("i", {
                            class: [_vm.$style.payicon, _vm.$style.wxpay],
                          }),
                          _vm._v("微信支付\n              "),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("h3", [
                      _c(
                        "p",
                        { class: [_vm.$style["should_title"], "font-regular"] },
                        [_vm._v("折后仅需：")]
                      ),
                      _vm._v(" "),
                      _vm.priceManager.currentPrice.price
                        ? _c(
                            "span",
                            { class: [_vm.$style["payPrice"], "font-bold"] },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.parseNumber(
                                    _vm.priceManager.currentPrice.price.price *
                                      0.01
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { class: _vm.$style.should }, [_vm._v("元")]),
                      _vm._v(" "),
                      _vm.priceManager.currentPrice.maxprice
                        ? _c("span", { class: _vm.$style.maxPrice }, [
                            _c("span", [_vm._v("￥")]),
                            _vm._v(
                              _vm._s(
                                _vm.parseNumber(
                                  _vm.priceManager.currentPrice.maxprice * 0.01
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          class: [_vm.$style["infor_text1"], "font-regular"],
                          staticStyle: {
                            cursor: "pointer",
                            "margin-top": "27px",
                          },
                          on: { click: _vm.gotoHc },
                        },
                        [
                          _c("span", {}),
                          _vm._v(
                            "大作捐赠1元“火柴乡村教师公益”\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { class: [_vm.$style["infor_text2"], "font-regular"] },
                        [
                          _c("span", { staticStyle: {} }),
                          _vm._v("支持无理由退款(48h到账)\n                "),
                          _c("a", {
                            attrs: {
                              href: _vm.PageAbout + "#tab03",
                              target: "_blank",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { class: [_vm.$style.infor_text3, "font-regular"] },
                        [_c("span", {}), _vm._v("付款后可提供发票")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { class: [_vm.$style.alipayqr, "fr"] }, [
                      _c(
                        "div",
                        {
                          class: _vm.$style.refreshPay,
                          style: {
                            opacity:
                              _vm.isShowRefresh || _vm.alirefreshover ? 1 : 0,
                          },
                          on: {
                            click: _vm.refresh,
                            mouseover: function ($event) {
                              _vm.alirefreshover = true
                            },
                            mouseout: function ($event) {
                              _vm.alirefreshover = false
                            },
                          },
                        },
                        [_vm._v("刷新\n              ")]
                      ),
                      _vm._v(" "),
                      _c("div", { class: _vm.$style.qrcode }, [
                        _c(
                          "div",
                          {
                            class: _vm.$style.payqrcode,
                            attrs: { id: "zfb_qr" + _vm.curTime },
                          },
                          [_c("canvas", { ref: "zfb_qrcode_canvas" })]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        { class: [_vm.$style.usealipay, _vm.$style.paytype] },
                        [
                          _c("i", {
                            class: [_vm.$style.payicon, _vm.$style.alipay],
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class: [_vm.$style["alipaylink"], "font-regular"],
                            },
                            [_vm._v("支付宝支付")]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { class: [_vm.$style["right-tips"], "font-regular"] }, [
                _c("p", [
                  _vm._v("付款即同意"),
                  _c(
                    "a",
                    {
                      class: _vm.$style.service,
                      attrs: {
                        href: _vm.PageAbout + "#tab04",
                        target: "_blank",
                      },
                    },
                    [_vm._v("《VIP服务协议》")]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { class: [_vm.$style.contact, "font-regular"] }, [
              _c("div", { class: _vm.$style.all_buyVip }, [
                _c("span", [_vm._v("【企业团购】：")]),
                _vm._v(" "),
                _c("span", { class: _vm.$style.seach_code }, [
                  _vm._v("点击咨询\n                          "),
                  _c("div", { class: _vm.$style.bottom_hover_box }, [
                    _c("img", {
                      attrs: {
                        src: _vm.process.env.CDN_DZ_PATH + "/bbw/ff_tuan.png",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("扫码咨询团购专员")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.$style.top_hover_box }, [
                    _c("span", [_vm._v("扫码咨询团购专员")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: _vm.process.env.CDN_DZ_PATH + "/bbw/ff_tuan.png",
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$style.teacher_buyVip }, [
                _c("span", [_vm._v("【师生专享计划】：")]),
                _vm._v(" "),
                _c("span", { class: _vm.$style.seach_code }, [
                  _vm._v("点击咨询\n                             "),
                  _c("div", { class: _vm.$style.bottom_hover_box }, [
                    _c("img", {
                      attrs: {
                        src: _vm.process.env.CDN_DZ_PATH + "/bbw/ff_sh.png",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("扫码咨询师生专员")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: _vm.$style.top_hover_box }, [
                    _c("span", [_vm._v("扫码咨询师生专员")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: _vm.process.env.CDN_DZ_PATH + "/bbw/ff_sh.png",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [_vm.$style.examine, "font-regular"],
                on: { click: _vm.handleChange },
              },
              [
                _vm._v("查看大作VIP特权"),
                _c("span", { class: _vm.$style.drop_icon }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "medal__2ov9",
                class: _vm.$style["medal__2ov9"],
                staticStyle: {
                  height: "0px",
                  transition: "height 1000ms ease-in 0s",
                  overflow: "hidden",
                },
              },
              [
                _c("div", { class: [_vm.$style.more, _vm.$style.toggle] }, [
                  _c("div", { class: [_vm.$style["hot-power"]] }, [
                    _c("div", { class: [_vm.$style.titie, "font-regular"] }, [
                      _c(
                        "span",
                        { class: [_vm.$style["left-t"], "font-medium"] },
                        [_vm._v("热门特权")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { class: [_vm.$style["right-t"], "font-regular"] },
                        [_c("span"), _vm._v("VIP专属客服1对1支持：6天x8小时")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.$style.powerList },
                      _vm._l(_vm.vipDataList, function (item, index) {
                        return _c("div", { class: _vm.$style.item }, [
                          _c(
                            "div",
                            {
                              class: [_vm.$style["top-l"]],
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.process.env.CDN_XCX_PATH +
                                  "/p/newVip/v2/" +
                                  item.icon +
                                  ")",
                              },
                            },
                            [
                              item.ai
                                ? _c("div", { class: _vm.$style.ai }, [
                                    _vm._v("AI"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          item.smallText
                            ? _c(
                                "div",
                                {
                                  class: [
                                    _vm.$style["small-text"],
                                    "font-medium",
                                  ],
                                },
                                [_vm._v(_vm._s(item.smallText))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "p",
                            { class: [_vm.$style["big-t"], "font-medium"] },
                            [
                              _vm._v(
                                _vm._s(item.textTitle) + "\n                  "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { class: [_vm.$style["small-t"], "font-regular"] },
                            [_vm._v(_vm._s(item.contText))]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { class: [_vm.$style["else-power"]] }, [
                    _c("p", { class: [_vm.$style.title, "font-medium"] }, [
                      _vm._v("其他特权"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.$style.list },
                      _vm._l(_vm.lists, function (list) {
                        return _c("div", { class: _vm.$style.item }, [
                          _c("div", { class: _vm.$style.left }, [
                            _c("img", {
                              class: _vm.$style.power1,
                              attrs: { src: _vm.toRelativeProtocol(list.img) },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { class: _vm.$style.right }, [
                            _c("div", { class: [_vm.$style["box-center"]] }, [
                              _c(
                                "p",
                                { class: [_vm.$style.top, "font-regular"] },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(list.title1)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { class: [_vm.$style.bottom, "font-regular"] },
                                [_vm._v(_vm._s(list.title2))]
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [_vm.$style["bottom_button"], "font-medium"],
                      on: { click: _vm.handleChange },
                    },
                    [
                      _c("div", [
                        _vm._v("\n                立即开通VIP\n              "),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.isShowDialogVipOfferDesc
        ? _c("cDialogVipOfferDesc", {
            on: { closeDialogVip: _vm.closeDialogVip },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowDialogPaySuccess
        ? _c("cDialogPaySuccess", { on: { handleClose: _vm.handleClose } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }