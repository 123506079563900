var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.dropdownToolsBox] }, [
    _c("div", { staticClass: "dropdownMain" }, [
      _c(
        "ul",
        { class: _vm.$style.tools },
        _vm._l(_vm.toolList, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              class: [
                _vm.$style.toolsItem,
                item.linkClass ? _vm.$style[item.linkClass] : "",
                item.dropdown ? "dropdownOuter" : "",
              ],
              on: {
                mouseenter: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleHover(item)
                },
                mouseleave: function ($event) {
                  $event.stopPropagation()
                  _vm.hoverCaijiqi = false
                },
              },
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: item.link ? item.link : "javascript:void(0)",
                    target: item.link ? "_blank" : "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(item.name, $event)
                    },
                  },
                },
                [
                  _c("span", {
                    class: [_vm.$style.icon, _vm.$style[item.iconType]],
                  }),
                  _vm._v(" "),
                  _c("p", { class: [_vm.$style.toolsName, "font-regular"] }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]
              ),
              _vm._v(" "),
              !_vm.$store.state.server && item.dropdown
                ? _c("header-ext", { staticClass: "dropdownWrapper" })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }