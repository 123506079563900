<template>
  <div :class="['cHeaderInviteTips', 'font-medium', isFixed ? 'fixed' : '']">
    <div class="cHeaderInviteTips_line">
    </div>
    <p class="cHeaderInviteTips_validity">您是受邀用户，在{{beInvitedOverdue}}前</p>
    <p class="cHeaderInviteTips_give"><span class="cHeaderInviteTips_beVip font-bold">成为VIP</span>，额外加送1个月VIP</p>
  </div>
</template>

<script lang="ts">
import { beInvitedUserOverdue } from 'bbw-common'
export default {
  name: "HeaderInvitedTips",
  props: {
    isFixed: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  methods: {
  },
  computed: {
    beInvitedOverdue() {
      return beInvitedUserOverdue(this.$store).slice(1)
    }
  }
}
</script>

<!--<style lang="scss">-->
<!--</style>-->
