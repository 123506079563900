var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["cHeaderInviteTips", "font-medium", _vm.isFixed ? "fixed" : ""] },
    [
      _c("div", { staticClass: "cHeaderInviteTips_line" }),
      _vm._v(" "),
      _c("p", { staticClass: "cHeaderInviteTips_validity" }, [
        _vm._v("您是受邀用户，在" + _vm._s(_vm.beInvitedOverdue) + "前"),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "cHeaderInviteTips_give" }, [
      _c("span", { staticClass: "cHeaderInviteTips_beVip font-bold" }, [
        _vm._v("成为VIP"),
      ]),
      _vm._v("，额外加送1个月VIP"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }