var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$style.userTypeBox],
      on: {
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.buyVip()
        },
      },
    },
    [
      _c("div", { class: [_vm.$style.dropdownMain, "dropdownMain"] }, [
        _c("div", { class: [_vm.$style["top-content"]] }, [
          _c("div", { class: _vm.$style.usetype }, [
            _c("p", {
              staticClass: "font-medium",
              domProps: { innerHTML: _vm._s(_vm.vipName) },
            }),
            _vm._v(" "),
            _vm.userInfo.userType != 6 &&
            _vm.userInfo.userType != 7 &&
            _vm.userInfo.vipDays == 0
              ? _c("p", { class: [_vm.$style.smallTip, "font-regular"] }, [
                  _vm._v("您还不是VIP特权用户"),
                ])
              : _vm._e(),
            _vm._v(" "),
            (_vm.userInfo.userType == 6 && _vm.userInfo.vipDays == 0) ||
            (_vm.userInfo.userType == 7 && _vm.userInfo.vipDays == 0)
              ? _c("p", { class: [_vm.$style.smallTip, "font-regular"] }, [
                  _vm._v("您的大作VIP特权已到期"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.userInfo.vipDays > 90
              ? _c("p", { class: [_vm.$style.smallTip, "font-regular"] }, [
                  _vm._v("\n            您的大作VIP特权："),
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.vipDays))]),
                  _vm._v("天"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.userInfo.vipDays > 0 && _vm.userInfo.vipDays <= 90
              ? _c("p", { class: [_vm.$style.smallTip, "font-regular"] }, [
                  _vm._v("\n            您的大作VIP特权："),
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.vipDays) + "天后")]),
                  _vm._v("到期"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p"),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.buyVip()
                },
              },
            },
            [
              _c("p", { staticClass: "font-medium" }, [
                _vm._v(
                  _vm._s(
                    _vm.userInfo.userType == 6 || _vm.userInfo.userType == 7
                      ? "续费VIP"
                      : "开通VIP"
                  ) + "\n            "
                ),
                _vm.$store.state.Message && _vm.$store.state.Message.vipTips
                  ? _c("a", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(_vm.$store.state.Message.vipTips)),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: _vm.$style["bottom-content"],
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.buyVip()
              },
            },
          },
          [
            _c("div", { class: [_vm.$style.title, "font-regular"] }, [
              _c("p", { staticClass: "font-medium" }, [
                _vm._v("大作VIP尊享28项特权"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$style.wrapList },
              _vm._l(_vm.rights, function (right, index) {
                return _c("div", { key: index, class: _vm.$style.item }, [
                  _c("div", {
                    class: [_vm.$style.rights, _vm.$style[right.className]],
                  }),
                  _vm._v(" "),
                  right.smalltext
                    ? _c("div", { class: _vm.$style.on_fun }, [
                        _vm._v(_vm._s(right.smalltext)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(right.title)),
                  ]),
                  _vm._v(" "),
                  _c("p", { class: [_vm.$style.constr, "font-regular"] }, [
                    _vm._v(_vm._s(right.desc)),
                  ]),
                ])
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { class: [_vm.$style.more, "font-regular"] }, [
          _c(
            "a",
            {
              attrs: {
                href: _vm.PageVersionDiff + "?type=33",
                target: "_blank",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.buyVip("pl下拉-查看更多版权")
                },
              },
            },
            [_vm._v(" 查看大作VIP特权 > ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }