import axios from 'axios' ;
import qs from 'qs' ;
import Cookie from 'js-cookie' ;
import { ServerPayServer, ServerPayServerNew, AliPayUrl, getToken } from "bbw-common" ;
import assign from "lodash-es/assign" ;
import QRCode, { QRCodeRenderersOptions } from "qrcode" ;
/**
 * @class
 * @description 微转化用户价格项
 * @see http://doc.bigbigwork.com/docs/develop/432
 * @constructor
 */
var PriceItem = function ( options, manager ) {
    options = options || {} ;
    this.UserInfo = manager.UserInfo || {} ;
    /**
     * @description 价格管理器引用
     */
    this.act_title1 = options.act_title1 || `` ;
    this.act_title2 = options.act_title2 || `` ;
    this.act_title3 = options.act_title3 || `` ;
    this.manager = manager ;
    /**
     * @description 价格ID
     * @type {number}
     */
    this.id = options.id || 0 ;
    /**
     * @description 标题
     * @type {string}
     */
    this.title = options.title || `` ;
    /**
     * @description 副标题，可能为null或者空字符串，这种情况，则不显示
     * @type {string}
     */
    this.sec_title = options.sec_title || `` ;
    /**
     * @description 价格名称
     * @type {string}
     */
    this.name_bigword = options.name_bigword || `` ;
    /**
     * @description 价格名称小字,可能为null或者空字符串
     * @type {string}
     */
    this.name_smallword = options.name_smallword || `` ;
    /**
     * @description 实际支付价格
     * @type {number}
     */
    this.price = options.price || 0 ;
    /**
     * @description 原价
     * @type {number}
     */
    this.maxprice = options.maxprice || this.price ;
    /**
     * @description 实际增加天数
     * @type {number}
     */
    this.add_days = options.add_days || 0 ;
    /**
     * @description 普惠折扣，可能为0
     * @type {number}
     */
    this.pub_discount = options.pub_discount || 0 ;
    /**
     * @description 普惠折扣左侧描述文字
     * @type {string}
     */
    this.pub_discount_word = options.pub_discount_word || `` ;
    /**
     * @description 普惠折扣左侧文字（小字），可能为null或者空字符串
     * @type {string}
     */
    this.pub_discount_smallword = options.pub_discount_smallword || `` ;
    /**
     * @description 个性折扣，可能为0
     * @type {number}
     */
    this.single_discount = options.single_discount || 0 ;
    /**
     * @description 个性折扣左侧文字，可能为null或者空字符串
     * @type {string}
     */
    this.single_discount_bigword = options.single_discount_bigword || `` ;
    /**
     * @description 个性折扣左侧文字（小字），可能为null或者空字符串
     * @type {string}
     */
    this.single_discount_smallword = options.single_discount_smallword || `` ;
    /**
     * @description 每日价格
     * @type {number}
     */
    this.day_price_desc = options.day_price_desc || 0 ;
    /**
     * @description 活动开始时间
     * @type {number}
     */
    this.start_time = options.start_time || 0 ;
    /**
     * @description 活动结束时间
     * @type {number}
     */
    this.end_time = options.end_time || 0 ;
    /**
     * @description 获取当前订单信息的时间；
     * @type {number}
     */
    this.getInfoTime = 0 ;
    /**
     * @description 支付订单信息
     * @type {object}
     */
    this.payInfo = {} ;
    /**
     * @description 是否因为活动结束刷新过
     * @type {boolean}
     */
    this.isEndRefrash = false ;
    /**
     * @description 应付价格
     * @type {{}}
     */
    this.price = {
        price: options.price,
        smallword: ``
    } ;
    /**
     * @description 活动价格
     * @type {{}}
     */
    this.activityPrice = {
        price: 0,
        smallword: ``
    } ;
    /**
     * @description 支付宝二维码
     * @type {object}
     */
    this.zfbQRcode = null ;
    /**
     * @description 微信二维码
     * @type {object}
     */
    this.wxQRcode = null ;
    /**
     * @description 原价
     */
    this.maxprice = options.price ;
    // 初始化价格
    this.init ( options ) ;
} ;

PriceItem.prototype = {
    init: function ( options ) {
        // 如果没有任何折扣，则返回
        if ( !options.pub_discount && !options.single_discount ) {
            return ;
        }
        // 普惠折扣大于个性折扣，则应付价格是普惠折扣产生的
        if ( options.pub_discount >= options.single_discount ) {
            this.price.price = this.maxprice - options.pub_discount ;
            this.price.smallword = options.pub_discount_word ;
            // 个性折扣存在，则显示个性折扣
            if ( options.single_discount ) {
                this.activityPrice.price = this.maxprice - options.single_discount ;
                this.activityPrice.smallword = options.single_discount_bigword ;
            }
        } else {
            // 个性折扣大于普惠折扣，则应付价格个性折扣产生的
            this.price.price = this.maxprice - options.single_discount ;
            this.price.smallword = options.single_discount_bigword ;
            // 普惠折扣存在，则显示普惠折扣
            if ( options.pub_discount ) {
                this.activityPrice.price = this.maxprice - options.pub_discount ;
                this.activityPrice.smallword = options.pub_discount_word ;
            }
        }
    },
    /**
     * @description 获取该价格支付信息
     * @param [callback]
     * @param {boolean} [isRefresh] 是否强制刷新订单（会刷新redies缓存）
     */
    getPayInfo: async function ( callback, isRefresh ) {
        var _self = this ;
        // 如果已有支付信息，且不是强制刷新，返回已有支付信息
        // if(this.payInfo.innerPayId && !isRefresh){
        //     //刷新验证码
        //     _self.updateQRcode();
        //     callback && callback();
        //     return;
        // }
        // 来源类型
        console.log ( `_self.manager`, _self.manager ) ;
        if ( !_self.manager.fromType ) {
            var fromtype ;
            switch ( _self.manager.source ) {
            case `公告栏`:
                fromtype = 1 ;
                break ;
            case `点击pinterest-首页-搜索框右侧`:
                fromtype = 2 ;
                break ;
            case `点击behance-首页-搜索框右侧`:
                fromtype = 2 ;
                break ;
            case `点击artstation-首页-搜索框右侧`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-大作大图页-来源`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-全球大作-搜索框右侧`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-全球大作-分类栏`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-求网站`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-求网站搜索框`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-首页-搜索框提示`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-全球大作-搜索框提示`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-聚合-搜索框提示`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-聚合-来源`:
                fromtype = 2 ;
                break ;
            case `点击pinterest-大作-搜索框提示`:
                fromtype = 2 ;
                break ;
            case `其他分类下点击下载按钮`:
                fromtype = 3 ;
                break ;
            case `图片下载-首页推荐`:
                fromtype = 3 ;
                break ;
            case `图片下载-全球大作列表`:
                fromtype = 3 ;
                break ;
            case `图片下载-聚合大图页`:
                fromtype = 3 ;
                break ;
            case `图片下载-扩大图页`:
                fromtype = 3 ;
                break ;
            case `图片下载-找大图`:
                fromtype = 3 ;
                break ;
            case `图片下载-大作搜索结果`:
                fromtype = 3 ;
                break ;
            case `图片下载-图片集列表`:
                fromtype = 3 ;
                break ;
            case `图片下载-大作大图页`:
                fromtype = 3 ;
                break ;
            case `图片下载-相似图`:
                fromtype = 3 ;
                break ;
            case `图片下载-相关推荐`:
                fromtype = 3 ;
                break ;
            case `图片下载-发现列表`:
                fromtype = 3 ;
                break ;
            case `图片下载-大图页找大图`:
                fromtype = 3 ;
                break ;
            case `图片下载-全球大作大图页`:
                fromtype = 3 ;
                break ;
            case `图片下载-聚合搜索结果`:
                fromtype = 3 ;
                break ;
            case `图片下载-大兔大图`:
                fromtype = 3 ;
                break ;
            case `图片下载-大兔瀑布流`:
                fromtype = 3 ;
                break ;
            case `图片下载-图片集详情`:
                fromtype = 3 ;
                break ;
            case `免费版权分类下点击下载按钮`:
                fromtype = 4 ;
                break ;
            case `网站右侧快速成为VIP`:
                fromtype = 5 ;
                break ;
            case `vip下拉`:
                fromtype = 6 ;
                break ;
            case `特权对比页按钮`:
                fromtype = 7 ;
                break ;
            case `升级无限下载`:
                fromtype = 8 ;
                break ;
            case `红包弹框`:
                fromtype = 10 ;
                break ;
            case `相似图`:
                fromtype = 11 ;
                break ;
            case `聚合专享带宽`:
                fromtype = 12 ;
                break ;
            case `个人设置`:
                fromtype = 13 ;
                break ;
            case `上传大于5M`:
                fromtype = 14 ;
                break ;
            case `点击behance`:
                fromtype = 15 ;
                break ;
            case `点击behance-聚合大图页-来源`:
                fromtype = 15 ;
                break ;
            case `点击behance-大作大图页-来源`:
                fromtype = 15 ;
                break ;
            case `点击behance-全球推荐页-搜索框右侧`:
                fromtype = 15 ;
                break ;
            case `点击behance-全球推荐页-分类列表`:
                fromtype = 15 ;
                break ;
            case `点击behance-单网站-打开behance`:
                fromtype = 15 ;
                break ;
            case `点击behance-单网站-地址图标`:
                fromtype = 15 ;
                break ;
            case `协作图片集`:
                fromtype = 16 ;
                break ;
            case `协作图片集-开启第三个协作图片集`:
                fromtype = 16 ;
                break ;
            case `扩大图`:
                fromtype = 21 ;
                break ;
            case `扩大图-上传框`:
                fromtype = 21 ;
                break ;
            case `扩大图-本地上传`:
                fromtype = 21 ;
                break ;
            case `单网站付费-Behance`:
                fromtype = 23 ;
                break ;
            case `单网站付费-Flickr`:
                fromtype = 23 ;
                break ;
            case `单网站付费-Issuu`:
                fromtype = 23 ;
                break ;
            case `单网站付费-Artstation`:
                fromtype = 23 ;
                break ;
            case `点击artstation-全球推荐页-搜索框右侧`:
                fromtype = 25 ;
                break ;
            case `点击artstation-全球推荐页-分类列表`:
                fromtype = 25 ;
                break ;
            case `点击artstation-大作搜索页- 推荐网站`:
                fromtype = 25 ;
                break ;
            case `点击artstation-单网站-打开artstation`:
                fromtype = 25 ;
                break ;
            case `点击artstation-单网站-地址图标`:
                fromtype = 25 ;
                break ;
            case `点击artstation-聚合大图页-来源`:
                fromtype = 25 ;
                break ;
            case `点击artstation-大作大图页-来源`:
                fromtype = 25 ;
                break ;
            case `大兔`:
                fromtype = 26 ;
                break ;
            case `PL搜索结果`:
                fromtype = 30 ;
                break ;
            case `PL下载`:
                fromtype = 31 ;
                break ;
            case `PL右侧入口`:
                fromtype = 32 ;
                break ;
            case `PL-vip下拉`:
                fromtype = 33 ;
                break ;
            case `PL公告栏`:
                fromtype = 34 ;
                break ;
            default:
                fromtype = 9 ;
            }
        } else {
            fromtype = _self.manager.fromType ;
        }

        // eslint-disable-next-line camelcase
        const sem_params = Cookie.get ( `sem_params` ) ;
        let sem = `` ;
        // eslint-disable-next-line camelcase
        if ( sem_params ) {
            // eslint-disable-next-line camelcase
            const sem_params_obj = JSON.parse ( sem_params ) ;
            sem = encodeURIComponent ( sem_params_obj.data ) ;
        }
        // 没有支付信息的，去服务器获取
        axios ( {
            url: ServerPayServer + `/pricelistnew/newprice-pre-pay.htm`,
            params: {
                token: await getToken (),
                price: _self.maxprice,
                id: _self.id,
                pub_discount: _self.pub_discount,
                single_discount: _self.single_discount,
                refreshorder: isRefresh ? 1 : 0, // 没有支付信息的，需强制刷新获取订单
                from_type: fromtype,
                from_text: _self.manager.source,
                sem
            }
        } ).then ( function ( e ) {
            const data = e.data ;
            assign ( _self.payInfo, data ) ;
            callback && callback () ;
        } ).catch ( function () {
            // alert('获取订单信息失败')
            // var d = new Dialogs ( { mes: `获取订单信息失败` } ) ;
            // d.show () ;
        } ) ;
    },
    /**
     * @description 更新二维码
     */
    async updateQRcode () {
        try {
            var _self = this ;
            // eslint-disable-next-line no-useless-return
            if ( !this.payInfo.innerPayId ) return ;
            _self.payInfo.zfbCodeUrl = AliPayUrl + `?&token=` + await getToken () + `&id=` + _self.id + `&no=` + _self.payInfo.out_trade_no ;
            const qrcodeOpts:QRCodeRenderersOptions = {
                errorCorrectionLevel: `L`,
                width: 120,
                margin: 0
            } ;
            const zfbCanvas = this.manager.zfbQRcode ;
            const wxCanvas = this.manager.wxQRcode ;
            if ( !zfbCanvas || !wxCanvas ) throw new Error ( `找不到二维码canvas对象` ) ;
            QRCode.toCanvas (
                zfbCanvas,
                _self.payInfo.zfbCodeUrl,
                qrcodeOpts,
                function ( error ) {
                    if ( error ) throw error ;
                    console.log ( `支付宝二维码生成成功!` ) ;
                } ) ;
            QRCode.toCanvas (
                wxCanvas,
                _self.payInfo.codeUrl,
                qrcodeOpts, function ( error ) {
                    if ( error ) throw error ;
                    console.log ( `微信二维码生成成功!` ) ;
                } ) ;
        } catch ( e ) {
            throw e ;
        }
    },
    /**
     * @description 检查本价格是否已支付
     */
    checkIsPay: function () {
        var _self = this ;
        if ( !_self.payInfo.innerPayId ) return ;
        axios ( {
            method: `POST`,
            url: ServerPayServerNew + `/pricelistnew/is-pay-success.htm`,
            headers: {
                'Content-Type': `application/x-www-form-urlencoded;charset=UTF-8`
            },
            data: qs.stringify ( {
                innerPayId: _self.payInfo.innerPayId,
                userid: _self.UserInfo.id
            } )
        } ).then ( function ( e ) {
            const r = e.data ;
            if ( r === 1 ) {
                // 设置cookie 今天禁止二次弹框
                var todyTime = new Date () ; // 当前时间戳
                var hours = todyTime.getHours () + ( todyTime.getMinutes () ) / 60 ;
                // 距零点相差的时间戳
                var useTime = ( 24 - hours ) / 24 ;
                Cookie.set ( `offerDialog`, `true`, { expires: useTime, domain: `.bigbigwork.com` } ) ;
                _self.manager.onPaySuccess ( _self.payInfo.innerPayId ) ;
            }
        } ) ;
    },
    /**
     * @description 清除价格信息及支付检测
     */
    clear: function () {
        this.payInfo = {} ;
    }
} ;
export default PriceItem ;
