var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.Message.reserve1
    ? _c("div", { class: [_vm.$style["header-vip-tips"], _vm.ani] }, [
        _vm._v("\n  " + _vm._s(_vm.$store.state.Message.reserve1) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }