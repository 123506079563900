var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style["bbw-mask-btn"] }, [
    _c(
      "button",
      {
        class: [_vm.mask ? _vm.$style.mask : ""],
        style: _vm.ops,
        on: { click: _vm.btnClick },
      },
      [
        _vm.icon
          ? _c("img", {
              class: _vm.$style.normalIcon,
              attrs: { src: _vm.icon, alt: "" },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.icon
          ? _c("img", {
              class: _vm.$style.hoverIcon,
              attrs: { src: _vm.hoverIcon || _vm.icon, alt: "" },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }