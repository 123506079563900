<template>
    <transition :name="isCenter ? `fadeupCenter` : `fadeup`"
    :leave-to-class=" leaveToType ? leaveToType : `fadeup-leave-to` " >
        <div v-if="isShow" :class='theme' class="hitbox" @click="openNew(PropsData)" :style="cursor" @mouseleave="mouseleave()" @mouseenter="mouseenter">
            <span v-if="image" class="collection-tip-title">
                <img :src='url'>
            </span>
            <component :PropsData="PropsData" :is="child"></component>
            <div v-if="content" class="collection-tip-content" v-html="content"></div>
            <div v-if="PropsData.isshow">
                <div class="collection-tip-content">{{PropsData.title || '已成功收藏到我的设计云'}}
                    <br>
                    <p :class="$style.hoverUnderline"  class="name toolong"> {{ PropsData.board.title }} </p>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang=ts>
    import { toRelativeProtocol, BigBigWork } from "bbw-common";
    import {lowerFirst} from "lodash-es";
    export default {
        props: ['content', 'image', 'child', 'PropsData', 'appendToBody', 'theme', 'leaveToType','hoverAble','resetTime', `isCenter`],
        data: function () {
            return {
                isShow: false,
                setTimeoutEvent: null
            }
        },
      mounted(){
        BigBigWork.on('hideHint', () => {
          this.hide()
        })
      },
        methods: {
            show: function () {
                this.appendToBody.appendChild(this.$el);
                this.isShow = true;
            },
            hide: function () {
                var _self = this;
                this.$el.addEventListener("transitionend", function (e) {
                    _self.$el.remove();
                });
                this.isShow = false;
            },
            openNew: function (PropsData) {
                if (!PropsData.board) return
              _hmt.push([`_trackEvent`, `收藏成功toast`, `点击`, `图片集`]);
                var surl = __DEV__ ? 'https://www-test.bigbigwork.com' : 'https://www.bigbigwork.com';
                let pathName = ''
                console.log('PropsData', PropsData)
                if (PropsData.isSection) {
                  pathName = `${surl}/u/${PropsData.board.uid}/${PropsData.board.base_id}/${PropsData.board.uuid}`
                } else if( PropsData.isUseUUID ) {
                    pathName = `${surl}/board/${PropsData.board.uuid}`
                } else {
                  pathName = `${surl}/u/${PropsData.board.uid}/${PropsData.board.id}/`
                }
                window.open(pathName, '_blank');
            },
            //若当前是可悬停的 鼠标移出时  重置隐藏时间
            mouseleave(){
                if (!this.hoverAble) return
                this.setTimeoutEvent=setTimeout( ()=>{
                    this.hide()
                },this.resetTime)
            },
            //若当前是可悬停的 鼠标移入时 移除隐藏时间计时器
            mouseenter(){
                if (!this.hoverAble) return
                clearTimeout(this.setTimeoutEvent)
            }
        },
        computed:{
            url: function () {
                return toRelativeProtocol(this.image);
            },
            themeClass(){
                if(!this.theme.length){
                    return [this.$style.hitbox]
                }
                const themes = this.theme.split(',');
                const list = themes.map( className =>{
                    return this.$style[className];
                })
                return list.push(this.$style.hitbox);
            },
            cursor(){
                if (this.PropsData.board){
                    return {cursor:'pointer'}
                }else {
                    return {}
                }
            }
        }
    }
</script>
<style lang="scss" module src="./Hint.scss"></style>