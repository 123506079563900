<style lang="scss" module src="./HeaderExt.scss"></style>
<template>
    <div :class="[$style.dropdownToolsBox]">
        <div class="dropdownMain">
            <div :class="$style.tools">
                <div :class="$style.version">
                    <h4>特色功能</h4>
                    <span v-show="extVersion">版本{{extVersion}}</span>
                </div>
                <a :class="[$style.toolsItem, item.class?$style[item.class]:'']" v-for="(item,index) in items" :key="index" :href="item.link" target=_blank>
                    <p :class="[$style.toolsName, 'font-regular']" v-html="item.name"></p>
                </a>
            </div>
        </div>
    </div>
</template>

<script lang=ts>
    import {
        PageCollectionTool,
    } from 'bbw-common';
    import { mapState } from "vuex";
    export default {
        props: {},
        name: "HeaderExt",
        data: function () {
            return {
                items: [
                    { name: `采集高清原图`, link: `${PageCollectionTool}#p1` },
                    { name: `网页图片放大`, link: `${PageCollectionTool}#p6`, class: 'new' },
                    // { name: `淘宝全额返利`, link: `${PageCollectionTool}#p2`, class: 'main' },
                    { name: `<span class="${this.$style.ai}">AI</span>搜相似图`, link: `${PageCollectionTool}#p3` },
                    { name: `智能打标签`, link: `${PageCollectionTool}#p4` },
                    { name: `一键截图`, link: `${PageCollectionTool}#p7` },
                    // { name: `高效批量采集`, link: `${PageCollectionTool}#p6` },
                    { name: `更多功能...`, link: `${PageCollectionTool}#more1`, class: `more` },
                ]
            }
        },
        methods:{

        },
        computed: {
          extVersion(){
            return this.$store.state?.System?.extVersion || ''
          }
            /*...mapState({
                extVersion: ( state ) => state?.System?.extVersion?? ''
            })*/
        }
    }
</script>
