var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isShow,
        "custom-class": "dialogOfferDesc",
        top: "0px",
        width: "540px",
        "modal-append-to-body": true,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
        open: _vm.onOpen,
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { class: _vm.$style.offerdesc }, [
        _c("p", { class: _vm.$style.title }, [
          _vm._v("您距离获得VIP特权仅差一步！"),
        ]),
        _vm._v(" "),
        _c("p", { class: _vm.$style["sm-title"] }, [
          _vm._v("-已有" + _vm._s(_vm.number) + "万设计师使用VIP服务-"),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style["desc-content"] }, [
          _c("div", { class: _vm.$style["effective-today"] }, [
            _c("span", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.priceManager.currentPrice.act_title1) +
                  "\n    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.$style["offer-word"] }, [
            _c("span", [
              _vm._v(_vm._s(_vm.priceManager.currentPrice.act_title2)),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.priceManager.currentPrice.act_title3)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style["btn-box"] }, [
          _c("button", { on: { click: _vm.goBack } }, [_vm._v("狠心离开")]),
          _vm._v(" "),
          _c("button", { class: _vm.$style.again, on: { click: _vm.again } }, [
            _vm._v("再考虑下"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }