import PriceItem from "./PriceItem" ;
// import $ from "jquery" ;
// import VipSuccess from "./VipSuccess.vue" ;
import { BigBigWork, BBWpriceManager, ServerPayServer, PageSearch, PageVersionDiff, getToken, getOS } from "bbw-common" ;
import axios from 'axios' ;
const UserInfo = { state: { diffTime: 0, id: 12 } } ;
interface IntuserInfo {
    [propName: string]: any;
}
/**
 * @class
 * @description 支付管理器
 * @constructor
 */
var PriceManager = function ( options ) {
    options = options || {} ;
    this.curTime = options.curTime || `` ;
    this.UserInfo = options.UserInfo || { UserInfo: { state: {} } } ;
    /**
     * @description 价格列表数组
     * @type {Array}
     */
    this.priceList = [] ;
    /**
     * @description 当前价格
     * @type {PriceItem}
     */
    this.currentPrice = {} ;
    /**
     * @description 当前价格索引
     * @type {number}
     */
    this.currentIndex = -1 ;
    /**
     * @description 当前价格索引
     * @type {number}
     */
    this.currentIndexForDelay = -1 ;
    /**
     * @description 记录最近关闭VIP弹窗时间。（关闭5分钟后自动清除所有的支付检查）
     * @type {number}
     */
    this.closeTime = 0 ;
    /**
     * @description 获取价格的时间（每隔this.refreshTime需要重新获取支付信息，避免订单失效）
     * @type {number}
     */
    this.startTime = 0 ;
    /**
     * @description 刷新价格时间
     * @type {number}
     */
    this.refreshTime = 30 * 60 * 1000 ;
    /**
     * @description 闲置时间
     * @type {number}
     */
    this.idelTime = __DEV__ ? 12 * 1000 : 2 * 60 * 1000 ;
    /**
     * @description 是否加载价格中
     * @type {boolean}
     */
    this.isLoading = false ;
    /**
     * @description 是否闲置状态
     * @type {boolean}
     */
    this.isIdel = false ;
    /**
     * @description 自定义标题
     * @type {string}
     */
    this.customTitle = options.title || `` ;
    /**
     * @description 支付成功检测间隔
     * @type {number}
     */
    this.interval = 3000 ;
    /**
     * @description 支付检查计时器
     * @type {number | null}
     */
    this.timer = null ;
    /**
     * @description 支付宝二维码canvas
     * @type {object}
     */
    this.zfbQRcode = options.zfbQRcode ?? null ;
    /**
     * @description 微信二维码canvas
     * @type {object}
     */
    this.wxQRcode = options.wxQRcode ?? null ;
    /**
     * @description 是否支付成功
     * @type {boolean}
     */
    this.paySuccess = false ;
    /**
     * @description 付费弹窗点击来源
     * @type {string}
     */
    this.source = options.source || `其他` ;
    this.fromType = options.fromType ;
} ;
PriceManager.prototype = {
    /**
     * @description 初始化支付管理器
     */
    init () {
        var _self = this ;
        _self.closeTime = 0 ;
        _self.isIdel = true ;
        return new Promise ( ( resolve, reject ) => {
            // 获取价格列表
            this.getPrizeList ( function () {
                // _self.changePrice ( 1 ) ;
                // _self.checkPaySuccess () ;
                resolve ( true ) ;
            } ) ;
        } ) ;
    },
    /**
     * @description 获取价格列表
     * @param callback
     * @param {boolean} [isRefresh] 是否强制刷新
     */
    getPrizeList: async function ( callback ) {
        var _self = this ;
        if ( this.isLoading ) return ;
        _self.priceList.splice ( 0, Number.MAX_VALUE ) ;
        axios ( {
            url: ServerPayServer + `/pricelistnew/getUserPriceListNew.htm`,
            params: {
                token: await getToken ()
            }
        } ).then ( function ( e ) {
            const data = e.data ;
            if ( data.status === 200 ) {
                for ( var i in data.pricelist ) {
                    var price = new PriceItem ( data.pricelist[i], _self ) ;
                    _self.priceList.push ( price ) ;
                }
                console.log ( `_self.UserInfo`, _self.UserInfo ) ;
                _self.startTime = new Date ().getTime () + _self.UserInfo.diffTime || 0 ;
                callback && callback () ;
            } else {
                alert ( `价格列表获取失败,请刷新页面重试1` ) ;
                // var d = new Dialogs ( {
                //     mes: data.errorCode || `获取价格失败，请刷新页面重试`
                // } ) ;
                // d.show () ;
            }
        } ).catch ( e => {
            console.log ( `价格列表失败`, e ) ;
        } ) ;
        // .finally ( function () {
        //     _self.isLoading = false ;
        // } ) ;
    },
    /**
     * @description 强制刷新所有已获取的价格的订单信息
     */
    refreshOrader: function () {
        var _self = this ;
        for ( var i in this.priceList ) {
            if ( this.priceList[i].payInfo.innerPayId ) {
                this.priceList[i].getPayInfo ( function () {
                    _self.currentPrice.updateQRcode () ;
                }, true ) ;
            }
        }

        this.startTime = new Date ().getTime () + _self.UserInfo.diffTime ;
        // 如果timer关了，重启
        if ( _self.isIdel ) {
            _self.checkPaySuccess () ;
            _self.isIdel = false ;
        }
    },
    /**
     * @description 选中指定的价格
     * @param {number} index 当前价格索引
     */
    changePrice: function ( index ) {
        var _self = this ;
        if ( index < 0 || index >= this.priceList.length || index === _self.currentIndex ) {
            return ;
        }
        _self.currentPrice = _self.priceList[index] ;
        _self.currentIndex = index ;
        _self.currentIndexForDelay = index ;
        // 如果没有获取过该价格的订单信息，则去服务器获取订单信息
        if ( Object.keys ( this.currentPrice.payInfo ).length == 0 ) {
            this.currentPrice.getPayInfo ( function () {
                // 刷新验证码
                _self.currentPrice.updateQRcode () ;
                // 如果timer关了，重启
                if ( _self.isIdel ) {
                    _self.checkPaySuccess () ;
                    _self.isIdel = false ;
                }
            } ) ;
            return ;
        }
        // 刷新验证码
        _self.currentPrice.updateQRcode () ;
        // 如果timer关了，重启
        if ( _self.isIdel ) {
            _self.checkPaySuccess () ;
            _self.isIdel = false ;
        }
    },
    /**
     * @description 当支付成功时调用
     */
    onPaySuccess: function ( innerPayId ) {
        var _self = this ;
        this.clearAll () ;
        this.paySuccess = true ;
        // 显示支付成功弹窗
        BigBigWork.emit ( `onPaySuccess` ) ;
        // var dialog = new Dialogs ( {
        //     child: VipSuccess,
        //     theme: `PaymentSuccess`,
        //     onHide: function () {
        //         window.location.reload () ;
        //     }
        // } ) ;
        // dialog.show () ;
        // window.VipBox.hide () ;
        if ( !innerPayId || !_self.UserInfo.id ) return ;
        // 统计支付成功信息
        var pathSource ;
        switch ( `.` + window.location.pathname ) {
        case PageSearch:
            pathSource = `搜索页二维码层` ;
            break ;
        case PageVersionDiff:
            pathSource = `VIP特权页` ;
            break ;
        default:
            pathSource = `其他` ;
        }
        // 统计付费页面
        BigBigWork.emit ( `Analysis`, {
            type: `VipPaySuccess`,
            data: {
                userid: _self.UserInfo.id,
                innerPayId: innerPayId,
                source: pathSource
            }
        } ) ;
        // 统计付费弹窗打开的地方
        BigBigWork.emit ( `Analysis`, {
            type: `VipPaySuccessSource`,
            data: {
                source: _self.source,
                userid: _self.UserInfo.id
            }
        } ) ;
        // 刷新所有订单
        // this.refreshAll();
    },
    /**
     * @description 清除所有支付检查
     */
    clearAll: function () {
        for ( var i in this.priceList ) {
            this.priceList[i].clear () ;
        }
        window.clearInterval ( this.timer ) ;
    },
    /**
     * @description 检查是否支付成功
     */
    checkPaySuccess: function () {
        var _self = this ;
        if ( _self.timer ) {
            window.clearInterval ( _self.timer ) ;
        }
        _self.timer = window.setInterval ( function () {
            update () ;
        }, 3000 ) ;

        var update = function () {
            console.log ( `diffTime`, _self.UserInfo.diffTime, new Date ().getTime () ) ;
            var now = new Date ().getTime () + _self.UserInfo.diffTime ;
            // 如果关闭VIP弹窗时间超过5分钟，则清除所有支付检查
            console.log ( `时间 _self.startTime=${_self.startTime},_self.closeTime=${_self.closeTime},now=${now}` ) ;
            if ( _self.closeTime && ( now - _self.closeTime ) >= 5000 ) {
                console.log ( `时间到了，结束监听` ) ;
                // 初始化数据
                _self.priceList.length = 0 ; // 清空价格列表
                _self.currentIndex = -1 ; // 清空选择列表
                _self.clearAll () ; // 清空二维码付费数据
                return ;
            }

            if ( _self.closeTime ) {
                console.log ( `倒数五秒` ) ;
            }

            // 如果打开支付弹窗的事件已经超过refreshTime(闲置时间)设定的事件，则关闭轮询
            if ( _self.isIdel ) {
                window.clearInterval ( _self.timer ) ;
                console.log ( `画面闲置超时，结束监听` ) ;
                return ;
            }

            // 如果打开支付弹窗的事件已经超过refreshTime设定的事件，则刷新所有点过的订单信息
            if ( _self.startTime - now > _self.refreshTime ) {
                _self.refreshOrader () ;
                return ;
            }

            for ( var i in _self.priceList ) {
                // 如果有价格的结束时间到了，且没有刷新过，则刷新所有价格；
                if ( new Date ( _self.priceList[i].end_time ).getTime () + _self.UserInfo.diffTime <= now && !_self.priceList[i].isEndRefrash ) {
                    _self.getPrizeList ( function () {
                        _self.refreshOrader () ;
                        _self.priceList[i].isEndRefrash = true ;
                    } ) ;
                }
            }
            // 监听选中的价格有没有付款
            _self.priceList[_self.currentIndexForDelay].checkIsPay () ;
        } ;
    },
    /**
     * @description 统计打开付费弹窗的用户信息
     */
    analysis: function () {
        var _self = this ;
        // 用户系统
        var info = getOS () ;
        var userInfo: IntuserInfo = {
            userId: _self.UserInfo.id,
            // 用户打开付费弹窗的入口
            source: this.source,
            // 用户电脑分辨率
            screen: window.screen.width + `*` + window.screen.height,
            // 用户浏览器内核
            browser: info.browser,
            // 用户使用系统
            os: info.os,
            // 用户UA
            ua: window.navigator.userAgent,
            // 用户操作系统信息
            platform: window.navigator.platform
        } ;
        __DEV__ && console.dir ( userInfo ) ;
        // 发出统计事件
        BigBigWork.emit ( `Analysis`, {
            type: `VipShowAnalysis`,
            data: {
                source: _self.source,
                userInfo: userInfo
            }
        } ) ;
    }
} ;
export default PriceManager ;
