<style lang=scss module src="./DialogVip.scss"></style>
<template>
  <div>
    <!--        付费弹框-->
    <el-dialog :visible="isShowVipBox"
               :custom-class="cusClass"
               :close-on-click-modal="false"
               :append-to-body="true"
               @open="onOpen"
               @opened="onOpened"
               @close="handleClose"
               width="810px"
               top="0">
      <div :class="$style.con" ref="dialogVip_new">
        <img style="display: none" :src="`${process.env.CDN_XCX_PATH}/pimg/offertwo.png`"
             alt="">
        <img style="display: none" :src="`${process.env.CDN_XCX_PATH}/pimg/offerone.png`"
             alt="">
        <div :class="[$style.vipcon,$style.cl]">
          <div :class="[$style.VIPSelectBox,$style.fl]">
            <h3 :class="[$style.VIPtip,'font-medium']" v-html="title"></h3>
            <p :class="[$style.payTitDec,'font-medium']">{{
                priceManager.currentPrice &&
                priceManager.currentPrice.sec_title
              }}</p>
            <div :class="[$style['num-designer'],'font-regular' +
                         '']">
              - 已有<span class="font-bold">{{ num }}</span>万设计师使用VIP服务 -
            </div>
            <ul :class="[$style.VIPSelect,$style.select,$style.cl]" id="VIPSelect">
              <li @click="changePrice(priceIndex)"
                  v-for="(priceItem,priceIndex) in priceManager.priceList"
                  :class="[$style.item,priceIndex===priceManager.currentIndex?$style.active:'',priceIndex==1?$style.isReduction:'']"
              >
                <div :class="$style.reduction"></div>
                <!--                                <div :class="$style.countdown" v-if="priceIndex === 1">-->
                <!--                                    <div :class="[$style.time,'font-regular']">-->
                <!--                                        <span class="font-bold">{{hours}}</span>时<span class="font-bold">{{minutes}}</span>分<span class="font-bold">{{seconds}}</span>秒<span class="font-bold" style="margin-left: 1px">{{minsecond}}</span>-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <span :class="$style.viptime ">
              <span :class="[$style.oTime,'font-medium']" v-html="priceItem.name_bigword"></span>
              <span :class="[$style.addTime,'font-medium']" v-html="priceItem.name_smallword"></span>
                  <!--              <span v-if="isBeInvitedUser" :class="[$style.invitedTips, 'font-medium']">额外送1个月</span>-->
            </span>
                <span :class="[$style.yuan,$style.active_p]">
              <span :class="[$style.priceVal,'font-medium']">{{ parseNumber(priceItem.price.price * 0.01) }}
                 <div :class="[$style.fuli,'font-medium',$style.dafuli]" v-if="priceItem.price.smallword"><span
                     v-html="priceItem.price.smallword"></span></div>
                 <div :class="[$style.rmb,'font-regular']">￥</div>
              </span>
            </span>
                <span
                    :class="[$style.yuan,$style.original_p_t,handleIsFour(priceItem.activityPrice.price)?$style.original_p_t_four:'','font-regular']"
                    v-if="priceItem.activityPrice.price">
              <div :style="{right:handleIsFour(priceItem.activityPrice.price)?'-20px':'-14px'}"
                   :class="[$style.fuli,'font-regular']" v-if="priceItem.activityPrice.smallword"><span
                  v-html="priceItem.activityPrice.smallword"></span></div>
              <div :class="[$style['rmb-1'],'font-regular']">￥</div><span
                    :class="$style.oPriceVal">{{ parseNumber(priceItem.activityPrice.price * 0.01) }}</span>
            </span>

                <span
                    :class="[$style.yuan,$style.original_p_b,handleIsFour(priceItem.activityPrice.price)?$style.original_p_b_four:'','font-regular']"
                    v-if="priceItem.price.price !== priceItem.maxprice">
              <div :class="[$style['rmb-1'],'font-regular']">￥</div><span
                    :class="$style.oPriceVal">{{ parseNumber(priceItem.maxprice * 0.01) }}</span>
              <span :style="{right:handleIsFour(priceItem.maxprice)?'-16px':'-10px'}"
                    :class="[$style.oPriceValone,'font-regular']">原价</span>
            </span>


                <span :class="[$style.perdayPriceCont,'font-regular']">低至 <i :class="[$style.perdayPrice]"
                                                                             class="font-bold">{{ priceItem.day_price_desc }}</i> 元 / 天</span>
                <!--<span class="VIPdec">原图无限下载<br>Pinterest特权<br>3千万免费版权<br>高速专线带宽<br><span style="color:#BDC3C7">• • •</span></span>-->
              </li>
            </ul>
          </div>
        </div>
        <div :class="$style.payArea">
          <div :class="$style.payContent"
               v-if="priceManager.currentPrice">
            <div :class="[$style.wexinqr,'fl']">
              <div
                  :class="$style.refreshPay"
                  @click="refresh"
                  @mouseover="wxrefreshover = true"
                  @mouseout="wxrefreshover = false"
                  :style="{opacity: (isShowRefresh  || wxrefreshover)? 1 : 0}"
              >刷新
              </div>
              <div :class="$style.qrcode">
                <div :class="$style.payqrcode" :id="'wx_qr'+curTime">
                  <canvas ref="wx_qrcode_canvas"/>
                </div>
              </div>
              <p :class="[$style['usewexin'],$style['paytype'],'font-regular']"><i
                  :class="[$style.payicon,$style.wxpay]"></i>微信支付
              </p>
            </div>
            <h3>
              <p :class="[$style['should_title'],'font-regular']">折后仅需：</p>
              <span
                  :class="[$style['payPrice'],'font-bold']"
                  v-if="priceManager.currentPrice.price"
              >{{ parseNumber(priceManager.currentPrice.price.price * 0.01) }}</span> <span
                :class="$style.should">元</span>
              <span :class="$style.maxPrice"
                    v-if="priceManager.currentPrice.maxprice"><span>￥</span>{{ parseNumber(priceManager.currentPrice.maxprice * 0.01) }}</span>
              <br>
              <p :class="[$style['infor_text1'],'font-regular']" style="cursor: pointer;margin-top:27px"
                 @click="gotoHc"><span style=""></span>大作捐赠1元“火柴乡村教师公益”
                <!--<a style="margin-left: -8px"
                    href="http://www.huochaibox.com"
                    target="_blank"
                ></a>-->
              </p>
              <!--style="margin-top:27px"-->
              <p :class="[$style['infor_text2'],'font-regular']"><span style=" "></span>支持无理由退款(48h到账)
                <a
                    :href="PageAbout+'#tab03'"
                    target="_blank"
                ></a>
              </p>
              <p :class="[$style.infor_text3,'font-regular']"><span style=""></span>付款后可提供发票</p>
              <!-- <p style="">付款即同意<a :href="PageAbout+'#tab04'"
                                   target="_blank" class="service">《 VIP 服务协议 》</a></p>-->
            </h3>
            <div :class="[$style.alipayqr,'fr']">
              <div
                  :class="$style.refreshPay"
                  @click="refresh"
                  @mouseover="alirefreshover = true"
                  @mouseout="alirefreshover = false"
                  :style="{opacity: (isShowRefresh || alirefreshover)? 1 : 0}"
              >刷新
              </div>
              <div :class="$style.qrcode">
                <div :class="$style.payqrcode" :id="'zfb_qr' + curTime">
                  <canvas ref="zfb_qrcode_canvas"/>
                </div>
              </div>
              <p :class="[$style.usealipay,$style.paytype]">
                <i :class="[$style.payicon,$style.alipay]"></i>
                <span :class="[$style['alipaylink'],'font-regular']">支付宝支付</span>
              </p>
            </div>
          </div>
          <div :class="[$style['right-tips'],'font-regular']">
            <p>付款即同意<a :href="PageAbout+'#tab04'"
                       target="_blank" :class="$style.service">《VIP服务协议》</a></p>
          </div>
        </div>
        <div :class="[$style.contact,'font-regular']">
          <div :class="$style.all_buyVip">
            <span>【企业团购】：</span>
            <span :class="$style.seach_code">点击咨询
                          <div :class="$style.bottom_hover_box">
                             <img :src="`${process.env.CDN_DZ_PATH}/bbw/ff_tuan.png`"/>
                             <span>扫码咨询团购专员</span>
                          </div>
                          <div :class="$style.top_hover_box">
                             <span>扫码咨询团购专员</span>
                             <img :src="`${process.env.CDN_DZ_PATH}/bbw/ff_tuan.png`"/>
                          </div>
                        </span>
          </div>
          <div :class="$style.teacher_buyVip">
            <span>【师生专享计划】：</span>
            <span :class="$style.seach_code">点击咨询
                             <div :class="$style.bottom_hover_box">
                                  <img :src="`${process.env.CDN_DZ_PATH}/bbw/ff_sh.png`"/>
                                  <span>扫码咨询师生专员</span>
                             </div>
                            <div :class="$style.top_hover_box">
                                  <span>扫码咨询师生专员</span>
                                  <img :src="`${process.env.CDN_DZ_PATH}/bbw/ff_sh.png`"/>
                             </div>
                        </span>
          </div>
        </div>
        <div :class="[$style.examine,'font-regular']" @click="handleChange">查看大作VIP特权<span
            :class="$style.drop_icon"></span></div>
        <div ref="medal__2ov9" :class="$style['medal__2ov9']"
             style="height: 0px; transition: height 1000ms ease-in 0s;overflow:hidden;">
          <div :class="[$style.more,$style.toggle]">
            <div :class="[$style['hot-power']]">
              <div :class="[$style.titie, 'font-regular']">
                <span :class="[$style['left-t'],'font-medium']">热门特权</span>
                <p :class="[$style['right-t'],'font-regular']"><span></span>VIP专属客服1对1支持：6天x8小时</p>
              </div>
              <div :class="$style.powerList">
                <div :class="$style.item" v-for="(item,index) in vipDataList">
<!--                  <div v-if="index=== 17" :class="[$style['top-l']]"
                       :style="{backgroundImage:`url(${process.env.CDN_XCX_PATH}/p/newVip/newVip_icon18_1.svg)`}"></div>-->
                  <div :class="[$style['top-l']]"
                       :style="{backgroundImage:`url(${process.env.CDN_XCX_PATH}/p/newVip/v2/${item.icon})`}">
                    <div :class="$style.ai" v-if="item.ai">AI</div>
                    <!--                                        <img :src=`${process.env.CDN_XCX_PATH}/p/newVip/newVip_icon${index+1}.png`>-->
                  </div>
                  <div :class="[$style['small-text'],'font-medium']" v-if="item.smallText">{{ item.smallText }}</div>
                  <p :class="[$style['big-t'],'font-medium']">{{ item.textTitle }}
                  </p>
                  <p :class="[$style['small-t'],'font-regular']">{{ item.contText }}</p>
                </div>

                <!--                                <div :class="$style.item" v-for="(item,index) in vipDataList">-->
                <!--                                    <div :style="{backgroundImage:`url(${process.env.CDN_XCX_PATH}/p/newVip/newVip_icon${index+1}.png)`}">-->
                <!--                                    </div>-->
                <!--                                    <div :class="[$style['small-text'],'font-medium']" v-if="item.smallText">{{item.smallText}}</div>-->
                <!--                                    <p :class="[$style.title,'font-bold']">{{item.textTitle}}<span v-if="item.textTitle==='移动版'" :class="[$style.on_fun,'font-regular']">(待上线)</span></p>-->
                <!--                                    <p :class="['font-regular']">{{item.contText}}</p>-->
                <!--                                </div>-->


              </div>
            </div>
            <div :class="[$style['else-power']]">
              <p :class="[$style.title,'font-medium']">其他特权</p>
              <div :class="$style.list">
                <div :class="$style.item" v-for="list in lists">
                  <div :class="$style.left"><img :src="toRelativeProtocol(list.img)"
                                                 :class="$style.power1"></div>
                  <div :class="$style.right">
                    <div :class="[$style['box-center']]"><p :class="[$style.top,'font-regular']">
                      {{ list.title1 }}</p>
                      <p :class="[$style.bottom,'font-regular']">{{ list.title2 }}</p></div>
                  </div>
                </div>
              </div>
            </div>
            <div @click="handleChange" :class="[$style['bottom_button'],'font-medium']">
              <div>
                立即开通VIP
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--        vip 二次弹框-->
    <cDialogVipOfferDesc v-if="isShowDialogVipOfferDesc" @closeDialogVip="closeDialogVip"></cDialogVipOfferDesc>
    <!--        付费成功弹框-->
    <cDialogPaySuccess @handleClose="handleClose" v-if="isShowDialogPaySuccess"></cDialogPaySuccess>
  </div>
</template>
<script lang=ts>
import Vue from "vue";
import {
  toRelativeProtocol,
  BBWpriceManager,
  BigBigWork,
  PageAbout,
  ServerWww,
  beInvitedUserOverdue,
  getDomain
} from 'bbw-common';
import PriceManager from "../VipBox/PriceManager";
import cDialogVipOfferDesc from '../DialogVipOfferDesc'
import cDialogPaySuccess from '../DialogPaySuccess'
import axios from 'axios';
import Cookie from 'js-cookie';

const Dialog = require("element-ui/lib/dialog").default;
Vue.use(Dialog);
export default {
  name: "cDialogVip",
  props: {
    isShow: {
      type: Boolean,
      default: false,
      require: false
    },
    //自定义标题
    customTitle: {
      type: String,
      default: `<span class="red">开通VIP</span>，3000万免费版权图片每天随心用!`,
      require: false
    },
    defaultTitle: {
        type: String,
        default: `<span class="red">开通VIP</span>，3000万免费版权图片每天随心用!`,
        require: false
    },
    source: {
      type: String,
      default: '其他',
      require: false
    },
    fromType: {
      type: Number,
      default: 9,
      required: false
    },
    customClasses: {
        type: Array,
        default: () => []
    }
  },
  data: function () {
    return {
      curTime: 0,
      // isShow: false,
      isShowDialogVipOfferDesc: false,
      isShowDialogPaySuccess: false,
      PageAbout,
      //价格管理器
      priceManager: {},
      idelTimer: null,
      count: 0,
      outTime: __DEV__ ? 20 : 60 * 10,
      wxrefreshover: false,
      alirefreshover: false,
      num: '',
      activeNames: false,
      toRelativeProtocol,
      firstShow: true,
      items: [
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox1.png`,
          bigt: "无限下载",
          smallt: "原站图片"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox2.png`,
          bigt: "高速下载",
          smallt: "专属宽带"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox3.png`,
          bigt: "无限下载",
          smallt: "3000万免费版权图片"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox4.png`,
          bigt: "赠送",
          smallt: "同等天数"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox5.png`,
          bigt: "赠送",
          smallt: "同等天数"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox17.png`,
          bigt: "赠送",
          smallt: "同等天数"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox6.png`,
          bigt: "终身免费下载",
          smallt: "VIP期间收藏的图片"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox8.png`,
          bigt: "无限创建",
          smallt: "协作/私有图片集"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox9.png`,
          bigt: "协作人数≤99",
          smallt: "协作图片集",
          smallts: "非VIP≤5人"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox10.png`,
          bigt: "全功能使用",
          smallt: "AI查找相似图",
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox11.png`,
          bigt: "VIP特权3折",
          smallt: "66款个人终身商用字体"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox12.png`,
          bigt: "VIP特权3折",
          smallt: "设计师智能名片"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox13.png`,
          bigt: "VIP特权6折",
          smallt: "话画团队版（＞5人）"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox14.png`,
          bigt: "VIP独享",
          smallt: "在线现金回馈活动"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox15.png`,
          bigt: "无限使用",
          smallt: "AI扩大图"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/pimg/vip/vipbox16.png`,
          bigt: "持续增加中",
          smallt: "大作VIP权益"
        }
      ],
      lists: [
        {
          img: `${process.env.CDN_XCX_PATH}/p/newVip/newVip_b_icon1.svg`,
          title1: "优先体验",
          title2: "大作最新功能"
        }, {
          img: `${process.env.CDN_XCX_PATH}/p/newVip/newVip_b_icon7.svg`,
          title1: "赠送7万AI点数",
          title2: "购大设Pro会员额外尊享"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/p/newVip/newVip_b_icon2.svg`,
          title1: "大设报奖",
          title2: "一站式全球奖项申报"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/p/newVip/newVip_b_icon3.svg`,
          title1: "VIP享特权价",
          title2: "后续设计师产品"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/p/newVip/newVip_b_icon4.svg`,
          title1: "尊享专属标识",
          title2: "大作VIP身份"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/p/newVip/newVip_b_icon5.svg`,
          title1: "快速认证通道",
          title2: "大作认证设计师"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/p/newVip/newVip_b_icon6.svg`,
          title1: "优先推荐",
          title2: "创建的优秀图片集"
        },
        {
          img: `${process.env.CDN_XCX_PATH}/p/newVip/newVip_b_icon8.svg`,
          title1: "不定期赠送",
          title2: "线下活动门票"
        }
      ],
      isShowRefresh: false,
      instanceShow: false,
      nowTime: '',
      hours: '',
      minutes: '',
      seconds: '',
      minsecond: '',
      vipDataList: [
        {ai:true,contText: 'AI赋能图意搜索', textTitle: '无限使用', smallText: '', icon: `newVip_icon1.png`},
        {ai:true,contText: '3000万免费版权原图', textTitle: '无限下载', smallText: '', icon: `newVip_icon2.png`},
        {ai:true,contText: 'AI设计工作流模板库', textTitle: '尊享点数', smallText: '', icon: `newVip_icon3.png`},
        {ai:false,contText: '全球设计案例库', textTitle: '无限使用', smallText: '', icon: `newVip_icon4.png`},
        {ai:false,contText: '使用Pinlite极速版', textTitle: '网页版', smallText: '', icon: `newVip_icon5.png`},
        {ai:false,contText: '手机上用Pinlite极速版', textTitle: '移动版', smallText: 'Pin极速版', icon: `newVip_icon6.png`},
        {ai:false,contText: 'Pinlite极速版', textTitle: '无限收藏', smallText: 'Pin极速版', icon: `newVip_icon7.png`},
        {ai:false,contText: '专属宽带', textTitle: '高速访问', smallText: '', icon: `newVip_icon8.png`},
        {ai:true,contText: '大作·灵感助理', textTitle: '全功能使用', smallText: '', icon: `newVip_icon9.png`},
        {ai:true,contText: '300万诗词匹配图片', textTitle: '无限使用', smallText: '', icon: `newVip_icon10.png`},
        {ai:true,contText: 'AI查找相似图', textTitle: '全功能使用', smallText: '', icon: `newVip_icon11.png`},
        {ai:true,contText: 'AI扩大图', textTitle: '无限使用', smallText: '', icon: `newVip_icon12.png`},
        {ai:false,contText:'AI灵感图谱搜索', textTitle:'无限生成',smallText:'', icon: `newVip_icon_tp.png`},
        {ai:false,contText:'保存生成的优秀大谱', textTitle:'无限保存',smallText:'', icon: `newVip_icon_tp.png`},
        {ai:false,contText:'大谱高级版使用权益', textTitle:'每日100次',smallText:'', icon: `newVip_icon_tp.png`},
        // {ai:false,contText: '大作压缩图', textTitle: '无限使用', smallText: '', icon: `newVip_icon13.png`},
        // {ai:false,contText: '协作/私有图片集', textTitle: '无限创建', smallText: '', icon: `newVip_icon14.png`},
        // {ai:false,contText: '协作图片集', textTitle: '协作人数≤99', smallText: '', icon: `newVip_icon15.png`},
        {ai:true,contText: '一键AI智能抠图', textTitle: '专属权益', smallText: '', icon: `newVip_icon16.png`},
        {ai:true,contText: '快捷转换SVG矢量格式', textTitle: '专属权益', smallText: '', icon: `newVip_icon17.png`},
        {ai:true,contText: '自动文字擦除等AI工具', textTitle: '专属权益', smallText: '', icon: `newVip_icon18.png`},
        {ai:false,contText: '1对1支持：6天x8小时', textTitle: 'VIP专属微信客服', smallText: '', icon: `newVip_icon20.png`},
        // {contText: '设计行业专业沟通工具', textTitle: '企业版9折', smallText: '', icon: `newVip_sm_icon17.svg`},
        // {contText: '帮设计师赚点小钱', textTitle: '大作返利', smallText: '', icon: `newVip_icon18_1.svg`},
        // {contText: '同等天数', textTitle: '赠送', smallText: '原生网站', icon: `newVip_sm_icon11.svg`},
        // {contText: '同等天数', textTitle: '赠送', smallText: '原生网站', icon: `newVip_sm_icon12.svg`},
        // {contText: '同等天数', textTitle: '赠送', smallText: '原生网站', icon: `newVip_sm_icon13.svg`},
      ],
    };
  },
  async beforeMount() {
    this.priceManager = new PriceManager({
      UserInfo: this.$store.state.UserInfo,
      curTime: this.curTime,
      source: this.source,
      fromType: this.fromType
    });
    //调用接口取得报价，并初始化
    await this.priceManager.init();
    this.instanceShow = true;
  },
  activated() {
  },
  methods: {
    setTimeHandle() {
      //根据服务器和本地时间差算出正确的时间；
      //当前日期
      var now = new Date(new Date().getTime());
      // 下一天日期
      var nextday = new Date(new Date(now.getTime()).setDate(now.getDate() + 1));
      // 将下一天日期 的时间设置为 00:00:00
      var endTime = new Date(nextday.setHours(0, 0, 0));
      // 下一天零点与当前时间的时差的时间戳
      var dist = endTime.getTime() - now.getTime();
      //转换为 00:00:00 的格式
      this.nowTime = this.formatToString(dist);
      // 获取倒计时的时分秒
      let timeArr = this.nowTime.split(':');
      this.hours = timeArr[0];
      this.minutes = timeArr[1];
      this.seconds = timeArr[2];
    },
    async onOpen() {
      try {
        console.log(`%c 点击behance-首页-搜索框右侧`, 'font-size:20px; color:red', this.source);
        if (!this.firstShow) {
          this.priceManager = {}
          console.log(this.fromType)
          this.priceManager = new PriceManager({
            UserInfo: this.$store.state.UserInfo,
            curTime: this.curTime,
            source: this.source,
            fromType: this.fromType
          });
          //调用接口取得报价，并初始化
          await this.priceManager.init();
        } else {
          this.firstShow = false
        }
        this.instanceShow = true
        this.isShowRefresh = false
        this.curTime = new Date().getTime();

        BBWpriceManager.priceManager = this.priceManager;

        this.$nextTick(() => {
          this.priceManager.zfbQRcode = this.$refs.zfb_qrcode_canvas;
          this.priceManager.wxQRcode = this.$refs.wx_qrcode_canvas;
          this.priceManager.changePrice(1);
        })
      } catch (e) {
        throw e;
      }
    },
    /**
     * @description 模态框打开
     */
    async onOpened() {
      try {
        this.priceManager.zfbQRcode = this.$refs.zfb_qrcode_canvas;
        this.priceManager.wxQRcode = this.$refs.wx_qrcode_canvas;
        this.priceManager.changePrice(1);
        BigBigWork.on('OfferDialogToReferPrice', () => {
          this.idelTimerReset();
          this.priceManager.refreshOrader();
        })
        __DEV__ ? console.log(this.priceManager.priceList) : "";
        this.idelTimer = window.setInterval(() => {
          this.onIdel();
        }, 1000);
        console.log(BBWpriceManager);

        axios({
          url: ServerWww + '/getIndexMeg'
        }).then(res => {
          this.num = this.parseNumber(res.data.userNum * 0.0001)
          Cookie.set('number', (this.num).toString(), {expires: 7, domain: getDomain()})
        })
        // callback && callback(this);
      } catch (e) {
        throw e;
      }

    },
    /**
     * @description 模态框关闭
     */
    handleClose() {
      this.activeNames = true
      this.handleChange()
      let domDialogVip = this.$refs.dialogVip_new.parentNode.parentNode as HTMLDivElement
      domDialogVip.style.top = '50%'
      var art = BBWpriceManager.priceManager.priceList[0].act_title1
      //检查今天是否弹过 未 > 弹起优惠描述框 已 > 直接进行
      if (!art) {
      } else {
        if (Cookie.get("offerDialog")) {
          this.closeDialogVip()
        } else {
          // 设置cookie
          var todyTime = new Date() // 当前时间戳
          var hours = todyTime.getHours() + (todyTime.getMinutes()) / 60
          // 距零点相差的时间戳
          var useTime = (24 - hours) / 24;
          Cookie.set('offerDialog', 'true', {expires: useTime, domain: getDomain()})
          this.isShowDialogVipOfferDesc = true
          return;
        }
      }
    },

    gotoHc: function () {
      window.open('http://www.huochaibox.com', '_blank');
    },

    parseNumber: function (val) {
      if (val < 10) {
        return val.toFixed(2);
      } else {
        return parseInt(val);
      }
    },

    handleIsFour: function (val) {
      let value = 0
      if (val < 10) {
        value = val.toFixed(2) * 0.01;
      } else {
        value = parseInt(val) * 0.01;
      }
      return value > 999 ? true : false
    },

    onIdel: function () {
      this.count++;
      if (this.count === (this.outTime * 1) - 9) {
        this.isShowRefresh = true
      }
      if (this.count == this.outTime * 1) {
        this.priceManager.isIdel = true;
        console.log("闲置");
      }
    },

    handleChange(val) {
      this.activeNames = !this.activeNames
      let domDialogVip = this.$refs.dialogVip_new.parentNode.parentNode as HTMLDivElement
      let top = domDialogVip.getBoundingClientRect().top
      if (this.activeNames) {
        // domDialogVip.className += ' dialogVipHandleChange'
        this.$refs.medal__2ov9.style.height = '1200px'
        this.$refs.medal__2ov9.style['margin-top'] = '23px'
        if (top < 106) {
          domDialogVip.style.top = top + 'px'
        } else {
          domDialogVip.style.top = '106px'
        }
      } else {
        // domDialogVip.classList.remove('dialogVipHandleChange')
        domDialogVip.style.top = '50%'
        this.$refs.medal__2ov9.style.height = '0px'
        this.$refs.medal__2ov9.style['margin-top'] = '0px'
      }
    },

    changePrice: function (index) {
      this.idelTimerReset();
      this.isShowRefresh = false
      this.priceManager.changePrice(index);
    },
    idelTimerReset: function () {
      this.count = 0;
    },
    refresh: function () {
      this.idelTimerReset();
      this.isShowRefresh = false
      this.priceManager.refreshOrader();
    },
    closeDialogVip() {
      this.$emit('onClose')
      this.instanceShow = false
      window.clearInterval(this.idelTimer);
      // 初始化数据
      this.priceManager.currentIndex = -1;
      this.priceManager.closeTime = new Date().getTime();
      if (this.priceManager.paySuccess) return;
    },
    formatToString: function (time) {
      let t;
      let s = parseInt(String(time / 1000));
      if (s > -1) {
        var hour = Math.floor(s / 3600);
        var min = Math.floor(s / 60) % 60;
        var sec = s % 60;
        if (hour < 10) {
          t = '0' + hour + ":";
        } else {
          t = hour + ":";
        }

        if (min < 10) {
          t += "0";
        }
        t += min + ":";
        if (sec < 10) {
          t += "0";
        }
        t += sec;
      }
      return t;
    },
  },
  computed: {
    showDatuLogo(){
        return this.title === this.defaultTitle;
    },
    isBeInvitedUser() {
      return !!(this.$store.state.UserInfo?.isInviteExpiry || false)
    },
    dislogIsShow: function () {
      return this.instanceShow && this.isShow
    },
    title: function () {
      const isBeInvitedUser = !this.$store.state.UserInfo?.isUserPay && this.$store.state.UserInfo?.isInviteExpiry
      let title = this.defaultTitle;
      // let title =
      //     '<span >开通VIP</span>，拥有专线带宽、无限下载、独家功能等超多特权！'; // 被替换
      if (this.customTitle) {
        title = this.customTitle;
      }
      const beInvitedTitle = `您是受邀用户，在<span class='red'>${beInvitedUserOverdue(this.$store)}</span>前成为VIP，额外加送<span class='red'>1个月VIP</span>`
      if (isBeInvitedUser) {
        title = beInvitedTitle
      }
      return title;
    }
    ,
    cusClass: function () {
        const baseClass = ['dialogVip', 'dialogVip_new'];
        let classNames = [...baseClass];
        if (this.activeNames) {
          classNames.push('dialogVipHandleChange')
        }
        if ( this.customClasses.length ) {
            classNames = classNames.concat(this.customClasses)
        } else {
            if ( this.showDatuLogo ) {
                classNames.push('dialogVip_datu_bg')
            }
        }
        return classNames.join(' ')
    },

    isShowVipBox: function () {
      return this.instanceShow && this.isShow && !this.isShowDialogPaySuccess
    }
  },
  components: {
    cDialogVipOfferDesc, cDialogPaySuccess
  },
  mounted(): void {
    let _self = this
    // _self.setTimeHandle()
    BigBigWork.on(`onPaySuccess`, () => {
      this.isShowDialogPaySuccess = true
      document.body.style.paddingRight = '17px'
      window.document.body.style.overflowY = 'hidden';
    })


    // window.setInterval(function () {
    //         _self.setTimeHandle();
    //     },1000)
    // window.setInterval(() => {
    //     if (this.minsecond === 9) {
    //         this.minsecond = 0
    //     }else{
    //         this.minsecond++
    //     }
    // }, 100)
  },
}
</script>
