<template>
  <div :class="$style.searchHistory" v-if="words.length">
    <div :class="$style.label">
      <span class="font-regular">最近搜索</span>
      <div :class="$style.clear" @mousedown.stop @click.stop="deleteHistory">
        <svg  height="16" width="16" viewBox="0 0 24 24" aria-hidden="true" aria-label="" role="img"><path d="M15.18 16.95L12 13.77l-3.18 3.18c-.49.49-1.28.49-1.77 0a1.25 1.25 0 0 1 0-1.77L10.23 12 7.05 8.82a1.25 1.25 0 0 1 0-1.77 1.25 1.25 0 0 1 1.77 0L12 10.23l3.18-3.18a1.25 1.25 0 0 1 1.77 0c.49.49.49 1.28 0 1.77L13.77 12l3.18 3.18c.49.49.49 1.28 0 1.77s-1.28.49-1.77 0M24 12c0-6.63-5.37-12-12-12S0 5.37 0 12s5.37 12 12 12 12-5.37 12-12"></path></svg>
      </div>
    </div>
    <div :class="[$style.wrapper,'font-medium']">
      <a v-for="(word,index) in words" :key=index @mousedown.capture.stop="changeHistory(word)" @click.prevent.stop :class="$style.word" :href="`/tupian?kw=${word}`">{{word}}</a>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import {waitLogin} from "bbw-common";

  export default {
      name:"SearchHistory",
      props:{

      },
      mounted () {
          this.getHistory();
      },
      activated () {
          this.getHistory();
      },
      methods:{
          getHistory: async function () {
              const unlogin = `SearchHistory_${-1}`;
              localStorage.removeItem( unlogin);
              await waitLogin ( this.$store );
              const id = `SearchHistory_${this.userId}`;
              const historyStr = localStorage.getItem(id);
              this.words = historyStr ? JSON.parse(historyStr):[];
              this.$emit('isHistory',this.words)
          },
          deleteHistory:function (index) {
              const id = `SearchHistory_${this.userId}`;
              localStorage.removeItem(id);
              this.$set( this, 'words', [])
              this.$emit('isHistory', this.words)
          },
          changeHistory(kw){
              this.$emit("changeHistory", kw);
              this.$router.push({path:"/tupian",query:{kw:kw}});
          }
      },
      data(){
        return{
            words:[]
        }
      },
      computed:{
          userId:function () {
            return this.$store.state.UserInfo.id;
          }
      },
    watch:{
        'words':function(newV,oldV){
          this.words = newV
        }
    }
  }
</script>
<style src="./SearchHistory.scss" module></style>
