var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.recommendedWrapper },
    [
      _vm._l(_vm.recommendedWords, function (word, index) {
        return _c(
          "div",
          {
            key: index,
            class: [
              _vm.$style.recommendedWord,
              _vm.isActiveItem("words", index) ? _vm.$style.hover : "",
            ],
          },
          [
            _c(
              "a",
              {
                attrs: { href: "/tupian?kw=" + word },
                on: {
                  "!mousedown": function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.handleClick(word)
                  },
                  mouseenter: function ($event) {
                    return _vm.onItemEnter(index)
                  },
                  mouseleave: function ($event) {
                    return _vm.onItemLeave(index)
                  },
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("span", { class: _vm.$style.normal }, [
                  _vm._v(_vm._s(_vm.recommendedLeft(_vm.searchWord, word))),
                ]),
                _c("span", { class: _vm.$style.bold }, [
                  _vm._v(_vm._s(_vm.recommendedRight(_vm.searchWord, word))),
                ]),
              ]
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.searchWord && _vm.recommendBoardsList.length > 0,
              expression: "searchWord && recommendBoardsList.length > 0",
            },
          ],
          class: _vm.$style.recommendBoards,
          style: { paddingTop: _vm.recommendedWords.length ? "8px" : "" },
        },
        [
          _c("div", { class: [_vm.$style.recommendTitle, "font-regular"] }, [
            _vm._v("大作灵感图片集推荐"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.recommendBoardsList, function (item, index) {
            return _c(
              "div",
              {
                key: item.uuid,
                class: [
                  _vm.$style.recommendBoardsItem,
                  _vm.isActiveItem("boards", index) ? _vm.$style.hover : "",
                ],
                on: {
                  "!mousedown": function ($event) {
                    $event.stopPropagation()
                    return _vm.goToBoard(item)
                  },
                },
              },
              [
                _c("div", { class: _vm.$style.BoardsImg }, [
                  _c("img", {
                    attrs: {
                      src:
                        _vm.toRelativeProtocol(item.cover1) +
                        "?x-oss-process=style/pc_100_jpg",
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.BoardsInfo }, [
                  _c(
                    "div",
                    { class: [_vm.$style.BoardsInfoTitle, "font-medium"] },
                    [_vm._v(_vm._s(item.board_title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: [_vm.$style.BoardsInfoDesc, "font-regular"] },
                    [_vm._v(_vm._s(item.username))]
                  ),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.$style.moreBoards,
                "font-medium",
                _vm.isActiveItem("moreBoards", 0) ? _vm.$style.hover : "",
              ],
              on: {
                "!mousedown": function ($event) {
                  $event.stopPropagation()
                  return _vm.goToMoreBoards({ more: _vm.keyword })
                },
              },
            },
            [
              _vm._v(
                "\n      查看更多“" + _vm._s(_vm.keyword) + "”大作图片集\n    "
              ),
            ]
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }