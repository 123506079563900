var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.dropdownToolsBox] }, [
    _c("div", { staticClass: "dropdownMain" }, [
      _c(
        "div",
        { class: _vm.$style.tools },
        [
          _c("div", { class: _vm.$style.version }, [
            _c("h4", [_vm._v("特色功能")]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.extVersion,
                    expression: "extVersion",
                  },
                ],
              },
              [_vm._v("版本" + _vm._s(_vm.extVersion))]
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "a",
              {
                key: index,
                class: [
                  _vm.$style.toolsItem,
                  item.class ? _vm.$style[item.class] : "",
                ],
                attrs: { href: item.link, target: "_blank" },
              },
              [
                _c("p", {
                  class: [_vm.$style.toolsName, "font-regular"],
                  domProps: { innerHTML: _vm._s(item.name) },
                }),
              ]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }