<template>
    <el-dialog :visible="isShow"
               custom-class="cDialogPaySuccess"
               :append-to-body="true"
               :close-on-click-modal="false"
               @close="onClose"
               :show-close="false"
               width="400px"
               top="0px">
        <div :class="[$style.weixinprepaycontent,'font-medium']">
          <img :class="$style.img_success" :src="`${process.env.CDN_WIMG_PATH}/p/images/payment_sucess.png`">
          <div :class="$style.success_text">支付成功</div>
          <div :class="$style.vip_text1">扫码添加VIP专属客服,</div>
          <div :class="$style.vip_text2">额外<span class="font-bold">免费</span>多得<span class="font-bold">10天VIP</span>!</div>
          <img :class="$style.img_code" :src="`${process.env.CDN_XCX_PATH}/p/code/code2x_5.png`">
          <!--<div :class="$style.code_text1">扫码添加福利官</div>-->
          <!--<div :class="$style.code_text2">回复“<span class="font-bold">VIP</span>”立即免费领取</div>-->
          <div :class="$style.code_tip">刷新已打开的页面，即可使用VIP权益。</div>
          <div :class="$style.code_button" @click="closed">
            <span>完成添加VIP专属客服</span>
          </div>
        </div>

    </el-dialog>
</template>
<script lang=ts>
    import Vue from "vue";
    const Dialog = require("element-ui/lib/dialog").default;

    Vue.use(Dialog)

    export default {
        name: "cDialogPaySuccess",
        data: function () {
            return {
                isShow: true
            }
        },
        methods: {
            onClose() {
              // 压缩图不刷新页面
              if (!window.location.href.match('yasuotu')){
                window.location.reload();
              }else{
                document.body.style.paddingRight = ''
                window.document.body.style.overflowY = '';
                 this.$store.dispatch ( `UserInfo/FETCH_USER_INFO`, true) ;
              }
            },
          closed(){
            this.isShow = false
          }
        },
        props: {
            testProp: {
                type: String,
                required: false
            }
        },
        mounted() {
        }
    }
</script>
<style lang=scss module src="./DialogPaySuccess.scss">
</style>
