var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.token
    ? _c(
        "div",
        {
          staticClass: "c-header c-globalHeader",
          class: _vm.$style.headerNavBar,
        },
        [
          _c(
            "div",
            { staticClass: "c-header-l", staticStyle: { "margin-left": "0" } },
            [
              _c(
                "div",
                {
                  staticClass: "c-header-li font-regular",
                  staticStyle: {
                    "line-height": "24px",
                    "margin-left": "0",
                    "font-size": "16px",
                  },
                },
                [_vm._v("\n        Hi，欢迎来到Pinlite极速版！\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-header-li" },
                [
                  _c("HeaderNavItem", { attrs: { type: "xs", link: "/" } }, [
                    _vm._v("极速版首页"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "c-header-li font-medium",
                  class: _vm.$style.mobile_header,
                  on: { click: _vm.moveUseClick },
                },
                [_vm._v("\n         移动版\n")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("header-navigation-right-items", {
            attrs: {
              isBBW: false,
              "show-list": ["pinliteBackToSearch", "vip", "myCloud"],
              "stats-vip": _vm.statsVip,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }