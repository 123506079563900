<template>
  <div :class="[$style.userTypeBox]" @click.prevent.stop="buyVip()">
    <div :class="[$style.dropdownMain,'dropdownMain']">
      <div :class="[$style['top-content']]">
        <div :class="$style.usetype">
          <p class="font-medium" v-html="vipName"></p>
          <p v-if=" userInfo.userType!=6 && userInfo.userType!=7 && userInfo.vipDays ==0 " :class="[$style.smallTip, 'font-regular']">您还不是VIP特权用户</p>
          <p v-if=" userInfo.userType==6 && userInfo.vipDays==0||userInfo.userType==7&& userInfo.vipDays==0"
             :class="[$style.smallTip, 'font-regular']" >您的大作VIP特权已到期</p>
          <p v-if="userInfo.vipDays>90" :class="[$style.smallTip, 'font-regular']">
            您的大作VIP特权：<span>{{userInfo.vipDays}}</span>天</p>
          <p v-if="userInfo.vipDays>0&&userInfo.vipDays<=90"
             :class="[$style.smallTip, 'font-regular']">
            您的大作VIP特权：<span>{{userInfo.vipDays}}天后</span>到期</p>
          <p></p>
        </div>
        <button @click.prevent.stop="buyVip()">
          <p class="font-medium">{{userInfo.userType==6||userInfo.userType==7 ? "续费VIP" : "开通VIP"}}
            <a  v-if="$store.state.Message && $store.state.Message.vipTips" class="font-bold">{{ $store.state.Message.vipTips }}</a>
          </p>
        </button>
      </div>
      <div :class="$style['bottom-content']"  @click.prevent.stop="buyVip()">
        <div :class="[$style.title, 'font-regular']">
          <p class="font-medium">大作VIP尊享28项特权</p>
<!--          <p :class="$style.smalltitle">专属客服1对1支持</p>-->
        </div>
        <!------------------ 权益开始 ------------------------>
        <div :class="$style.wrapList">
          <div :class="$style.item" v-for="(right,index) in rights" :key=index>
            <div :class="[$style.rights, $style[right.className]]"></div>
            <div :class="$style.on_fun" v-if="right.smalltext">{{right.smalltext}}</div>
            <p class="font-bold">{{right.title}}</p>
            <p :class="[$style.constr, 'font-regular']">{{right.desc}}</p>
          </div>
        </div>
        <!------------------ 权益结束 ------------------------>
      </div>
      <div :class="[$style.more,'font-regular']">
        <a @click.prevent.stop="buyVip('pl下拉-查看更多版权')" :href="PageVersionDiff+'?type=33'" target="_blank"> 查看大作VIP特权 > </a>
      </div>
    </div>
  </div>
</template>
<script lang=ts>
    import { PageVersionDiff, buyVip } from "bbw-common";

    export default {
        name:"cHeaderDropdownVip",
        props:{

        },
        data(){
            return {
                PageVersionDiff,
                msg:""
            }
        },
        mounted() {

        },
        methods: {
            buyVip: function (text) {
                const fromText = this.userInfo.userType==6||this.userInfo.userType==7 ? "pl下拉-续费大作vip" : "pl下拉-开通大作vip"
                buyVip('bbw',this.$store, text || fromText,33, true)
            }
        },
        computed: {
          vipName(){
            if(this.$store.state.UserInfo.vipDays>61){
              return "VIP用户"
            }else if(this.$store.state.UserInfo.vipDays<61 && this.$store.state.UserInfo.vipDays>0){
              return "<span style='color:#FF4D4D'>VIP即将到期</span>"
            }else{
              return '普通用户'
            }
            //return this.$store.state.UserInfo.vipDays>0 ? "VIP用户" : "普通用户";
          },
            btnStyle(){
                return [ "VIPbtn", this.$store.state.UserInfo.isUserPay ? 'btnTrans':'btnPink'];
            },
            userInfo(){
                return this.$store.state.UserInfo;
            },
            rights(){
                return [
                    { className:"flwsx", title:`网页版`, desc:"免工具Pinterest搜图",smalltext:'极速版'},
                    { className:"wyb", title:`无限下载收藏`, desc:"Pinterest原图"},
                    { className:"wxxz", title:`高速下载`, desc:"专属宽带"},
                    { className:"gsxz", title:`全功能使用`, desc:"AI查找相似图"},
                    { className:"zsbe", title:`赠送`, desc:"同等天数",smalltext:'原生网站'},
                    { className:"zspin", title:`赠送`, desc:"同等天数",smalltext:'原生网站'},
                    { className:"wxsc", title:`无限上传`, desc:"收藏/采集/上传图片"},
                    { className:"wxxzdatu", title:`无限下载`, desc:"3000万免费版权原图"}
                ]
            }
        }
    };
</script>
<style src="./HeaderDropdownVip.scss" module></style>
