var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.words.length
    ? _c("div", { class: _vm.$style.searchHistory }, [
        _c("div", { class: _vm.$style.label }, [
          _c("span", { staticClass: "font-regular" }, [_vm._v("最近搜索")]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.$style.clear,
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                },
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.deleteHistory.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    height: "16",
                    width: "16",
                    viewBox: "0 0 24 24",
                    "aria-hidden": "true",
                    "aria-label": "",
                    role: "img",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M15.18 16.95L12 13.77l-3.18 3.18c-.49.49-1.28.49-1.77 0a1.25 1.25 0 0 1 0-1.77L10.23 12 7.05 8.82a1.25 1.25 0 0 1 0-1.77 1.25 1.25 0 0 1 1.77 0L12 10.23l3.18-3.18a1.25 1.25 0 0 1 1.77 0c.49.49.49 1.28 0 1.77L13.77 12l3.18 3.18c.49.49.49 1.28 0 1.77s-1.28.49-1.77 0M24 12c0-6.63-5.37-12-12-12S0 5.37 0 12s5.37 12 12 12 12-5.37 12-12",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: [_vm.$style.wrapper, "font-medium"] },
          _vm._l(_vm.words, function (word, index) {
            return _c(
              "a",
              {
                key: index,
                class: _vm.$style.word,
                attrs: { href: "/tupian?kw=" + word },
                on: {
                  "!mousedown": function ($event) {
                    $event.stopPropagation()
                    return _vm.changeHistory(word)
                  },
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                  },
                },
              },
              [_vm._v(_vm._s(word))]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }