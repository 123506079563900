
import {
    downloadImg,
    getDownloadImageUrl,
    StoreDownload,
    getToken,
    goPinliteRegister,
    buyVip,
    SearchProxy,
    toRelativeProtocol,
    ProxyImageStyle,
    BigBigWork,
    PagePinliteTupian,
    PinliteItem,
    ResultItem,
    getTokenCrossDomain,
    getDomain,
    LocalStorageWrite,
    LocalStorageRead, PageSearchAuth, getDownloadState, statsDzActionClick
} from "bbw-common" ;
import { Store } from "vuex" ;
import Cookies from "js-cookie" ;
import dayjs from "dayjs" ;
import { Hint, Scripts as bbwComponents } from "bbw-components" ;
import Vue from "vue" ;

/** 关键词是否需要翻译 */
export const needTranslate = ( str:string ) => {
    const result = str.match ( /[\u4e00-\u9fa5]+/i ) ;
    return !!result ;
} ;

/** 设置瀑布流显示尺寸 **/
export const resizeItem = ( item:ResultItem ) => {
    const h = 236 / item.w * item.h ;
    item.dw = 236 ;
    if ( item.w < 236 && item.h < 524 ) {
        item.dh = Math.max ( item.h + 24, 155 + 24 ) ;
        return item ;
    }
    item.dh = h + 24 ;
    // item.dh = Math.min ( 500 + 24, item.dh ) ;
    item.dh = Math.max ( Math.min ( 500 + 24, item.dh ), 180 ) ;
    return item ;
} ;

/** 一像素透明图片 */
export const emptyImg = `data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==` ;

export const createDialogKF = ( () => {
    let instanceDialogKF = null ;
    return async function ( options:{
        store:Store<any>,
        type: Number
    } ) {
        if ( !instanceDialogKF ) {
            const dialogKF = await import ( `bbw-components/src/components/DialogKF` ) ;
            // @ts-ignore
            instanceDialogKF = new Vue ( {
                ...dialogKF.default,
                props: {
                    isShow: {
                        type: Boolean,
                        default: true,
                        require: false
                    },
                    type: {
                        type: Number,
                        default: 1
                    }
                }
            } ) ;
            instanceDialogKF.$store = options.store ;
            const eld = document.createElement ( `div` ) ;
            instanceDialogKF.$mount ( eld ) ;
            document.body.appendChild ( eld ) ;
            instanceDialogKF.$nextTick ().then ( res => {
                instanceDialogKF.isShow = true ;
                instanceDialogKF.type = options.type ;
            } ) ;
        } else {
            instanceDialogKF.isShow = true ;
            instanceDialogKF.isShowBox = true ;
            instanceDialogKF.type = options.type ;
        }
    } ;
} ) () ;
/**
 * pinlite下载图片流程
 * @param item 下载的图片item
 * @param store vuex
 * @param fromText 统计来源文本
 * @param fromType 统计来源类型
 */
export const download = async ( item:ResultItem, store:Store<any>, fromText:string, fromType:number, resource = `` ) => {
    try {
        // 注册下载模块
        StoreDownload.CreateUserDownload ( store ) ;
        // 不是pinlite vip的，去获取可下载数
        if ( !store.getters[`UserInfo/isPinliteVip`] ) {
            // 新下载逻辑 http://doc.bigbigwork.com/docs/dz2023/7075

            const [err, res] = await getDownloadState () ;
            if ( err ) {
                throw err ;
            }
            // 获取今日可下载数
            // await store.dispatch ( `UserDownload/FETCH_CAN_DOWNLOAD` ) ;
            if ( res.k_type === 1 ) {
                await bbwComponents.openVipDialog ( { store, customTitle: `<span class="red">开通VIP</span>，免工具直接使用Pin站（支持电脑和手机）！`, source: fromText, fromType } ) ;
                return `noVip` ;
            } else if ( res.k_type === 2 ) {
                // 弹客服框A
                // createDialogKF( { store, type: 1 } )
            } else if ( res.k_type === 3 ) {
                // 弹客服框B
                createDialogKF ( { store, type: 2 } ) ;
                statsDzActionClick ( {
                    store,
                    action_type: `弹客服框`,
                    source: `pc`,
                    remark: `k_type` + res.k_type
                } ) ;
            }
            if ( res.d_type === 0 ) {
                return ;
            }
        }
        if ( !addTodayDownloadTimes () ) {
            const hint = new Hint ( {
                fadeDelay: 3000,
                theme: `sensitive-hint collection-tipsbox`,
                content:
                    `<div>下载太频繁了，明天再来吧</div> <div>如有需要可以右侧加客服联系</div>`
            } ) ;
            return ;
        }
        if ( !addHourDownloadTimes () ) {
            const hint = new Hint ( {
                fadeDelay: 3000,
                theme: `sensitive-hint collection-tipsbox`,
                content:
                    `<div>下载太频繁了，休息一会再来吧</div>`
            } ) ;
            return ;
        }
        const url = await getDownloadImageUrl ( item, null, true, store ) ;
        // 开始下载
        downloadImg ( `${url}` ) ;
        // 非VIP增加图片下载数
        // if ( !store.getters[`UserInfo/isPinliteVip`] ) {
        //     await store.dispatch ( `UserDownload/FETCH_ADD_TODAY_DOWNLOAD` ) ;
        // }
        const token = await getTokenCrossDomain () ;
        // 统计下载
        // http://doc.bigbigwork.com/docs/kibana/1749
        BigBigWork.emit ( `Analysis`, {
            type: `AnalysisDownloadImage`,
            data: {
                userid: store.state.UserInfo.id,
                type: `pc`,
                source_board_id: ``,
                search_word: store.state.analysis.searchword,
                is_pay_vip: !!( store.state.UserInfo.isUserPay && store.state.UserInfo.vipDays > 0 ),
                is_pay: store.state.UserInfo.isUserPay ? `yes` : `no`,
                register_days: store.state.UserInfo.registerDays,
                login_days: store.state.UserInfo.loginDays,
                img_width: item.w,
                img_height: item.h,
                resource: resource,
                accessToken: token
            }
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;

/**
 * 检查是否登录
 * @param store 组件$store
 * @param force 是否从服务器重新获取用户数据
 * @param autoRegister 是否自动跳转到注册界面
 * @return 用户token
 */
export const checkLogin = async ( store:Store<any>, redirect:string, force = false, autoRegister = true ):Promise<string> => {
    try {
        const token = await getTokenCrossDomain () ;
        if ( !token ) {
            autoRegister && goPinliteRegister ( redirect ) ;
            return Promise.resolve ( `` ) ;
        }
        // 如果是强制模式，则去获取一遍用户信息
        if ( force ) {
            await store.dispatch ( `UserInfo/FETCH_USER_INFO` ) ;
        }
        // 从store中的状态判断是否登录
        if ( store.getters[`UserInfo/userReady`] ) {
            return token ;
        } else {
            autoRegister && goPinliteRegister ( redirect ) ;
            return `` ;
        }
    } catch ( e ) {
        throw e ;
    }
} ;

/** 客服二维码地址 */
export const ServiceQR = `${process.env.CDN_DZ_PATH}/p/pinlite_channel.jpg` ;

/** 根据URL获取代理图片地址 */
export const resizeUrl = ( item:PinliteItem, url:string, imageStyle:ProxyImageStyle = null ) => {
    if ( url === emptyImg ) return url ;
    const src = SearchProxy.getImgUrl ( url, imageStyle, item ) ;
    return toRelativeProtocol ( src ) ;
} ;
/** pinlite用多尺寸代理图片地址 */
export const multiSrc = ( item:PinliteItem, url ) => {
    if ( url === emptyImg ) return url ;
    let srcset = `` ;
    srcset = resizeUrl ( item, item.images[`236x`].url, null ) + ` 1x,` ;
    srcset += resizeUrl ( item, item.images[`474x`].url, null ) + ` 2x,` ;
    return srcset ;
} ;

/** pinlite用多尺寸代理图片地址 */
export const multiBurlSrc = ( item:PinliteItem, url ) => {
    if ( url === emptyImg ) return url ;
    let srcset = `` ;
    srcset = resizeUrl ( item, item.images[`474x`].url, null ) + ` 1x,` ;
    srcset += resizeUrl ( item, item.images[`736x`].url, null ) + ` 2x,` ;
    return srcset ;
} ;
export const multiBgSrc = ( item:PinliteItem, url, prefix:string = ``, imageStyle1:ProxyImageStyle = `tabulation`, imageStyle2:ProxyImageStyle = `tabulation_2` ) => {
    if ( url === emptyImg ) return url ;
    let srcset = `` ;
    if ( prefix !== null ) {
        srcset += `${prefix}image-set(url("${resizeUrl ( item, item.images[`236x`].url, null )}") 1x, url("${resizeUrl ( item, item.images[`474x`].url, null )}") 2x ),` ;
    }
    srcset += `url('${resizeUrl ( item, item.images[`236x`].url )}')` ;
    return srcset ;
} ;
/** 过滤页面浏览历史，是否可以回退 */
export const filterHistory = () => {
    // @ts-ignore
    if ( window?.routerHistory?.length > 1 ) {
        // @ts-ignore
        const latest:string = window.routerHistory[window.routerHistory.length - 2] ;
        if ( latest.match ( /(^\/tupian|^\/$)/ ) ) {
            return null ;
        }
    }
    return `/tupian` ;
} ;

/** 标识是否是从大作大图等网站点过来的，是的话不弹红包 */
export const setFrom = () => {
    const todyTime = new Date () ; // 当前时间戳
    const hours = todyTime.getHours () + ( todyTime.getMinutes () ) / 60 ;
    // 距零点相差的时间戳
    const useTime = ( 24 - hours ) / 24 ;
    Cookies.set ( `authFrom`, `1`, { expires: useTime, domain: getDomain () } ) ;
} ;
/** 增加今天下载数 */
export const addTodayDownloadTimes = () => {
    const num:number = LocalStorageRead ( `pin-today-download`, `` ) || 0 ;
    // 一天3000张限额
    if ( num > 3000 ) { return false ; }
    const endTime = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
    LocalStorageWrite ( `pin-today-download`, ``, num + 1, { expires: endTime.diff ( dayjs () ), unit: `millisecond` } ) ;
    return true ;
} ;
/** 增加当天下载数 */
export const addHourDownloadTimes = () => {
    const num:number = LocalStorageRead ( `pin-hour-download`, `` ) || 0 ;
    // 一小时1000张限额
    if ( num > 1000 ) { return false ; }
    const endTime = dayjs ().startOf ( `hour` ).add ( 1, `hour` ) ;
    LocalStorageWrite ( `pin-hour-download`, ``, num + 1, { expires: endTime.diff ( dayjs () ), unit: `millisecond` } ) ;
    return true ;
} ;
/** 获得大作登录url */
export const getAuthUrl = ( store:Store<any>, url:string ) => {
    if ( store.state.token ) {
        return `${PageSearchAuth}?token=${store.state.token}&redirect=${encodeURIComponent ( url )}` ;
    } else {
        return url ;
    }
} ;

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
