<template>
    <div :class="$style['bbw-mask-btn']">
        <button :style="ops" @click="btnClick" :class="[mask?$style.mask: '']">
            <img :class="$style.normalIcon" :src="icon" alt="" v-if="icon">
            <img :class="$style.hoverIcon" :src="hoverIcon || icon" v-if="icon" alt="">
            <slot></slot>
        </button>
    </div>
</template>

<script>
    export default {
        name: "cButtonMask",
        props:{
            'icon': {
                type: String,
                required: false,
                default: ''
            },
            'hoverIcon': {
                type: String,
                required: false,
                default: ''
            },
            'ops': {
                type: Object,
                required: false,
                default: () => {
                    return {
                        width: '48px',
                        height: '48px'
                    }
                }
            },
            'mask': {
                type: Boolean,
                required: false,
                default: true
            }
        },
        methods: {
            btnClick () {
                this.$emit('onBtnCli')
            }
        }
    }
</script>

<style lang="scss" module src="./ButtonMask.scss">

</style>
