var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: ["bbw-user-area", _vm.styleName, _vm.themeColor],
      attrs: { target: "_blank", href: _vm.dz },
      on: { click: _vm.userClick },
    },
    [
      _c("img", {
        staticClass: "avatar",
        attrs: { alt: "头像", src: _vm.avaterImg },
      }),
      _vm._v(" "),
      _c("span", { class: ["desc", "font-medium"] }, [_vm._v("我的设计云")]),
      _vm._v(" "),
      _vm.isShowUserGui
        ? _c("GuideCircle", {
            class: _vm.$style.guide_box,
            attrs: { options: _vm.userGuideOptions },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }