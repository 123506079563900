var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hotWordList.length > 0
    ? _c(
        "div",
        {
          class: _vm.$style.hotWordsBox,
          style: { paddingTop: !_vm.isHistoryVal ? "23px" : "" },
        },
        [
          _c("div", { class: _vm.$style.hotWords }, [
            _c("div", { class: [_vm.$style.hotWordsTitle, "font-regular"] }, [
              _vm._v("热门搜索"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$style.hotWordsList },
              _vm._l(_vm.hotWordList, function (item, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    class: [_vm.$style.hotWordsItem, "font-medium"],
                    attrs: { href: "/tupian?kw=" + item.word },
                    on: {
                      "!mousedown": function ($event) {
                        $event.stopPropagation()
                        return _vm.hotWordToSearch(item.word)
                      },
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.word))]
                )
              }),
              0
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }