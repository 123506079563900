<style lang="scss" module src="./notification.scss"></style>
<!--<style lang="scss" src="./notification.scss"></style>-->
<template>
<!--    <div>-->
        <div id="msg-box">
            <div id="msg-cont" class="dropdownMain">
                <div class="first-level-title">
                    <span class="close-icon" @click="closeNotifi"></span>
                    消息
                </div>
                <div class="first-level-content">
                    <ul>
                        <li class="first-level-li" @click="showNotificationBox('coopreation')">
<!--                            <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/notice_icon_1.svg">-->
<!--                            <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/notice_icon_hover_1.svg">-->
                            <div :class="['first-level-icon', firstLevelData[0].iconType]"></div>
                            <div class="li-cont">{{firstLevelData[0].title}}
                                <span v-show="unReadCount.cooperation > 0" class="unread-count">{{unReadCount.cooperation}}</span>
                            </div>
                        </li>
                        <li class="first-level-li" @click="showNotificationBox('follow')">
<!--                            <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/notice_icon_2.svg">-->
<!--                            <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/notice_icon_hover_2.svg">-->
                            <div :class="['first-level-icon', firstLevelData[1].iconType]"></div>
                            <div class="li-cont">{{firstLevelData[1].title}}
                                <span v-show="unReadCount.follow > 0" class="unread-count">{{unReadCount.follow}}</span>
                            </div>
                        </li>
                        <li class="first-level-li" @click="showNotificationBox('notification')">
<!--                            <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/notice_icon_3.svg">-->
<!--                            <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/notice_icon_hover_3.svg">-->
                            <div :class="['first-level-icon', firstLevelData[2].iconType]"></div>
                            <div class="li-cont">{{firstLevelData[2].title}}
                                <span v-show="unReadCount.notice > 0" class="unread-count">{{unReadCount.notice}}</span>
                            </div>
                        </li>
                        <li class="first-level-li" @click="showNotificationBox('notice')">
<!--                            <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/notice_icon_4.svg">-->
<!--                            <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/notice_icon_hover_4.svg">-->
                            <div :class="['first-level-icon', firstLevelData[3].iconType]"></div>
                            <div class="li-cont">{{firstLevelData[3].title}}
                                <span v-show="unReadCount.announcement > 0" class="unread-count">{{unReadCount.announcement}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <!--    协作-->
                <transition name="secondary-box">
                    <div class="secondary-box" v-show="coopreation">
                        <div class="title">
                            协作
                            <span class="close-icon" @click="closeThis('coopreation')"></span>
                            <span class="view-us-coopreation" @click="viewBoard">查看我的协作</span>
                        </div>
                        <div class="secondary-cont" style="height: 392px">
                            <Vuescroll :ops="scrollOps">
                                <ul>

                                    <!--               图片集新增成员模板-->
                                    <li v-show="true" v-for="(item,index) in coopreationMsg"
                                        :class="[item.status===0?'no-read':'']"
                                        v-html="item.content">
                                    </li>
                                    <!--      图片集新增图片模板-->
                                </ul>
                            </Vuescroll>
                        </div>
                    </div>
                </transition>
                <!--    关注-->
                <transition name="secondary-box">
                    <div class="secondary-box" v-show="follow">
                        <div class="title">
                            关注
                            <span class="close-icon" @click="closeThis('follow')"></span>
                            <span class="view-us-coopreation" @click="viewFans">查看我的粉丝</span>
                        </div>
                        <div class="secondary-cont" style="height: 392px">
                            <Vuescroll :ops="scrollOps">
                                <ul>
                                    <!--              谁 关注了 你 模板-->
                                    <li v-for="(item,index) in followMsg" :class="[item.status===0?'no-read':'']"
                                        v-html="item.content">
                                    </li>
                                    <!--      谁 关注了 你的图片集 模板-->
                                </ul>
                            </Vuescroll>
                        </div>
                    </div>
                </transition>
                <!--    通知-->
                <transition name="secondary-box">
                    <div class="secondary-box" v-show="notification">
                        <div class="title">
                            通知
                            <span class="close-icon" @click="closeThis('notification')"></span>
                            <span class="view-us-coopreation"></span>
                        </div>
                        <div class="secondary-cont" style="height: 392px">
                            <!--                        @handle-scroll="handleScrollNotification"-->
                            <Vuescroll :ops="scrollOps" >
                                <ul ref="notification-list">
<!--                                  昨日邀请数量模板-->
                                    <transition v-if="false" name="noti-box">
                                        <li class="">
                                            <div class="cnt-noti">
                                                <div class="ava-box">
                                                    <a class="avatar pointer no-radius" href="javascript: void(0)">
                                                        <img
                                                                :src="`${process.env.CDN_XCX_PATH}/p/images/msg-invite-status.svg`"
                                                                alt="">
                                                    </a>
                                                </div>
                                                <div>
                                                <p>恭喜!您邀请的好友成为VIP！</p>
                                                <p>预计可返现：<span class="black-m">20</span>元</p>
                                                <p>您的VIP特权已过期，只需在</p>
                                                <p><span class="black-m">14天内</span><a href="" class="link link-inline">再次成为VIP</a></p>
                                                <p>这笔现金奖励可为您保留哦！</p>
                                                <div class="push-time">一天前</div>
                                              </div>
                                            </div>
                                            <div class="del-this"></div>
                                        </li>
                                    </transition>

                                    <!--             vip续费模板-->
                                    <transition v-if="true" name="noti-box" v-for="(item,index) in notificationMsg" :key="item.id">
                                        <li :class="[item.status===0?'no-read':'']" v-if="item.show">
                                            <div class="cnt-noti" v-html="item.content">
                                            </div>
                                            <div class="del-this" @click="delThisMsg(item,index)"></div>
                                        </li>
                                    </transition>
                                    <!--      设计师状态更新-->
                                </ul>
                            </Vuescroll>
                        </div>
                    </div>
                </transition>
                <!--    公告-->
                <transition name="secondary-box">
                    <div class="secondary-box" v-show="notice">
                        <div class="title">
                            公告
                            <span class="close-icon" @click="closeThis('notice')"></span>
                        </div>
                        <div class="secondary-cont" style="height: 392px">
                            <!--                        @handle-scroll="handleScroll"-->
                            <Vuescroll :ops="scrollOps">
                                <ul ref="secondary-list">

                                    <!--               系统公告 模板
                                    v-for="(item,index) in noticeMsg"
                                    -->
                                    <li :class="[item.status===0?'no-read':'','msg-system',item.link?'linkBoxHover':'']"
                                        v-for="(item,index) in noticeMsg"
                                        @click="item.link?goToLink(item.link):''"
                                    >
                                        <div class="ava-box">
                                            <div class="avatar"><img :src="item.avatar"></div>
                                        </div>
                                        <div class="system-content">
                                            <div class="cop-cont msg-system-title black-m" v-html="item.title"></div>
                                            <div class="msg-system-detail">
                                                {{item.content}}
                                                <a v-if="!!item.link" @click.stop="" class="sys-link" target="black" :href="item.link">{{item.link_font}}</a>
                                            </div>
                                            <div class="push-time">{{item.datetime_str}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </Vuescroll>
                        </div>
                    </div>
                </transition>
            </div>

        </div>
<!--    </div>-->
</template>

<script lang=ts>
    import Vuescroll from 'vuescroll';
    import axios from 'axios'
    import {
        ServerNoticeServer,
        getToken,
        ServerWww,
        PageInvite,
        EncryptionDecryptParam,
        PageVersionDiff,
        getAuthUrl
    } from 'bbw-common'
    // import $ from 'jquery'
    import { mapState } from "vuex";

    export default {
        name: "cHeaderNotification",
        props: {
            /** 是否要通过auth页面登录跳转 */
            auth: {
                type: Boolean,
                required:false,
                default:false
            },
            msgShow: {
                type: Boolean,
                default: false
            },
            designUrl: {
                type: String,
                default: ''
            }
        },
        data: function () {
            return {
                // unReadCount: {
                //     cooperation: 0,
                //     follow: 0,
                //     notice: 0,
                //     announcement: 0},
                firstLevelData: [
                  {title: '协作', iconType:'xz'},
                  {title: '关注', iconType:'gz'},
                  {title: '通知', iconType:'tz'},
                  {title: '公告', iconType:'gg'}
                ],
                coopreation: false,
                follow: false,
                notification: false,
                notice: false,
                noticeParam: {
                    page: 1,
                    size: 25,
                    isRequesting: false,
                    isEnd: false
                },
                coopreationMsg: [],
                followMsg: [],
                notificationMsg: [],
                notificationParam: {
                    page: 1,
                    size: 25,
                    isRequesting: false,
                    isEnd: false
                },
                noticeMsg: [],
                scrollOps: {
                    vuescroll: {
                        mode: 'native',
                        sizeStrategy: 'percent',
                        detectResize: true,
                        wheelScrollDuration: 150,
                    },
                    bar: {
                        showDelay: 1000,
                        onlyShowBarOnScroll: true,
                        keepShow: false,
                        background: '#999999',
                        opacity: 1,
                        hoverStyle: false,
                        specifyBorderRadius: false,
                        minSize: false,
                        size: '6px',
                        disable: false,
                    },
                    rail: {
                        background: '#F5F5F5',
                        opacity: 0,
                        size: '10px',
                        specifyBorderRadius: false,
                        gutterOfEnds: null,
                        gutterOfSide: '2px',
                        keepShow: false
                    }
                },
                testStatus: true,
                secondaryListHeight: 0,
                notificationListHeight: 0,
                ServerWww
            }
        },
        components: {
            Vuescroll
        },
        mounted() {
            const scrollUnique = (function () {
                var eventType = 'mousewheel';
                if ((document as any).mozHidden !== undefined) {
                    eventType = 'DOMMouseScroll';
                }
                return function (ele) {
                    ele.addEventListener(eventType,function (event) {
                        var scrollTop = this.scrollTop,
                            scrollHeight = this.scrollHeight,
                            height = this.clientHeight;
                        var delta = (event.wheelDelta) ? event.wheelDelta : -(event.wheelDelta || 0);

                        if ((delta > 0 && scrollTop <= delta) || (delta < 0 && scrollHeight - height - scrollTop <= -1 * delta)) {
                            // IE浏览器下滚动会跨越边界直接影响父级滚动，因此，临界时候手动边界滚动定位
                            this.scrollTop = delta > 0 ? 0 : scrollHeight;
                            // 向上滚 || 向下滚
                            event.preventDefault();
                        }
                    })
                }
            })()
            const eleScrollList = document.querySelectorAll('#msg-cont .__panel')

            eleScrollList.forEach(ele => {
                scrollUnique(ele)
            })
        },
        methods: {
            goToLink(link){
                if( this.auth && link.match(/bigbigwork.com/) ){
                    window.open(getAuthUrl(this.$store,link),'_blank')
                }else{
                    window.open(link,'_blank')
                }

            },
            initNotice() {
                this.noticeMsg = []
                this.noticeParam = {
                    page: 1,
                    size: 25,
                    isRequesting: false,
                    isEnd: false
                }
            },
            initNotification() {
                this.notificationParam = {
                    page: 1,
                    size: 25,
                    isRequesting: false,
                    isEnd: false
                }
            },
            async getNotificationList() {
                this.notificationParam.isRequesting = true
                axios({
                    url: ServerNoticeServer + '/message/notice/messages',
                    params: {
                        user_token: await getToken(),
                        page: this.notificationParam.page++
                    }
                }).then(res => {
                    let data = res.data;
                    this.notificationParam.isRequesting = false
                    if (data.code === 200) {
                        if (data.data.length === 0) {
                            this.notificationParam.isEnd = true
                        }
                        data.data.forEach(item => item.show = 1);
                        let formateData = this.fillData(data.data);
                        formateData.forEach(item => this.notificationMsg.push(item))
                        this.notificationListHeight = this.$refs['notification-list'].offsetHeight
                        if (!!this.notificationMsg.length && this.notificationMsg[0].status === 0) {
                            this.$store.dispatch('Notification/FETCH_UNREAD_COUNT', { isForce: true })
                        }
                    }
                })
            },
            async getNoticeList() {
                this.noticeParam.isRequesting = true
                axios({
                    url: ServerNoticeServer + '/message/announcement/messages',
                    params: {
                        page: this.noticeParam.page++,
                        user_token: await getToken()
                    }
                }).then(res => {
                    this.noticeParam.isRequesting = false
                    let data = res.data;
                    if (data.code === 200) {
                        if (data.data.length === 0) {
                            this.noticeParam.isEnd = true
                        }
                        data.data.forEach(item => {
                            this.noticeMsg.push(item)
                        })
                        this.$nextTick(() => {
                            this.secondaryListHeight = this.$refs['secondary-list'].offsetHeight
                        })

                        if (!!this.noticeMsg.length && this.noticeMsg[0].status === 0) {
                            this.$store.dispatch('Notification/FETCH_UNREAD_COUNT', { isForce: true })
                        }
                    }
                })
            },
            handleScrollNotification(vertical, horizontal, nativeEvent) {
                nativeEvent.stopPropagation()
                if (this.notificationListHeight - 392 - vertical.scrollTop <= 10 && !this.notificationParam.isRequesting && !this.notificationParam.isEnd) {
                    this.getNotificationList()
                }
            },
            handleLoadEnd(vertical) {
                if (this.secondaryListHeight - 392 - vertical.scrollTop <= 10 && !this.noticeParam.isRequesting && !this.noticeParam.isEnd) {
                    this.getNoticeList()
                }
            },
            viewBoard() {
                this.goToLink(`${ServerWww}/u/${this.userId}/xz`)
            },
            viewFans() {
                this.goToLink(`${ServerWww}/u/${this.userId}/followers`)
            },
            fillData(data) {
              const invitedPage = `${PageInvite}`
              const invitedOpenVipUrl = `${PageVersionDiff}?from_type=63&from_text=${EncryptionDecryptParam('老拉新-站内消息')}`
                data.forEach(item => {
                    try {
                        item.content = item.content.replace(/\${avatar}/, item.avatar).replace(/\${datetime_str}/, item.datetime_str).replace(/\${design_url}/g, this.designUrl).replace(/\${invited_page}/, invitedPage).replace(/\${invited_tovip}/, invitedOpenVipUrl)
                    } catch (e) {
                        console.log('消息处理-err', e);
                    }
                });
                return data
            },
            async delThisMsg(item, index) {
                item.show = false;
                axios({
                    url: ServerNoticeServer + '/message/notice/delete',
                    params: {
                        user_token: await getToken(),
                        id: item.id
                    }
                }).then(res => {
                    setTimeout(() => {
                        // this.notificationMsg.splice(index, 1)
                    }, 800)
                })
            },
            closeNotifi(){
                this.$emit('close')
            },
            closeThis(boxType) {
                this[boxType] = false
            },
            showNotificationBox(boxType) {
                switch (boxType) {
                    case 'coopreation':
                        break;
                    case 'follow':
                        break;
                    case 'notification':
                        break;
                    case 'notice':
                }

                this[boxType] = true;
            }
        },
        watch: {
            msgShow: function (newV) {
                if (!newV) {
                    this.coopreation = false;
                    this.follow = false;
                    this.notification = false;
                    this.notice = false;
                }
            },
             coopreation: async function (newV) {
                if (newV) {
                    axios({
                        url:ServerNoticeServer + '/message/cooperation/messages',
                        params: {
                            user_token: await getToken()
                        }
                    }).then(res => {
                        let data = res.data;
                        if (data.code === 200) {
                            this.coopreationMsg = this.fillData(data.data);
                            if (!!this.coopreationMsg.length && this.coopreationMsg[0].status === 0) {
                                this.$store.dispatch('Notification/FETCH_UNREAD_COUNT', { isForce: true })
                            }
                        }
                    })
                }
            },
            follow: async function (newV) {
                if (newV) {
                    axios({
                        url: ServerNoticeServer + '/message/follow/messages',
                        params: {
                            user_token: await getToken()
                        }
                    }).then(res => {
                        let data = res.data;
                        if (data.code === 200) {
                            this.followMsg = this.fillData(data.data);
                            if (!!this.followMsg.length && this.followMsg[0].status === 0) {
                                this.$store.dispatch('Notification/FETCH_UNREAD_COUNT', { isForce: true })
                            }
                        }
                    })
                }
            },
            //通知
            notification: function (newV) {
                if (newV) {
                    this.initNotification()
                    this.getNotificationList()
                } else {
                    setTimeout(() => {
                        this.notificationMsg = []
                    }, 300)
                }
            },
            //公告
            notice: function (newV) {
                if (newV) {
                    this.initNotice()
                    this.getNoticeList()
                }
            }
        },
        computed: {
            ...mapState({
                "userId": function (state: any) {
                    return state.UserInfo.id
                },
                "unReadCount": function (state: any) {
                    return (state.Notification && state.Notification.unReadCount) || {
                        cooperation: 0,
                        follow: 0,
                        notice: 0,
                        announcement: 0
                    }
                }
            })
        }
    }
</script>


