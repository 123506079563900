<template>
    <div :class="[short?'navBarR_short':'','navBarR', 'headerNavRightItems']" @click.stop>
<!--      <div v-if="$route.name === 'Home'" @click="toInvitePage" :class="['cHeaderLi', 'dropdownOuter', 'cHeaderLi_invite']">-->
<!--        <cHeaderNavItem-->
<!--            themeColor="navItem_invite"-->
<!--            type="xs"-->
<!--            class="onlyIconMax_1280"-->
<!--            icon="https://xcx.bigbigwork.com/p/search/header_user_invite_icon_hover_v3.svg"-->
<!--            hoverIcon="https://xcx.bigbigwork.com/p/search/header_user_invite_icon_v3.svg"-->
<!--        >赚现金-->
<!--        </cHeaderNavItem>-->
<!--      </div>-->
<!--        <div :class="['cHeaderLi', 'dropdownOuter',$style.fanli]"-->
<!--             @click.stop="goToFanli" v-if="!isFixed && ($route.name === 'Home')" @mouseenter="isShowFanliTip=true" @mouseleave="isShowFanliTip=false">-->
<!--            &lt;!&ndash;            :class="$route.name === 'Home'? 'newAwards':''"&ndash;&gt;-->
<!--            <cHeaderNavItem-->
<!--                themeColor="navItem_grey150"-->
<!--                type="xs"-->
<!--                class="onlyIconMax_1280"-->
<!--                icon-type="fanli"-->
<!--            >大作返利-->
<!--            </cHeaderNavItem>-->
<!--            <div v-show="isShowFanliTip" :class="$style.fanliTip">-->
<!--                设计师买东西， <br>-->
<!--                就用大作返利！           </div>-->
<!--            <img src="../../../assets/images/header_new.svg" alt="new" style="position: absolute;left: 18px;top: -6px;" :class="$style.new">-->
<!--        </div>-->
<!--        找设计入口-->
<!--        <div :class="['cHeaderLi','dropdownOuter',$style.findDesign]"-->
<!--             @click.stop="goTofindDesign" v-if="!isFixed && ($route.name === 'Home')">-->
<!--            <cHeaderNavItem-->
<!--                themeColor="navItem_grey150"-->
<!--                type="xs"-->
<!--                class="onlyIconMax_1280"-->
<!--                icon-type="findDesign"-->
<!--            >找设计-->
<!--            </cHeaderNavItem>-->
<!--        </div>-->
<!--        &lt;!&ndash;找项目入口 &ndash;&gt;-->
<!--        <div :class="['cHeaderLi','dropdownOuter',$style.findProject]"-->
<!--             @click.stop="goTofindProject" v-if="!isFixed && ($route.name === 'Home')">-->
<!--            <cHeaderNavItem-->
<!--                themeColor="navItem_grey150"-->
<!--                type="xs"-->
<!--                class="onlyIconMax_1280"-->
<!--                icon-type="findProject"-->
<!--            >找项目-->
<!--            </cHeaderNavItem>-->
<!--        </div>-->
        <!-- 企业团购入口  暂时不要-->
<!--        <div :class="['cHeaderLi','dropdownOuter']"-->
<!--             @click.stop="goToGroupbuy" v-if="!isFixed && ($route.name === 'Home' || $route.path === '/versiondifnew.htm')">-->
<!--          <cHeaderNavItem-->
<!--              themeColor="navItem_grey160"-->
<!--              type="xs"-->
<!--              class="onlyIconMax_1280"-->
<!--              icon-type="group"-->
<!--          >团购-->
<!--          </cHeaderNavItem>-->
<!--        </div>-->
        <div v-if="showList.includes('pinliteBackToSearch')" :class="$style.cHeaderLi">
            <cHeaderNavItem type="xs" :href="homeLink" name="bbwHome">返回大作首页</cHeaderNavItem>
        </div>
        <div v-if="showList.includes('pinliteBackToSearch')" :class="[$style.cHeaderLi, $style.ai_tool]">
          <cHeaderNavItem type="xs" :href="aiLink" name="bbwHome" @click="statsAi(`pinlite导航栏`)">AI绘画</cHeaderNavItem>
        </div>
        <div v-if="showList.includes('industrySearch')" :class="$style.cHeaderLi">
            <cHeaderNavItem
                    type="xs"
                    :href="industryLink"
                    @click="stats('大作','pl')"
            >
                分类搜索</cHeaderNavItem>
        </div>
        <div v-if="showList.includes('metaSearch')" :class="$style.cHeaderLi">
            <cHeaderNavItem
                    type="xs"
                    :href="metaLink"
                    @click="stats('聚合','pl')"
            >全球网站聚合</cHeaderNavItem>
        </div>
        <div v-if="showList.includes('backToSearch')" :class="$style.cHeaderLi">
            <cHeaderNavItem type="xs" :href="PageHome" name="bbwHome">返回大作</cHeaderNavItem>
        </div>
        <div v-if="showList.includes('search')" :class="'cHeaderLi'">
            <cHeaderNavItem
                    @click="stats('大作','dt')"
                    type="xs"
                    :href="PageSearchEngine"
            >搜灵感
            </cHeaderNavItem>
        </div>
        <div v-if="showList.includes('tuyi')" :class="'cHeaderLi'">
            <cHeaderNavItem
                @click="stats('图意','dt')"
                type="xs"
                :href="PageTuYiEngine"
            >图意搜索
            </cHeaderNavItem>
        </div>
<!--        <div v-if="showList.includes('pinlite')" :class="['cHeaderLi', 'pr-zero']">-->
        <div v-if="showList.includes('pinlite')" :class="['cHeaderLi']">
            <cHeaderNavItem
                    :capture="true"
                    type="xs"
                    :href="pinLiteHref"
                    @click="toPinlite">
                Pinlite极速版
            </cHeaderNavItem>
        </div>
<!--      <div v-if="showQiWeiDialog" :class="['cHeaderLi']">-->
<!--        <div id="qiWeiIcon" :class="$style.qiwei_icon" @click="openQiWeiDialog"></div>-->
<!--      </div>-->

        <div v-if="showList.includes('aiTool') && ( ($route.name === 'Home'||$route.name === 'TuYiSearchImgs') || showAiTool ) && aiConfig " v-show="!short" :class="['cHeaderLi', 'dropdownOuter',$style.fanli,$style.aiTool]">
            <!--            :class="$route.name === 'Home'? 'newAwards':''"-->
            <cHeaderNavItem
                themeColor="navItem_grey150"
                type="xs"
                class="onlyIconMax_1280"
                icon-type="aiTool"
                @click="handleAITool(aiConfig)"
            >{{aiConfig.show_name}}
            </cHeaderNavItem>
            <!--            <img src="../../../assets/images/header_new.svg" alt="new" style="position: absolute;left: 18px;top: -6px;" :class="$style.new">-->
        </div>

      <!--<div v-if="showList.includes('ext')" v-show="!short" :class="['cHeaderLi', 'dropdownOuter',$style.fanli]" @mouseenter="extEnter">-->
      <div v-if="false" v-show="!short" :class="['cHeaderLi', 'dropdownOuter',$style.fanli]" @mouseenter="extEnter">
            <!--            :class="$route.name === 'Home'? 'newAwards':''"-->
            <cHeaderNavItem
                themeColor="navItem_grey150"
                type="xs"
                class="onlyIconMax_1280"
                icon-type="caijiqi"
                @click="handleExtClick"
            >智能插件
            </cHeaderNavItem>
            <header-ext v-if="!$store.state.server"
                        class="dropdownWrapper"
            />
<!--            <img src="../../../assets/images/header_new.svg" alt="new" style="position: absolute;left: 18px;top: -6px;" :class="$style.new">-->
        </div>
<!--        <div v-show="!short" :class="['cHeaderLi', 'dropdownOuter']">-->
<!--            &lt;!&ndash;            :class="$route.name === 'Home'? 'newAwards':''"&ndash;&gt;-->
<!--            <cHeaderNavItem-->
<!--                    themeColor="navItem_grey150"-->
<!--                    type="xs"-->
<!--                    class="onlyIconMax_1280"-->
<!--                    icon-type="imageTools"-->
<!--            >图片处理-->
<!--            </cHeaderNavItem>-->
<!--            <img src="../../../assets/images/header_new.svg" class="newTips" alt="new" style="position: absolute;left: 80px;top: -5px;z-index:5" :class="$style.new">-->
<!--            <cHeaderDropdownTools v-if="!$store.state.server" :tool-list="imageToolList" class="dropdownWrapper"></cHeaderDropdownTools>-->
<!--        </div>-->
        <div v-if="showList.includes('tools')" v-show="!short" :class="['cHeaderLi', 'dropdownOuter']">
<!--            :class="$route.name === 'Home'? 'newAwards':''"-->
            <cHeaderNavItem
                    themeColor="navItem_grey150"
                    type="xs"
                    class="onlyIconMax_1280"
                    icon-type="tools"
            >更多功能
            </cHeaderNavItem>
            <cHeaderDropdownTools v-if="!$store.state.server" :tool-list="toolList" class="dropdownWrapper"></cHeaderDropdownTools>
        </div>
<!--        <div v-show="!short" :class="['cHeaderLi','dropdownOuter', 'msgLi']" @mouseenter="msgEnter"-->
<!--             @mouseleave="msgLeave">-->
<!--            <div :class="['msg-total-count', 'font-blod']" v-if="total"><span>{{total}}</span></div>-->
<!--            <cHeaderNavItem-->
<!--                    themeColor="navItem_grey150"-->
<!--                    type="xs"-->
<!--                    class="onlyIconMax_1280"-->
<!--                    icon-type="notice"-->
<!--            >消息-->
<!--            </cHeaderNavItem>-->
<!--            <cHeaderNotification-->
<!--                    v-if="!$store.state.server"-->
<!--                    class="dropdownWrapper"-->
<!--                    @updateUnread='updateUnread'-->
<!--                    :msgShow="msgShow"-->
<!--                    :designUrl="designUrl"></cHeaderNotification>-->
<!--        </div>-->
<!--        <div :class="['cHeaderLi', 'dropdownOuter']" style="letter-spacing: 1px" @click.stop="buyVip" @mouseenter="invitedLeave" @mouseleave="invitedEnter" @mousemove="invitedLeave">-->
<!--            <cHeaderNavItem-->
<!--                    themeColor="navItem_grey150"-->
<!--                    type="xs"-->
<!--                    class="vipNewIcon"-->
<!--                    :icon="vipIcon"-->
<!--                    :icon-width="28"-->
<!--                    :icon-height="28"-->
<!--            >-->
<!--              <template v-if="showVipTips" slot="tips"  >-->
<!--                <header-vip-tips/>-->
<!--              </template>-->
<!--              VIP-->
<!--            </cHeaderNavItem>-->
<!--            <HeaderDropdownVip class="dropdownWrapper" v-if="showUser && showDropdownVip && !maxTeamInfo.vip_day"></HeaderDropdownVip>-->
<!--            <HeaderDropdownVipTeam class="dropdownWrapper" v-if="showUser && showDropdownVip && maxTeamInfo.vip_day" :maxTeamInfo="maxTeamInfo"></HeaderDropdownVipTeam>-->
<!--          &lt;!&ndash;受邀用户提示&ndash;&gt;-->
<!--          <cHeaderInvitedTips :isFixed="isFixed" v-if="$route.name === 'Home' && showInvitedTips" @mouseenter.native="invitedEnter" @mouseleave.native="invitedLeave" @click.native="toPayPage"></cHeaderInvitedTips>-->
<!--        </div>-->
        <div v-if="showList.includes('vip')"
             :class="['cHeaderLi', 'dropdownOuter']"
             style="letter-spacing: 1px"
             @click.stop="buyVip" @mouseenter="invitedLeave" @mouseleave="invitedEnter" @mousemove="invitedLeave">
            <VipBlock :is-vip="$store.getters['UserInfo/isVip']">
                <header-vip-tips v-if="showVipTips"/>
            </VipBlock>
            <HeaderDropdownVip v-if="showUser && showDropdownVip && !maxTeamInfo.vip_day"
                               class="dropdownWrapper"
                               :isBBW="isBBW"
                               :from-text="statsVip.fromText" :from-type="statsVip.fromType"
                               :from-text-privilege="statsVip.fromTextPrivilege" :from-type-privilege="statsVip.fromTypePrivilege"
            ></HeaderDropdownVip>
            <HeaderDropdownVipTeam v-if="showUser && showDropdownVip && maxTeamInfo.vip_day"
                                   class="dropdownWrapper"
                                   :isBBW="isBBW"
                                   :from-text="statsVip.fromText" :from-type="statsVip.fromType"
                                   :from-text-privilege="statsVip.fromTextPrivilege" :from-type-privilege="statsVip.fromTypePrivilege"
                                   :maxTeamInfo="maxTeamInfo"
            ></HeaderDropdownVipTeam>
            <cHeaderInvitedTips :isFixed="isFixed" v-if="$route.name === 'Home' && showInvitedTips" @mouseenter.native="invitedEnter" @mouseleave.native="invitedLeave" @click.native="toPayPage"></cHeaderInvitedTips>
        </div>
        <div v-if="showList.includes('myCloud')"
             :class="['cHeaderLi', 'dropdownOuter', personStatus === 1 ? 'notifiHeaderLi':'']" >
            <div :class="['msg-total-count', 'font-blod']" v-if="total"><span>{{total}}</span></div>
            <cHeaderUserArea
                    type="xs"
                    :isShowUserGui="isShowUserGui"
                    @userClick="userClick"></cHeaderUserArea>
            <cHeaderDropdownUser
                    :auth="!isBBW"
                    v-if="showUser && personStatus===0"
                    class="dropdownWrapper notifiDropdown"
                    :notifi-total="total"
                    :redirect="PageSearchUnlogin"
                    @userClick="userClick"
                    @notification="changePersonMode(1)"
            ></cHeaderDropdownUser>
            <!--消息-->
            <cHeaderNotification
                    :auth="!isBBW"
                    v-if="showUser && personStatus===1"
                    class="dropdownWrapper notifiDropdown"
                    @updateUnread='updateUnread'
                    @close="changePersonMode(0)"
                    :msgShow="msgShow"
                    :designUrl="designUrl">
            </cHeaderNotification>
        </div>
    </div>
</template>

<script lang="ts">
    import cHeaderNavItem from '../HeaderNavItem'
    import cHeaderUserArea from '../HeaderUserArea'
    import cHeaderDropdownUser from '../HeaderDropdownUser'
    import cHeaderDropdownTools from '../HeaderDropdownTools'
    import HeaderDropdownVip from '../HeaderDropdownVip'
    import HeaderDropdownVipTeam from '../HeaderDropdownVipTeam'
    import cHeaderNotification from '../HeaderNotification'
    import HeaderVipTips from "../HeaderVipTips/HeaderVipTips.vue";
    import VipBlock from "../VipBlock/VipBlock.vue";
    import HeaderExt from "../HeaderExt";
    import {
      ServerNoticeServer,
      StoreNotification,
      PageSearchUnlogin,
      BigBigWork,
      PageGroupbuy,
      findProjectUrl,
      findDesignUrl,
      PageFanliHome,
      getNextDate,
      PageInvite,
      buyVip,
      PageCollectionTool,
      PageNetworkSearch,
      PageExpandImage,
      PageExplore,
      PageGoDesign,
      ServerPrizes,
      PageYasuotu,
      PageKuotu,
      findParentNodeBySelector,
      PagePinliteAuth,
      PageSearchEngine,
      PageTuYiEngine,
      openPinlite,
      PageHome,
      statSearchRedirect,
      PageSearchAuth,
      ServerMetaSearch,
      PageSearch,
      getAuthUrl,
      PageSearchHome,
      statsDzToAi,
      goToAI, PageAiGallery, PageAiTool, PageAiSearch,getAIAuthUrl
    } from 'bbw-common'
    import Cookie from 'js-cookie'
    import axios from 'axios'
    import { mapState } from 'vuex'
    import cHeaderInvitedTips from '../HeaderInvitedTips'
    import Vue from "vue";
    import * as process from "process";
    export default {
      name: "HeaderNavigationRightItems",
      components: {
          cHeaderUserArea,
          cHeaderDropdownUser,
          cHeaderNavItem,
          cHeaderDropdownTools,
          HeaderDropdownVip,
          HeaderDropdownVipTeam,
          cHeaderNotification,
          cHeaderInvitedTips,
          HeaderVipTips,
          VipBlock,
          HeaderExt
      },
      props:{
          // 是否在.bigbigwork域名下
          isBBW: {
              type: Boolean,
              required: false,
              default: true
          },
        // 要显示的菜单项
        showList:{
            type: Array,
            // 可能的值：
            // pinliteBackToSearch:pinlite返回大作；
            // 返回大作：backToSearch;
            // search:找灵感;
            // pinlite:pinlite；
            // ext:智能插件；
            // tools: 智能工具;
            // vip:vip;
            // myCloud:我的设计云
            default: ()=>{
                return ['qiwei','ext','tools', 'vip', 'myCloud', 'aiTool']
            }
        },
        isFixed: {
          type: Boolean,
          default: false,
          required: false
        },
        showVipTips: {
          type: Boolean,
          required:false,
          default:false
        },
          /** 简短显示 */
          short: {
              type: Boolean,
              required:false,
              default:false
          },
          // vip下拉统计
          statsVip: {
              type: Object,
              required: false,
              default: ()=>{
                  return {
                      fromText: "vip下拉",
                      fromType: 6,
                      fromTextPrivilege: "特权对比页按钮",
                      fromTypePrivilege: 7
                  }
              }
            // default: function _default () {
            //   return {
            //     fromText: "vip下拉",
            //     fromType: 6,
            //     fromTextPrivilege: "特权对比页按钮",
            //     fromTypePrivilege: 7
            //   }
            // }
          },
          aiConfig:{
              type: Object,
              default: () => null
          },
          // 是否强制显示AiTool
          showAiTool:{
              type: Boolean,
              default: false
          },
          // ai工具来源统计
          aiFromText:{
              type: String,
              default: "dz首页"
          },
      },
        data: function () {
            return {
                msgShow: false,
                designUrl: '',
                PageSearchUnlogin,
                PageHome,
                changeNum:1,
                showDropdownVip: true,
                /** 显示受邀用户提示 */
                showInvitedTips: false,
                /** VIP下拉付费来源文字 */
                fromText:"vip",
                /** VIP下拉付费来源数字 */
                fromType:6,
                isShowFanliTip:false,
                /** 个人下拉状态(0:我的设计云；1:消息) */
                personStatus: 0,
                PageSearchEngine,
                PageTuYiEngine,
                toolList:[
                    // {
                    //     src: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_new_0.svg?2`,
                    //     hoversrc: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_new_hover_0.svg?2`,
                    //     iconType: 'cjq',
                    //     name: '大作采集器',
                    //     width: 14,
                    //     borderColor:'#303030',
                    //     link: PageCollectionTool
                    // },
                    // {
                    //     name: '协作图片集',
                    //     width: 18,
                    //     borderColor:'#303030',
                    //     link: PageWhatCollaborateboard
                    // },
                    {
                      iconType: 'caijiqi',
                      name: '智能采集插件',
                      borderColor: '#303030',
                      link: PageCollectionTool,
                      linkClass: `dropdownOuter`,
                      dropdown: true
                    },
                    {
                        iconType: 'yasuotu',
                        name: '专业压缩图片',
                        borderColor: '#303030',
                        link: PageYasuotu,
                        linkClass: `new`
                    },
                    /*{
                        iconType: 'khd',
                        name: '大作客户端',
                        borderColor: "#303030",
                        link: this.clientLink
                    },*/
                    {
                        iconType: 'ai',
                        name: 'AI算法扩大图',
                        borderColor: '#303030',
                        link: PageKuotu
                    },
                    {
                        iconType: 'zdt',
                        name: '全球找大图',
                        borderColor: '#303030',
                        link: PageNetworkSearch
                    },
                    // {
                    //     iconType: 'fanli',
                    //     name: '大作返利',
                    //     borderColor: "#303030",
                    //     link: PageFanliHome
                    // },

                    // {
                    //     src: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_new_5.svg?2`,
                    //     hoversrc: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_new_hover_5.svg?2`,
                    //     iconType: 'qqtj',
                    //     name: '今日全球推荐',
                    //     width: 25,
                    //     borderColor: '#303030',
                    //     link: PageSearchHome
                    // },
                    {
                        iconType: 'fx',
                        name: '发现设计主题',
                        borderColor: '#303030',
                        link: PageExplore
                    },
                    // {
                    //     iconType: 'goDesign',
                    //     name: 'Go.Design',
                    //     borderColor: '#303030',
                    //     link: PageGoDesign
                    // },
                    {
                        iconType: 'bj',
                        name: '大设全球报奖',
                        borderColor: '#303030',
                        link: ServerPrizes+'/?source=dashenew',
                        linkClass: `newAwardsIcon`
                    }
                ],
                imageToolList: [
                    {
                        src: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_yasuotu.svg?2`,
                        hoversrc: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_yasuotu_hover.svg?2`,
                        iconType: 'yasuotu',
                        name: '图片压缩',
                        width: 14,
                        borderColor: '#303030',
                        link: PageYasuotu
                    },
                    {
                        src: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_yasuotu.svg?2`,
                        hoversrc: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_yasuotu_hover.svg?2`,
                        iconType: 'png-yasuotu',
                        name: 'PNG压缩',
                        width: 14,
                        borderColor: '#303030',
                        link: `${PageYasuotu}/png`
                    },
                    {
                        src: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_yasuotu.svg?2`,
                        hoversrc: `${process.env.CDN_XCX_PATH}/p/search/header_tools_icon_yasuotu_hover.svg?2`,
                        iconType: 'jpg-yasuotu',
                        name: 'JPG压缩',
                        width: 14,
                        borderColor: '#303030',
                        link: `${PageYasuotu}/jpg`
                    }
                ]
            }
        },
        beforeMount(){
            BigBigWork.on('showGuideUser',()=>{
                this.changeNum++
            })
        },
        activated() {
            document.body.addEventListener('click', this.handleOtherClick);
        },
        deactivated() {
            document.body.removeEventListener('click', this.handleOtherClick);
        },
        async mounted() {
          const _self = this;
          window.addEventListener('scroll',()=>{
            this.showDropdownVip = false
          })
            StoreNotification.CreatedNotification(this.$store)
            this.$store.dispatch('Notification/RUN_FETCH_UNREAD')
          //    导航中是否显示受邀提示
          // 条件 受邀用户&&今天未点击过
          const hasClickStorage = Cookie.get('_dz_showedInvitedTips')
          await this.$store.dispatch('UserInfo/FETCH_USER_INFO')
          if (this.$store.state.token) {
            await  this.$store.dispatch('UserInfo/FETCH_USER_TEAM')
          }
          console.log(this.$store.state.UserInfo)
          const isInviteExpiry = !!(this.$store.state.UserInfo?.isInviteExpiry || 0)
          console.log('activated-items', hasClickStorage, isInviteExpiry)
          if (!hasClickStorage && isInviteExpiry && !this.$store.state.UserInfo.isUserPay) {
            this.showInvitedTips = true
          }
        },
        methods: {
            async toPinlite(){
                this.stats('pl')
                openPinlite(this.$store)
            },
            handleAITool ( aiConfig ) {
                statsDzToAi(this.$store,`${this.aiFromText}导航AI工具`,'');
                const tool = aiConfig.domain ? `${aiConfig.domain}/workflow-tools` : PageAiTool ;
                goToAI('', tool )
            },
            handleExtClick(){
              window.open(PageCollectionTool);
            },
            /** 鼠标移入智能插件 */
            async extEnter(){
                await this.$store.dispatch("System/FETCH_EXT_VER");
            },
            handleOtherClick (e){
                if ( this.personStatus === 0 ) return;
                const isDropdown = findParentNodeBySelector( e.target as HTMLElement, '.notifiDropdown');
                if(!isDropdown){
                    this.personStatus = 0;
                }
            },
            /** 切换我的设计云状态 */
            changePersonMode( mode ){
                this.$nextTick(()=>{
                    this.personStatus = mode;
                })
            },
          // 打开企业微信弹框
          async openQiWeiDialog() {
            const DialogQiweiVue = await import ( `../../components/DialogQiWei` );
            const DialogQiwei = new Vue({...DialogQiweiVue.default});
            DialogQiwei.$store = this.$store;
            const el = document.createElement(`div`);
            document.body.appendChild(el);
            DialogQiwei.$mount(el);
          },
          goToGroupbuy(){
            window.open ( PageGroupbuy ) ;
          },
            goTofindDesign(){
              window.open( findDesignUrl )
            },
            goTofindProject(){
                window.open( findProjectUrl )
            },
            goToFanli(){
                _hmt.push ( [`_trackEvent`, `导航栏`, `点击`, `大作返利`] ) ;
                window.open( PageFanliHome )
            },
            goToCaijiqi(){
                window.open( PageCollectionTool )
            },
          buyVip: function () {
            if (this.maxTeamInfo.vip_day){
              buyVip("bbwTeamRenew", this.$store, this.fromText, this.fromType, true, this.maxTeamInfo.uuid)
            }else{
              buyVip("bbw", this.$store, this.fromText, this.fromType, !this.isBBW)
            }
          },
          toPayPage() {
            buyVip('bbw', this.$store, '老拉新-导航', 61, !this.isBBW )
          },
          toInvitePage() {
            // Cookie.set('_dz_showedInvitedTips', '1', {
            //   expires: getNextDate(),
            //   domain: '.bigbigwork.com'
            // })
            // this.showInvitedTips = false
            window.open(PageInvite, '_blank')
          },
          invitedLeave() {
            this.showDropdownVip = true
          },
          invitedEnter() {
            this.showDropdownVip = false
          },
            //点击我的设计云触发下一个新手引导
            userClick(){
                if(this.isShowUserGui){
                    let emitName = ''
                    switch (this.$route.name) {
                        case 'Img':
                            emitName = 'showGuideBigImage';
                            break
                        case 'SearchImgs':
                            emitName = 'showGuideSerachImage';
                            break
                        case 'SemblancePic':
                            emitName = 'showGuideSemblanceImage';
                            break
                        default:
                    }
                    if(emitName){
                        this.$store.dispatch ( `UserGuide/FINISH_GUID`, { guideName: `success_favourite_img` } ) ;
                        BigBigWork.emit(`${emitName}`)
                    }
                }
            },
            msgEnter() {
                this.msgShow = true
            },
            msgLeave() {
                this.msgShow = false
            },
            /**
             * @description 手动获取消息未读数(强制更新)
             */
            updateUnread() {
                this.getUnreadCount(true)
            },
            /**
             * @description 获取消息未读数
             * @param isForce
             */
            getUnreadCount: function (isForce = false) {
                let updateUnreadTime = Cookie.get(this.env + 'updateUnreadTime')
                // {
                //     domain: ".bigbigwork.com"
                // }
                let cookieMsgUnReadCount = Cookie.get(this.env + 'msgUnReadCount')
                cookieMsgUnReadCount = cookieMsgUnReadCount && JSON.parse(cookieMsgUnReadCount)
                if (!isForce && updateUnreadTime && cookieMsgUnReadCount) {
                    this.unReadCount = cookieMsgUnReadCount
                }

                if (!isForce && !!updateUnreadTime && cookieMsgUnReadCount) {
                    //cookie 有效期0.5小时
                    return
                }
                axios({
                    url: ServerNoticeServer + '/message/unReadCount',
                    params: {
                        user_token: this.$store.state.token
                    }
                }).then(res => {
                    let data = res.data;
                    if (data.code === 200) {
                        this.unReadCount = data.data
                        //cookie 有效期0.5小时
                        if (!isForce) {
                            Cookie.set(this.env + 'updateUnreadTime', (new Date().getTime()).toString(), {
                                expires: new Date(new Date().getTime() + 30 * 60 * 1000),
                                domain: '.bigbigwork.com'
                            })
                        }
                        Cookie.set(this.env + 'msgUnReadCount', JSON.stringify(this.unReadCount), {
                            expires: 1,
                            domain: '.bigbigwork.com'
                        })
                    }
                })
            },
            stats(data, site){
                // 跳转统计
                statSearchRedirect({redirect_url:data,action_place:`导航-${site}`,action_source:'pc'},this.$store)
            },
            statsAi( from ) {
              statsDzToAi(this.$store, from, '');
            },
            //添加一个事件，当显示我的设计云新手引导时其他不显示
            handelGuide(){
                let emitName = ''
                switch (this.$route.name) {
                    case 'Img':
                        emitName = 'showGuideBigImage';
                        break
                    case 'SearchImgs':
                        emitName = 'showGuideSerachImage';
                        break
                    case 'SemblancePic':
                        emitName = 'showGuideSemblanceImage';
                        break
                    default:
                }
                if(emitName){
                    BigBigWork.emit(`${emitName}`,true)
                }
            }
        },
        computed: {
          process() {
            return process
          },
            metaLink: function(){
                if ( process.env.VUE_ENV === 'client'){
                    return getAuthUrl( this.$store, PageSearchHome )
                }else{
                    return ServerMetaSearch;
                }
            },
            showQiWeiDialog(){
              return !this.$store.state.UserInfo.wx.exclusive_service_id && this.$route.name === 'Home' && this.showList.includes('qiwei') && !this.isFixed
            },
            industryLink:function(){
                if ( process.env.VUE_ENV === 'client'){
                    return getAuthUrl( this.$store,"/tupian")
                }else{
                    return PageSearch
                }
            },
            aiLink: function(){
              const search = PageAiSearch;
              const gallery = PageAiGallery;
              const url = this.$route.query.kw ? search + `?w=${this.$route.query.kw}` : gallery;
              return getAIAuthUrl( this.$store,url)
            },
            homeLink: function(){
              return getAuthUrl( this.$store,"/home")
                // if ( process.env.VUE_ENV === 'client'){
                //     return getAuthUrl( this.$store,"/home")
                // }else{
                //     return PageHome;
                // }
            },
            pinLiteHref: function () {
                return `${PagePinliteAuth}?token=${this.$store.state.token}&redirect=%2Ftupian`
            },
            clientLink(){
                return 'https://www.ai-image.net/client.htm?token='+ this.$store.state.token
            },
            vipIcon: function () {
                /*return this.$store.getters[`UserInfo/isVip`]
                    ? `https://xcx.bigbigwork.com/p/search/header_icon_vip_v5.svg?1`
                    : `https://xcx.bigbigwork.com/p/search/header_novip_1x_v4.gif`*/
                return this.$store.getters[`UserInfo/isVip`]
                    ? `${process.env.CDN_XCX_PATH}/p/search/header_icon_vip_v6.svg?1`
                    : `${process.env.CDN_XCX_PATH}/p/search/header_novip_1x_v7.gif`
            },
            vipIconHover: function () {
                /*return this.$store.getters[`UserInfo/isVip`]
                    ? `https://xcx.bigbigwork.com/p/search/header_icon_vip_hover_v5.svg?1`
                    : `https://xcx.bigbigwork.com/p/search/header_novip_1x_v4.svg`*/
                return this.$store.getters[`UserInfo/isVip`]
                    ? `${process.env.CDN_XCX_PATH}/p/search/header_icon_vip_hover_v6.svg?1`
                    : `${process.env.CDN_XCX_PATH}/p/search/header_novip_1x_v5.svg`
            },
            showUser: function () {
                return !this.$store.state.server && this.$store.getters["UserInfo/userReady"]
            },
            ...mapState({
                'total': (state: any) => (state.Notification && state.Notification.unReadCount.total)
            }),
            isShowUserGui(){
                let showList = ['Img','SearchImgs','SemblancePic','SearchBoards']
                let isShow = false && showList.includes(this.$route.name) && this.$store.getters[`UserInfo/isShowGuide`] && this.changeNum && this.$store.state.UserGuide?.isInit && this.$store.state.UserGuide?.firsttime_favourite_img && !this.$store.state.UserGuide?.success_favourite_img
                if(isShow){
                    this.handelGuide()
                }
                return isShow
            },
            maxTeamInfo(){
              const maxTeamInfo = this.$store.state.UserInfo.maxTeamInfo;
              return {...maxTeamInfo}
            }
        }
    }
</script>

<style lang="scss" module>
.findDesign{
&::after{
    content: '/';
    position: absolute;
    right: 0;
    top: 1px;
}
    &>div>span{
        padding-right: 7px !important;
    }
}
.findProject{
    &>div>span{
        padding-left: 2px !important;
    }
}
.fanli{
    .fanliTip{
        //display: none;
        position: absolute;
        left:50%;
        margin-left: -70px;
        top: 27px;
        z-index: 999;
        background-image: url("../../../assets/images/fanliTip.svg");
        background-repeat: no-repeat;
        width: 139px;
        height: 52px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN, "Microsoft Yahei", 微软雅黑, sans-serif;
        color: #ffffff;
        font-size: 14px;
        padding:10px 15px 10px 25px;
        line-height: 18px;
    }
    //&:hover{
    //    .fanliTip{
    //        display: block;
    //    }
    //}
    }
.aiTool{
    :global(.navItemBk:hover .navItem_des){

        color: #6BCC38;
    }

}
.qiwei_icon{
  width: 124px;
  height: 24px;
  background-image: url("#{$cdn_dz_path}/3w/qw_1.svg");
  margin-right: 30px;
  cursor: pointer;
  position: relative;


}
.ai_tool {
  line-height: 24px;
  .ai{
    position: relative;
    display: flex;
    align-items: center;
    color: #767676;
    cursor: pointer;
  }
  .text {
    font-size: 16px;
    line-height: 16px;
    position: relative;
  }

  .text-hover {
    display: none;
  }

  &:hover {
    .text {
      display: none;
    }

    .text-hover {
      display: block;
    }
  }
}
@media (max-width: 1439px){
  .qiwei_icon{
    width: 32px;
    height: 24px;
    margin-right: 16px;
    background-image: url("#{$cdn_dz_path}/3w/qw_2.svg");
  }
}
</style>
