<template>
    <el-dialog :visible="cpuIsShow"
               @close="handleClose"
               @open="onOpen"
               :append-to-body="true"
               :custom-class="$style.dialogBehance"
               width="520px"
               top="0">
        <div :class="$style.ToBehance">
            <div v-if="hasBindPhone">
                <div :class="$style['icon-box']">
                    <img :class="$style['pinterest-logo']"
                         src="../../../assets/images/tobehance.png" alt="">
                    <img :class="$style['pinterest-word']"
                         src="../../../assets/images/behance-logo.png" alt="">
                </div>
                <span :class="$style.Versionfont">请登录大作客户端后，点击下方按钮</span>

                <div :class="$style.Versionbuttonbox" style="padding-top:29px;">
                    <a @click="gotoPage" target="_blank" :class="[$style.Versionbutton, $style.IsOk]"
                       style="display: inline-block;width: 200px;height: 50px;line-height: 50px">打开Behance</a>
                    <div :class="$style.gb"
                         style="font-size:12px;color:#AAAAAA;line-height:12px;margin-top:12px;text-align:center;">
                        关闭或退出大作将无法访问Behance
                    </div>
                    <p style="color:#7f8c8d;font-size:12px;line-height:30px">Behance使用特权（剩余<span :class="$style.pinusedays"
                                                                                                 style="color:#fe4365;">{{pinterestDays}}</span><span>天</span>）
                    </p>
                </div>
                <div :class="$style['loading_button']"
                     style="width:97px;height:30px;background:rgba(240,52,91,1);margin:29px auto 0;border-radius:5px;display:none;">
                    <img style="width:18px;height:18px;" :class="$style.loading_run"
                         :src="`${process.env.CDN_WIMG_PATH}/p/images/pinterest_loading.png`" alt="loading">
                    <span style="color:#fff;line-height:30px;">正在开启...</span>
                </div>

                <div :class="$style['down-word']">
                    <a class="fl" :href="`${process.env.CDN_WIMG_PATH}/client/DZClientSetup-x64.zip`">下载 Windows 客户端</a>
                    <a class="fr" :href="`${process.env.CDN_WIMG_PATH}/client/DZClientSetup-mac.zip`">下载 Mac 客户端</a>
                </div>
            </div>
            <div v-if="!hasBindPhone">
                <div :class="$style['icon-box']">
                    <img :class="$style['pinterest-logo']"
                         src="../../../assets/images/tobehance.png" alt="">
                    <img :class="$style['pinterest-word']"
                         src="../../../assets/images/behance-logo.png" alt="">
                </div>
                <p :class="$style.hoverbd" style="text-align:center;padding-top: 9px;margin-top:46px;font-size: 18px;">
                    绑定手机号才能打开Behance哦~
                    <a :href="PageCenter" style="text-decoration:underline;">现在去绑定</a></p>
                <div :class="$style.Versionbuttonbox" style="padding-top:29px;">
                    <a :class="[$style.Versionbuttons, $style.radius]" disabled>打开Behance</a>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script lang=ts>
    import { PageCenter, PageToBehance } from 'bbw-common'
    const Dialog = require("element-ui/lib/dialog").default;
    import Vue from 'vue'
    Vue.use(Dialog);

    Vue.use(Dialog)
    export default {
        name: "cDialogBehance",
        props: {
            'isShow': {
                type: Boolean,
                default: false,
                required: false,
            }
        },
        data: function () {
            return {
                PageCenter,
                instanceIsShow: true,
                appointUrl: ''
            }
        },
        methods: {
            onOpen() {
                this.instanceShow = true
            },
            handleClose: function () {
                this.instanceIsShow = false
                this.$emit('onClose')
            },
            gotoPage() {
                if (this.appointUrl) {
                    window.open(this.appointUrl, '_blank')
                }else {
                    window.open(PageToBehance)
                }
            }
        },
        computed: {
            pinterestDays() {
                return this.$store.state.UserInfo.pinDays;
            },
            hasBindPhone() {
                return !!this.$store.state.UserInfo.phone;
            },
            cpuIsShow() {
                return this.isShow && this.instanceIsShow
            }
        }

    }
</script>

<style module lang="scss" src="./DialogBehance.scss">

</style>
