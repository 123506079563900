var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.options.showGuide || false
    ? _c(
        "div",
        {
          staticClass: "font-medium",
          class: _vm.$style["circle-guide-box-V1"],
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  (_vm.options.showGuideWord && _vm.options.tipWord) || false,
                expression:
                  "(options.showGuideWord && options.tipWord) || false",
              },
            ],
            class: _vm.$style["guide-content-word-v1"],
            style: {
              bottom: _vm.options.bottom ? _vm.options.bottom + "px" : "-57px",
            },
            domProps: { innerHTML: _vm._s(_vm.options.tipWord || "") },
          }),
          _vm._v(" "),
          !_vm.$store.state.server
            ? _c("div", {
                directives: [
                  {
                    name: "circleposition",
                    rawName: "v-circleposition",
                    value: _vm.options.idName || "",
                    expression: "options.idName || ''",
                  },
                ],
                class: [
                  _vm.$style["circle-guide-v1"],
                  _vm.$style["SingleSiteSearch-circle-guide"],
                ],
                style: {
                  width: _vm.options.width ? _vm.options.width + "px" : "56px",
                  height: _vm.options.height
                    ? _vm.options.height + "px"
                    : "56px",
                },
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }