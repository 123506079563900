<template>
    <div :class="styleName" @click="handleClick" class="navItemBk">
       <span :class="['navItem_text', 'font-medium', themeColor, customClass]" v-if="!link && !href">
                <span v-if="iconType || icon" class="navItem_iconBox">
                  <img v-if="!iconType" class="navItem_icon" :src="icon" :style="{width:`${gifimgW}px`}" />
                  <img v-if="!iconType" :class="['navItem_icon', 'navItem_hoverIcon']" :src="hoverIcon || icon "
                         :style="{width:`${iconWidth}px`}"/>
                  <div v-if="iconType" :class="['navItem_icon', iconType ]" :style="iconStyle" ></div>
                </span>
                <span class="navItem_des">
                    <slot></slot>
                </span>
                <slot name="tips"></slot>
        </span>
        <a :href="(capture && ready) ? 'javascript:void(0);' : href"
           :class="['navItem_link', 'font-medium', $route.name===name?'router-link-active':'', themeColor, customClass]"
           v-if="!link && href" :rel="isNoFollow?'nofollow':''" :title="aTitle"
           :target="target">
            <span v-if="iconType" class="navItem_iconBox">
              <div :style="iconStyle" :class="['navItem_icon', iconType ]"></div>
            </span>
            <span class="navItem_des">
                <slot></slot>
            </span>
            <slot name="tips"></slot>
        </a>

        <router-link :to="link" :class="['navItem_link', 'font-medium',themeColor, customClass ]" v-if="link">
            <span v-if="iconType" class="navItem_iconBox">
              <div :style="iconStyle" :class="['navItem_icon', iconType ]"></div>
            </span>
            <span class="navItem_des">
                <slot></slot>
            </span>
            <slot name="tips"></slot>
        </router-link>
    </div>
</template>
<script lang=ts>

import {getToken, PagePinliteAuth, PageSearchIndex, ServerPService} from "bbw-common";
import {checkLoginFailed} from "../../scripts/utils";
import Bowser from "bowser"

export default {
        name: "cHeaderNavItem",
        props: {
            /** icon地址 */
            icon: {
              type: String,
              required: false
            },
            /** icon地址 */
            hoverIcon: {
              type: String,
              required: false
            },
            /** icon类型 */
            iconType:{
              type: String,
              required: false
            },
            /**
             * @description 主题色 一种主题色需要配置相应hover效果
             * grey normal>515151  hover > 333 (废弃)
             * grey100 normal > 333 hover > ff4d4d (适用于navItem中只有 文字)
             * grey150 normal > 333 hover > 333 (适用于navItem中有 文字+icon)
             * */
            themeColor: {
                type: String,
                required: false,
                default: 'navItem_grey100'
            },
            /** 跳转地址 */
            href: {
                type: String,
                required: false,
                default: ""
            },
            /** 是否在新标签打开 */
            newTab: {
                type: Boolean,
                required: false,
                default: true
            },
            /** 路由跳转(会忽略href和newTab) */
            link: {
                type: String,
                required: false,
                default: ""
            },
            /** 样式类型(small | normal) */
            type: {
                type: String,
                required: false,
                default: "normal"
            },
            /** icon图标宽度 */
            iconWidth: {
                type: Number,
                required: false,
                default: 14
            },
            /** icon图标高度 */
            iconHeight:{
              type: Number,
              required: false,
              default: 14
            },
            /** 自定义样式 */
            customClass: {
                type: String,
                required: false,
                default: ''
            },
            /**
             * @default 页面路由名称
             */
            name: {
                type: String,
                required: false,
                default: ''
            },
            /**
             * 检查是否登录
             */
            checkLogin:{
              type: Boolean,
              required: false,
              default: false
            },
            /** 是否捕获事件(只会触发click事件) */
            capture:{
              type: Boolean,
              required: false,
              default: false
            },
          /** a标签是否添加nofollow **/
          isNoFollow:{
            type: Boolean,
            required: false,
            default: false
          },
          /** a标签的title **/
          aTitle:{
            type: String,
            required: false,
            default: ''
          }
        },
        data(){
          return {
            ready: false
          }
        },
        mounted() {
          this.ready = true;
        },
        methods: {
            handleClick( e : Event ){
                // if( this.checkLogin && !this.capture ) {
                //   const browser = Bowser.getParser(window.navigator.userAgent);
                //   const browserName = browser.getBrowserName(true);
                //   const notCheck = browserName === 'firefox' || browserName === 'safari' || browserName === 'internet explorer';
                //   if( !notCheck ) {
                //     e.preventDefault();
                //     this.$store.dispatch("UserInfo/FETCH_CHECK_BROWSER_UNIQUE",{ source : `pc_new` }).then( ()=>{
                //       if( this.$store.getters["UserInfo/userReady"]){
                //         const link = document.createElement('a');
                //         link.href = this.href || this.link ;
                //         link.target = '_blank';
                //         link.click();
                //       }else{
                //         checkLoginFailed()
                //       }
                //     });
                //   }
                // }
                this.$emit('click', e)
            },
            loadImage(img:HTMLImageElement){
                console.log(img.src)
                if(img.src.includes('gif') && img.src.includes('novip')){
                    console.log('11111111')
                    setTimeout(()=>{
                        this.isStaticImg = true;
                    },8000)
                }
            }
        },
        computed: {
            /** 根据props.type决定样式名 */
            styleName: function () {
                let custom = "";
                switch (this.type) {
                    case 'xs':
                        custom = 'navItemXS';
                        break;
                    case 'small' :
                        custom = 'navItemS';
                        break;
                    case 'normal' :
                        custom = 'navItemM';
                        break;
                    default:
                        custom = 'navItemM';
                }
                return ['navItem', custom];
            },
            target: function () {
                const target = this.newTab ? `_blank` : `_self`;
                if(this.capture){
                  return this.ready ?  '_self' : target
                }
                return target
            },
            gifimgW: function (){
                let width = '';
                if(this.icon?.includes('gif')){
                    width = '34';
                }else{
                    width = this.iconWidth
                }
                return width;
            },
            iconStyle: function (){
              return {
                width: this.iconWidth + 'px',
                height: this.iconHeight + 'px'
              }
            },
            aherf:function (){
              return ( this.capture && this.ready) ?'javascript:void(0)': this.href
            }
        }
    };
</script>
<!-- style样式已提取到 styles/components/headerNavItem.scss-->
