var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isShow,
        "custom-class": "cDialogPaySuccess",
        "append-to-body": true,
        "close-on-click-modal": false,
        "show-close": false,
        width: "400px",
        top: "0px",
      },
      on: { close: _vm.onClose },
    },
    [
      _c("div", { class: [_vm.$style.weixinprepaycontent, "font-medium"] }, [
        _c("img", {
          class: _vm.$style.img_success,
          attrs: {
            src: _vm.process.env.CDN_WIMG_PATH + "/p/images/payment_sucess.png",
          },
        }),
        _vm._v(" "),
        _c("div", { class: _vm.$style.success_text }, [_vm._v("支付成功")]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.vip_text1 }, [
          _vm._v("扫码添加VIP专属客服,"),
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.vip_text2 }, [
          _vm._v("额外"),
          _c("span", { staticClass: "font-bold" }, [_vm._v("免费")]),
          _vm._v("多得"),
          _c("span", { staticClass: "font-bold" }, [_vm._v("10天VIP")]),
          _vm._v("!"),
        ]),
        _vm._v(" "),
        _c("img", {
          class: _vm.$style.img_code,
          attrs: { src: _vm.process.env.CDN_XCX_PATH + "/p/code/code2x_5.png" },
        }),
        _vm._v(" "),
        _c("div", { class: _vm.$style.code_tip }, [
          _vm._v("刷新已打开的页面，即可使用VIP权益。"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.code_button, on: { click: _vm.closed } },
          [_c("span", [_vm._v("完成添加VIP专属客服")])]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }