<style lang="scss" module src="./HeaderDropdownTools.scss"></style>
<template>
    <div :class="[$style.dropdownToolsBox]">
        <div class="dropdownMain">
            <ul :class="$style.tools">
                <li v-for="(item,index) in toolList" :class="[$style.toolsItem, item.linkClass?$style[item.linkClass]:'', item.dropdown? 'dropdownOuter': ``]" :key="index" @mouseenter.stop="handleHover(item)"  @mouseleave.stop="hoverCaijiqi=false">
                  <a @click="handleClick(item.name,$event)" :href="item.link ? item.link: 'javascript:void(0)'"  :target="item.link?`_blank`:``">
                    <span :class="[$style.icon, $style[item.iconType]]">
<!--                        <img :class="$style.img" :src="item.src" alt="" :width="item.width">-->
                      <!--                        <img :class="$style.hoverimg" :src="item.hoversrc" alt="" :width="item.width">-->
                    </span>
                    <p :class="[$style.toolsName, 'font-regular']">{{item.name}}</p>
                  </a>
                  <header-ext v-if="!$store.state.server && item.dropdown"
                              class="dropdownWrapper"
                  />
                </li>

            </ul>
        </div>
    </div>
</template>

<script lang=ts>
    import {
        PageCollectionTool,
        PageClient,
        getToken,
        PageGoDesign,
        PageNetworkSearch,
        PageExpandImage,
        PageExplore,
        PageSearchHome,
        PageWhatCollaborateboard,
        ServerWww,
        ServerPrizes,
        PageYasuotu
    } from 'bbw-common';
    import {checkLoginFailed} from "../../scripts/utils";
    import HeaderExt from "../HeaderExt";
    export default {
        props: {
          toolList: {
              type: Array,
              default:()=>[]
          }
        },
        name: "cHeaderDropdownTools",
        components: {
          HeaderExt
        },
        data: function () {
            return {
              hoverCaijiqi: false
            }
        },
        methods:{
          handleHover(item) {
            if (item.dropdown){
              this.hoverCaijiqi = true;
            }else {
              this.hoverCaijiqi = false;
            }
          },
          async handleClick(  name, e:Event ){
            if( name =='大作客户端'){
              e.preventDefault();
              const token = await getToken();
              await this.$store.dispatch("UserInfo/FETCH_CHECK_BROWSER_UNIQUE",{ source : `pc_new` });
              if( this.$store.getters["UserInfo/userReady"]){
                const url = 'https://www.ai-image.net/client.htm?token='+ token;
                window.open(url);
              }else{
                checkLoginFailed()
              }
            }
            if(name === '智能采集插件'){
              return false;
            }
            return;
          }
        },
        computed: {
            ClienLink(){
                return 'https://www.ai-image.net/client.htm?token='+ this.$store.state.token
            }
        }
    }
</script>
