<template>
  <div :class="$style.hotWordsBox" v-if="hotWordList.length > 0" :style="{paddingTop:!isHistoryVal?'23px':''}">
    <div :class="$style.hotWords">
      <div :class="[$style.hotWordsTitle,'font-regular']">热门搜索</div>
      <div :class="$style.hotWordsList">
        <a :class="[$style.hotWordsItem,'font-medium']" v-for="(item, index) in hotWordList" :key="index"
           @mousedown.capture.stop="hotWordToSearch(item.word)"
           @click.prevent.stop :href="`/tupian?kw=${item.word}`">{{ item.word }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "SearchHotWords",
  props:{
    'keyword': {
      type: String,
      default: '',
      required: false
    },
    'isHistoryVal': {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    hotWordToSearch(kw){
      this.$emit('hotWordsList',kw);
      this.$router.push({path:"/tupian",query:{kw:kw}});
    }
  },
  computed:{
    ...mapState({
      "hotWordList": state => state.Data.hotWordList
    })
  }
}
</script>

<style src="./SearchHotWords.scss" module></style>
