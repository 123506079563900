<template>
  <div :class="[$style.userTypeBox]">
    <div :class="[$style.dropdownMain,'dropdownMain']">
      <div :class="[$style['top-content']]" @click.stop="buyVip" >
        <!-- 活动logo图片 -->
        <div :class="$style['activityLogo']"> </div>
        <div :class="$style.usetype">
          <p class="font-medium" v-html="vipName"></p>
          <p v-if="!userInfo.personalVipDays"  :class="[$style.smallTip,userInfo.personalVipDays? $style.smallTip_team: '', 'font-regular']">您的大作团队VIP特权：<span :class="$style.blueFont">{{maxTeamInfo.vip_day}}</span> 天</p>
          <p v-if="userInfo.personalVipDays" :class="[$style.smallTip,userInfo.personalVipDays? $style.smallTip_team: '', 'font-regular']">您的团队版大作VIP特权：<span :class="$style.blueFont">{{maxTeamInfo.vip_day}}</span> 天</p>
          <div v-if="userInfo.personalVipDays" :class="[$style.smallTip_pesonal, 'font-regular']" >
            <div :class="$style.team_tips">
              个人版大作VIP特权：{{ userInfo.personalVipDays }} 天
              <div :class="$style.vip_help_box">
                <ToolTip :class="[$style.vip_help]"
                         placement="bottom"
                         popper-class="viptooltip"
                         :enterable="false"
                         :transition="'bbw-immediately'">
                  <div slot="content" :class="['font-medium']">
                  <span :class="[$style.downVipTip]">
                    <p>团队VIP生效后，个人VIP天数自动冻结,</p>
                    <p>不再消耗个人VIP剩余天数。</p>
                    <p>团队VIP失效后，将自动恢复个人VIP。</p>
                  </span>
                  </div>
                  <div :class="$style.help_icon"></div>
                </ToolTip>
              </div>
            </div>
          </div>
<!--          <p v-if="" :class="[$style.smallTip, 'font-regular']">
            您的大作VIP特权：<span>{{userInfo.vipDays}}</span>天</p>
          <p v-if=""
             :class="[$style.smallTip, 'font-regular']">
            您的大作VIP特权：<span>{{userInfo.vipDays}}天后</span>到期</p>
          <p></p>-->
        </div>
        <button>
          <p class="font-medium">
            续费团队VIP
            <a  v-if="maxTeamInfo.vip_day <= 60">即将到期</a>
          </p>
        </button>
      </div>
      <div :class="$style['bottom-content']" @click.stop="buyVip">
        <div :class="[$style.title, 'font-regular']">
          <p class="font-medium">团队VIP尊享特权</p>
<!--          <p :class="$style.smalltitle">专属客服1对1支持</p>-->
        </div>
        <div :class="$style.wrapList">
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg1]"></span>
            </div>
            <p class="font-bold">快捷协作</p>
<!--            <p :class="$style.small_text">极速版</p>-->
            <p :class="[$style.constr, 'font-regular']">成员一键加入协作</p>
          </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg2]"></span>
            </div>
            <p class="font-bold">实时共享</p>
            <p :class="[$style.constr, 'font-regular']">协作图片集图片</p>
          </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg3]"></span>
            </div>
            <p class="font-bold">讨论、投票</p>
<!--            <p :class="$style.small_text">原生网站</p>-->
            <p :class="[$style.constr, 'font-regular']">高效选方案、定设计</p>
          </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg4]"></span>
            </div>
            <p class="font-bold">资料保障</p>
<!--            <p :class="$style.small_text">原生网站</p>-->
            <p :class="[$style.constr, 'font-regular']">图片集归属团队</p>
          </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg5]"></span>
            </div>
            <p class="font-bold">无限高速下载</p>
            <p :class="[$style.constr, 'font-regular']">专属宽带下载原图</p>
          </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg6]"></span>
            </div>
            <p class="font-bold">赠送</p>
            <p :class="$style.small_text">原生网站</p>
            <p :class="[$style.constr, 'font-regular']">免工具Pinterest搜图</p>
          </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg7]"></span>
            </div>
            <p class="font-bold">赠送</p>
            <p :class="$style.small_text">原生网站</p>
            <p :class="[$style.constr, 'font-regular']">同等天数</p>
          </div>
          <div :class="$style.item">
            <div>
              <span :class="[$style.bg,$style.bg8]"></span>
            </div>
            <p class="font-bold">28项特权</p>
            <p :class="[$style.constr, 'font-regular']">成员VIP尊享</p>
          </div>
        </div>
      </div>
      <div :class="[$style.more,'font-regular']">
        <a @click.prevent.stop="goTo" :href="PageVersionDiff+'?type=2'" target="_blank">查看团队VIP所有特权 ></a>
      </div>
    </div>
  </div>
</template>
<script lang=ts>
    import { PageVersionDiff,beInvitedUserOverdue, buyVip } from "bbw-common";
    import ToolTip from "../ToolTip";
    export default {
        name:"HeaderDropdownVipTeam",
        props:{
            isBBW: {
              type: Boolean,
              required: false,
              default: true
            },
            /** VIP下拉付费来源文字 */
            fromText:{
                type:String,
                required: false,
                default: "vip下拉"
            },
            /** VIP下拉付费来源数字 */
            fromType:{
                type:Number,
                required: false,
                default: 6
            },
            /** VIP查特权付费来源文字 */
            fromTextPrivilege:{
                type:String,
                required: false,
                default: "特权对比页按钮"
            },
            /** VIP查特权来源数字 */
            fromTypePrivilege:{
                type:Number,
                required: false,
                default: 7
            },
            /** vip天数最多的团队*/
            maxTeamInfo: {
                type: Object,
                required: false,
                default: {}
            }
        },
      components:{
          ToolTip
        },
        data(){
            return {
                PageVersionDiff,
            }
        },
        mounted() {

        },
        methods: {
            buyVip: function () {
                buyVip("bbwTeamRenew", this.$store, this.fromText, this.fromType, !this.isBBW, this.maxTeamInfo.uuid )
            },
            goTo:function () {
                buyVip("bbwTeamRenew", this.$store, this.fromTextPrivilege, this.fromTypePrivilege,!this.isBBW,this.maxTeamInfo.uuid)
            }
        },
        computed: {
          vipName(){
              return '团队VIP用户'
          },
          userInfo(){
              return this.$store.state.UserInfo;
          }
        }
    };
</script>
<style src="./HeaderDropdownVipTeam.scss" module></style>
