var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "msg-box" } }, [
    _c(
      "div",
      { staticClass: "dropdownMain", attrs: { id: "msg-cont" } },
      [
        _c("div", { staticClass: "first-level-title" }, [
          _c("span", {
            staticClass: "close-icon",
            on: { click: _vm.closeNotifi },
          }),
          _vm._v("\n                    消息\n                "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "first-level-content" }, [
          _c("ul", [
            _c(
              "li",
              {
                staticClass: "first-level-li",
                on: {
                  click: function ($event) {
                    return _vm.showNotificationBox("coopreation")
                  },
                },
              },
              [
                _c("div", {
                  class: ["first-level-icon", _vm.firstLevelData[0].iconType],
                }),
                _vm._v(" "),
                _c("div", { staticClass: "li-cont" }, [
                  _vm._v(
                    _vm._s(_vm.firstLevelData[0].title) +
                      "\n                                "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.unReadCount.cooperation > 0,
                          expression: "unReadCount.cooperation > 0",
                        },
                      ],
                      staticClass: "unread-count",
                    },
                    [_vm._v(_vm._s(_vm.unReadCount.cooperation))]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "first-level-li",
                on: {
                  click: function ($event) {
                    return _vm.showNotificationBox("follow")
                  },
                },
              },
              [
                _c("div", {
                  class: ["first-level-icon", _vm.firstLevelData[1].iconType],
                }),
                _vm._v(" "),
                _c("div", { staticClass: "li-cont" }, [
                  _vm._v(
                    _vm._s(_vm.firstLevelData[1].title) +
                      "\n                                "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.unReadCount.follow > 0,
                          expression: "unReadCount.follow > 0",
                        },
                      ],
                      staticClass: "unread-count",
                    },
                    [_vm._v(_vm._s(_vm.unReadCount.follow))]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "first-level-li",
                on: {
                  click: function ($event) {
                    return _vm.showNotificationBox("notification")
                  },
                },
              },
              [
                _c("div", {
                  class: ["first-level-icon", _vm.firstLevelData[2].iconType],
                }),
                _vm._v(" "),
                _c("div", { staticClass: "li-cont" }, [
                  _vm._v(
                    _vm._s(_vm.firstLevelData[2].title) +
                      "\n                                "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.unReadCount.notice > 0,
                          expression: "unReadCount.notice > 0",
                        },
                      ],
                      staticClass: "unread-count",
                    },
                    [_vm._v(_vm._s(_vm.unReadCount.notice))]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "first-level-li",
                on: {
                  click: function ($event) {
                    return _vm.showNotificationBox("notice")
                  },
                },
              },
              [
                _c("div", {
                  class: ["first-level-icon", _vm.firstLevelData[3].iconType],
                }),
                _vm._v(" "),
                _c("div", { staticClass: "li-cont" }, [
                  _vm._v(
                    _vm._s(_vm.firstLevelData[3].title) +
                      "\n                                "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.unReadCount.announcement > 0,
                          expression: "unReadCount.announcement > 0",
                        },
                      ],
                      staticClass: "unread-count",
                    },
                    [_vm._v(_vm._s(_vm.unReadCount.announcement))]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "secondary-box" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.coopreation,
                  expression: "coopreation",
                },
              ],
              staticClass: "secondary-box",
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n                            协作\n                            "
                ),
                _c("span", {
                  staticClass: "close-icon",
                  on: {
                    click: function ($event) {
                      return _vm.closeThis("coopreation")
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "view-us-coopreation",
                    on: { click: _vm.viewBoard },
                  },
                  [_vm._v("查看我的协作")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "secondary-cont",
                  staticStyle: { height: "392px" },
                },
                [
                  _c("Vuescroll", { attrs: { ops: _vm.scrollOps } }, [
                    _c(
                      "ul",
                      _vm._l(_vm.coopreationMsg, function (item, index) {
                        return _c("li", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: true,
                              expression: "true",
                            },
                          ],
                          class: [item.status === 0 ? "no-read" : ""],
                          domProps: { innerHTML: _vm._s(item.content) },
                        })
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "secondary-box" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.follow,
                  expression: "follow",
                },
              ],
              staticClass: "secondary-box",
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n                            关注\n                            "
                ),
                _c("span", {
                  staticClass: "close-icon",
                  on: {
                    click: function ($event) {
                      return _vm.closeThis("follow")
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "view-us-coopreation",
                    on: { click: _vm.viewFans },
                  },
                  [_vm._v("查看我的粉丝")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "secondary-cont",
                  staticStyle: { height: "392px" },
                },
                [
                  _c("Vuescroll", { attrs: { ops: _vm.scrollOps } }, [
                    _c(
                      "ul",
                      _vm._l(_vm.followMsg, function (item, index) {
                        return _c("li", {
                          class: [item.status === 0 ? "no-read" : ""],
                          domProps: { innerHTML: _vm._s(item.content) },
                        })
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "secondary-box" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.notification,
                  expression: "notification",
                },
              ],
              staticClass: "secondary-box",
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n                            通知\n                            "
                ),
                _c("span", {
                  staticClass: "close-icon",
                  on: {
                    click: function ($event) {
                      return _vm.closeThis("notification")
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "view-us-coopreation" }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "secondary-cont",
                  staticStyle: { height: "392px" },
                },
                [
                  _c("Vuescroll", { attrs: { ops: _vm.scrollOps } }, [
                    _c(
                      "ul",
                      { ref: "notification-list" },
                      [
                        false
                          ? _c("transition", { attrs: { name: "noti-box" } }, [
                              _c("li", {}, [
                                _c("div", { staticClass: "cnt-noti" }, [
                                  _c("div", { staticClass: "ava-box" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "avatar pointer no-radius",
                                        attrs: { href: "javascript: void(0)" },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.process.env.CDN_XCX_PATH +
                                              "/p/images/msg-invite-status.svg",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("p", [
                                      _vm._v("恭喜!您邀请的好友成为VIP！"),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v("预计可返现："),
                                      _c("span", { staticClass: "black-m" }, [
                                        _vm._v("20"),
                                      ]),
                                      _vm._v("元"),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v("您的VIP特权已过期，只需在"),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("span", { staticClass: "black-m" }, [
                                        _vm._v("14天内"),
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "link link-inline",
                                          attrs: { href: "" },
                                        },
                                        [_vm._v("再次成为VIP")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v("这笔现金奖励可为您保留哦！"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "push-time" }, [
                                      _vm._v("一天前"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "del-this" }),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.notificationMsg, function (item, index) {
                          return true
                            ? _c(
                                "transition",
                                { key: item.id, attrs: { name: "noti-box" } },
                                [
                                  item.show
                                    ? _c(
                                        "li",
                                        {
                                          class: [
                                            item.status === 0 ? "no-read" : "",
                                          ],
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "cnt-noti",
                                            domProps: {
                                              innerHTML: _vm._s(item.content),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass: "del-this",
                                            on: {
                                              click: function ($event) {
                                                return _vm.delThisMsg(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "secondary-box" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.notice,
                  expression: "notice",
                },
              ],
              staticClass: "secondary-box",
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n                            公告\n                            "
                ),
                _c("span", {
                  staticClass: "close-icon",
                  on: {
                    click: function ($event) {
                      return _vm.closeThis("notice")
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "secondary-cont",
                  staticStyle: { height: "392px" },
                },
                [
                  _c("Vuescroll", { attrs: { ops: _vm.scrollOps } }, [
                    _c(
                      "ul",
                      { ref: "secondary-list" },
                      _vm._l(_vm.noticeMsg, function (item, index) {
                        return _c(
                          "li",
                          {
                            class: [
                              item.status === 0 ? "no-read" : "",
                              "msg-system",
                              item.link ? "linkBoxHover" : "",
                            ],
                            on: {
                              click: function ($event) {
                                item.link ? _vm.goToLink(item.link) : ""
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "ava-box" }, [
                              _c("div", { staticClass: "avatar" }, [
                                _c("img", { attrs: { src: item.avatar } }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "system-content" }, [
                              _c("div", {
                                staticClass:
                                  "cop-cont msg-system-title black-m",
                                domProps: { innerHTML: _vm._s(item.title) },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "msg-system-detail" }, [
                                _vm._v(
                                  "\n                                                " +
                                    _vm._s(item.content) +
                                    "\n                                                "
                                ),
                                !!item.link
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "sys-link",
                                        attrs: {
                                          target: "black",
                                          href: item.link,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.link_font))]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "push-time" }, [
                                _vm._v(_vm._s(item.datetime_str)),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }