<template>
  <div v-if="$store.state.token" class="c-header c-globalHeader" :class="$style.headerNavBar">
    <div class="c-header-l" style="margin-left: 0">
      <div class="c-header-li font-regular" style="line-height: 24px;margin-left: 0;font-size: 16px" >
        Hi，欢迎来到Pinlite极速版！
      </div>
      <div class="c-header-li">
        <HeaderNavItem type="xs" link="/">极速版首页</HeaderNavItem>
      </div>
      <div class="c-header-li font-medium" :class="$style.mobile_header" @click="moveUseClick">
         移动版
<!--         <img :class="$style.animate__shakeX" src="../../../assets/images/pinlite_new_green_small.svg"/>-->
      </div>
    </div>
    <header-navigation-right-items :isBBW="false" :show-list="['pinliteBackToSearch','vip', 'myCloud']" :stats-vip="statsVip"/>
  </div>
</template>
<script lang=ts>
  import {
      HeaderDropdownUser,
      HeaderUserArea,
      HeaderNavItem,
      HeaderDropdownVip,
      HeaderDropdownVipTeam,
      HeaderVipTips,
      HeaderNavigationRightItems
  } from "bbw-components";
  import {
    PageSearch,
    PageSearchIndex,
    PageSearchEngine,
    PageSearchHome,
    PagePinliteUnlogin,
    PageVersionDiff,
    BigBigWork, buyVip, ServerHomePage, PageSearchAuth, getAuthUrl, statSearchRedirect
  } from "bbw-common";
  import HeaderDropdownVipBBW from "@components/HeaderDropdownVipBBW/HeaderDropdownVipBBW.vue";

  export default {
      components: {
        HeaderDropdownUser,
        HeaderUserArea,
        HeaderNavItem,
        HeaderDropdownVip,
        HeaderDropdownVipTeam,
        HeaderDropdownVipBBW,
        HeaderVipTips,
        HeaderNavigationRightItems
      },
      data(){
          return {
              PageSearch,
              PageSearchIndex,
              PageSearchEngine,
              PagePinliteUnlogin,
              PageSearchHome,
              ImgCodeSrc:'',
              isShowBindPhone:false,
              statsVip: {
                fromText: "PL-下拉",
                fromType: 46,
                fromTextPrivilege: "PL-下拉-查特权",
                fromTypePrivilege: 46
              }
          }
      },
      methods:{
        moveUseClick(){
          if(this.$route.path.includes('/tupian/image/')){
            BigBigWork.emit('SHOWPINDPHONETUPIAN')
          }else{
            BigBigWork.emit('SHOWPINDPHONE')

          }
        },
        buyVip: function (text) {
          const fromText = this.userInfo.userType==6||this.userInfo.userType==7 ? "pl下拉-续费大作vip" : "pl下拉-开通大作vip"
          if (this.maxTeamInfo.vip_day){
            buyVip("bbwTeamRenew", this.$store, this.fromText, this.fromType, true, this.maxTeamInfo.uuid)
          }else{
            buyVip('bbw',this.$store, text || fromText,33)
          }
        },
        getAuthUrl( url ){
          return getAuthUrl( this.$store, url )
        },
        stats(data){
          // 跳转统计
          statSearchRedirect({redirect_url:data,action_place:'导航-pl',action_source:'pc'},this.$store)
        }

      },
      computed:{
        bbwIndex(){
          return `${PageSearchAuth}?token=${this.$store.state.token}&redirect=${encodeURIComponent(`/home`)}`
        },
          /*vipIcon:function () {
              return this.$store.getters[`UserInfo/isVip`]
                  ? `https://xcx.bigbigwork.com/pimg/red_diamond1.png`
                  : `https://dzimg.bigbigwork.com/p/icon_novip.png`
          },*/
        userInfo(){
          return this.$store.state.UserInfo;
        },
        vipIcon: function () {
          return this.$store.getters[`UserInfo/isVip`]
                  ? `${process.env.CDN_XCX_PATH}/p/search/header_icon_vip_v6.svg?1`
                  : `${process.env.CDN_XCX_PATH}/p/search/header_novip_1x_v5.gif`
          /*return this.$store.getters[`UserInfo/isVip`]
              ? `https://xcx.bigbigwork.com/p/search/header_icon_vip_v5.svg?1`
              : `https://xcx.bigbigwork.com/p/search/header_novip_1x_v4.gif`*/
        },
        vipIconHover: function () {
          return this.$store.getters[`UserInfo/isVip`]
                  ? `${process.env.CDN_XCX_PATH}/p/search/header_icon_vip_hover_v6.svg?1`
                  : `${process.env.CDN_XCX_PATH}/p/search/header_novip_1x_v5.svg`
          /*return this.$store.getters[`UserInfo/isVip`]
              ? `https://xcx.bigbigwork.com/p/search/header_icon_vip_hover_v5.svg?1`
              : `https://xcx.bigbigwork.com/p/search/header_novip_1x_v4.svg`*/
        },
        vipIconWidth: function () {
          return this.$store.getters[`UserInfo/isVip`] ? '24' : '38'
        },
          showUser:function () {
              return !this.$store.state.server && this.$store.getters["UserInfo/userReady"]
          },
          vip(){
              return `${PageVersionDiff}?type=33`
          },
          maxTeamInfo(){
            const maxTeamInfo = this.$store.state.UserInfo.maxTeamInfo;
            return {...maxTeamInfo}
          }
      }
  }
</script>


<style module src="./HeaderGlobal.scss"></style>
