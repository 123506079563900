var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navItemBk",
      class: _vm.styleName,
      on: { click: _vm.handleClick },
    },
    [
      !_vm.link && !_vm.href
        ? _c(
            "span",
            {
              class: [
                "navItem_text",
                "font-medium",
                _vm.themeColor,
                _vm.customClass,
              ],
            },
            [
              _vm.iconType || _vm.icon
                ? _c("span", { staticClass: "navItem_iconBox" }, [
                    !_vm.iconType
                      ? _c("img", {
                          staticClass: "navItem_icon",
                          style: { width: _vm.gifimgW + "px" },
                          attrs: { src: _vm.icon },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.iconType
                      ? _c("img", {
                          class: ["navItem_icon", "navItem_hoverIcon"],
                          style: { width: _vm.iconWidth + "px" },
                          attrs: { src: _vm.hoverIcon || _vm.icon },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.iconType
                      ? _c("div", {
                          class: ["navItem_icon", _vm.iconType],
                          style: _vm.iconStyle,
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "navItem_des" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _vm._t("tips"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.link && _vm.href
        ? _c(
            "a",
            {
              class: [
                "navItem_link",
                "font-medium",
                _vm.$route.name === _vm.name ? "router-link-active" : "",
                _vm.themeColor,
                _vm.customClass,
              ],
              attrs: {
                href:
                  _vm.capture && _vm.ready ? "javascript:void(0);" : _vm.href,
                rel: _vm.isNoFollow ? "nofollow" : "",
                title: _vm.aTitle,
                target: _vm.target,
              },
            },
            [
              _vm.iconType
                ? _c("span", { staticClass: "navItem_iconBox" }, [
                    _c("div", {
                      class: ["navItem_icon", _vm.iconType],
                      style: _vm.iconStyle,
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "navItem_des" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _vm._t("tips"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.link
        ? _c(
            "router-link",
            {
              class: [
                "navItem_link",
                "font-medium",
                _vm.themeColor,
                _vm.customClass,
              ],
              attrs: { to: _vm.link },
            },
            [
              _vm.iconType
                ? _c("span", { staticClass: "navItem_iconBox" }, [
                    _c("div", {
                      class: ["navItem_icon", _vm.iconType],
                      style: _vm.iconStyle,
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "navItem_des" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _vm._t("tips"),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }