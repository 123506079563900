<style lang="scss" module src="./DialogVipOfferDesc.scss"></style>
<template>
    <el-dialog :visible.sync="isShow"
               custom-class="dialogOfferDesc"
               top="0px"
               @open="onOpen"
               @close="handleClose"
                width="540px"
               :modal-append-to-body="true"
                :append-to-body="true">
        <div :class="$style.offerdesc">
            <p :class='$style.title'>您距离获得VIP特权仅差一步！</p>
            <p :class="$style['sm-title']">-已有{{number}}万设计师使用VIP服务-</p>
            <div :class="$style['desc-content']">
                <div :class="$style['effective-today']">
        <span>
          {{priceManager.currentPrice.act_title1}}
        </span>
                </div>
                <div :class="$style['offer-word']">
                    <span>{{priceManager.currentPrice.act_title2}}</span>
                    <span>{{priceManager.currentPrice.act_title3}}</span>
                </div>
            </div>
            <div :class="$style['btn-box']">
                <button @click="goBack">狠心离开</button>
                <button :class="$style.again" @click="again">再考虑下</button>
            </div>
        </div>
    </el-dialog>
</template>
<script lang=ts>
    import Vue from 'vue'
    import {BigBigWork,BBWpriceManager} from "bbw-common";
    import Cookie from 'js-cookie'
    const Dialog = require("element-ui/lib/dialog").default;
    Vue.use(Dialog)
    export default {
        name: 'cDialogVipOfferDesc',
        data: function () {
            return {
                isShow: true,
                priceManager: {currentPrice:{act_title1:'',act_title2:'',act_title3:''},priceList:[{act_title1:'',act_title2:'',act_title3:''}],currentIndexForDelay:0},
                number: 0
            }
        },
        methods: {
            handleClose () {
            },

            goBack() {
                //关闭优惠弹框和付费弹框
                this.isShow = false
                this.$emit('closeDialogVip')
                _hmt.push(['_trackEvent','付费二次弹框','狠心离开'])
            },
            again() {
                //  只关闭优惠弹框
                this.isShow = false
                //触发更新
                // this.priceManager.refreshOrader()
                BigBigWork.emit('OfferDialogToReferPrice')
                _hmt.push(['_trackEvent','付费二次弹框','再考虑下'])
            },
            onOpen() {
            },
        },
        mounted(): void {
            this.priceManager = BBWpriceManager.priceManager
            var number = Number(parseInt(Cookie.get('number')));
            this.number = parseInt(String(number))
        }
    }
</script>
