<template>
    <!-- 下拉-->
    <div :class="[$style.menu, styleName]">
        <ul class="dropdownMain" :class="[$style.menu, 'font-regular']">
            <li :class="$style.menuItem">
                <a @click="userClick" :href="dz" target="_blank">
                  <span :class="[$style.icon, $style.hasBorder]">
                     <img :src="avatar" alt="">
                   </span>
                    我的设计云</a>
            </li>
            <li :class="$style.menuItem">
                <a @click.prevent="handleNotifi" :href="invite" target="_blank">
                    <span :class="[$style.icon, $style.invite_icon, $style.xx]"></span>
                    消息
                    <span v-show="notifiTotal" :class="$style.notifiNum">{{notifiTotal}}</span>
                </a>
            </li>
          <li :class="$style.menuItem">
            <a :href="invite" target="_blank">
              <span :class="[$style.icon, $style.invite_icon, $style.yq]">
<!--                <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/header_user_invite_icon_hover_v2.svg" alt="">-->
<!--                <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/header_user_invite_icon_v2.svg" alt="">-->
              </span>
              邀好友赚现金
            </a>
          </li>
            <li :class="$style.menuItem">
                <a :href="setting" target="_blank">
          <span :class="[$style.icon, $style.sz]">
<!--            <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/header_user_edit.svg" alt="">-->
<!--            <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/header_user_edit_hover.svg" alt="">-->
          </span>
                    个人设置</a>
            </li>
            <li :class="$style.menuItem">
                <a :href="designer" target="_blank">
          <span :class="[$style.icon, $style.rz]">
<!--            <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/header_user_designer.svg" alt="">-->
<!--            <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/header_user_designer_hover.svg" alt="">-->
          </span>
                    认证设计师</a>
            </li>
            <li :class="$style.menuItem">
                <a :href="help" target="_blank">
          <span :class="[$style.icon, $style.bz]">
<!--            <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/header_user_help.svg" alt="">-->
<!--            <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/header_user_help_hover.svg" alt="">-->
          </span>
                    帮助中心</a>
            </li>
            <li :class="$style.menuItem" style="height: 44px">
                <a @click.prevent.stop="goLoginOut(redirect,url)" href="javascript:void(0)">
          <span :class="[$style.icon, $style.tc]" style="top: 50%">
<!--            <img :class="$style.image" src="https://xcx.bigbigwork.com/p/search/header_user_exit.svg" alt="">-->
<!--            <img :class="$style.imageHover" src="https://xcx.bigbigwork.com/p/search/header_user_exit_hover.svg" alt="">-->
          </span>
                    退出大作</a>
            </li>
        </ul>
    </div>
</template>
<script lang="ts">
import {
  goLoginOut,
  PageCenter,
  PageSearchEngineNew,
  PageWiki,
  toRelativeProtocol,
  PageInvite,
  getAuthUrl
} from "bbw-common";

    export default {
        name: "cHeaderDropdownUser",
        props: {
            notifiTotal:{
                type: Number,
                required: false,
                default: 0
            },
            /** 退出后跳转去的页面 */
            redirect: {
                type: String,
                required: false,
                default: ''
            },
            /** 不同样式对应不同的适配 */
            type: {
                type: String,
                required: false,
                default: 'xs'
            },
            /** 是否要通过auth页面登录跳转 */
            auth: {
              type: Boolean,
              required:false,
              default:false
            },
          /** 大库退出登录跳转地址 */
          url:{
            type: String,
            required: false,
            default: ''
          }
        },
        methods:{
            userClick(){
                this.$emit('userClick')
            },
            handleNotifi(){
                this.$emit('notification')
            }
        },
        data: function () {
            return {
                /** 帮助页面 */
                help: PageWiki,
                goLoginOut,
                PageInvite
            }
        },
        computed: {
            /** 用户头像 */
            avatar() {
              return toRelativeProtocol(this.$store.state.UserInfo.avatar)
            },
            /** 我的设计云页面 */
            dz() {
              const url = `${PageSearchEngineNew}${this.$store.state.UserInfo.id}/sy`;
              if( this.auth ){
                return getAuthUrl(this.$store, url );
              }else{
                return url;
              }
            },
            /** 大作认证设计师页面 */
            designer() {
              const url = `${PageSearchEngineNew}${this.$store.state.UserInfo.id}/CertifiedDesigner/?id=${this.$store.state.Designer.status}&time=${this.$store.state.Designer.againApplicationDate ? this.$store.state.Designer.againApplicationDate : ""}`
              if( this.auth ){
                return getAuthUrl(this.$store, url );
              }else{
                return url;
              }
            },
            invite(){
              const url = PageInvite;
              if( this.auth ){
                return getAuthUrl(this.$store, url );
              }else{
                return url;
              }
            },
            setting(){
              const url = PageCenter;
              if( this.auth ){
                return getAuthUrl(this.$store, url );
              }else{
                return url;
              }
            },
            /** 根据props.type决定样式名 */
            styleName: function () {
                let custom = "";
                switch (this.type) {
                    case 'xs'  :
                        custom = this.$style.xs;
                        break;
                    case 'small' :
                        custom = this.$style.small;
                        break;
                    case 'middle' :
                        custom = this.$style.middle;
                        break;
                    default:
                        custom = this.$style.xs;
                }
                return custom;
            },
        }
    };
</script>
<style lang=scss module src="./HeaderDropdownUser.scss"></style>
