import Vue, { VueConstructor } from "vue" ;
import Hintvue from "./Hint.vue" ;
import Vuex, { Store } from "vuex" ;
Vue.use(Vuex);

export default class Hint {
    content: string;
    image: string;
    template: unknown | VueConstructor;
    fadeDelay: number;
    leaveToType: string;
    theme:string;
    view: any;
    child:any;
    PropsData:unknown;
    appendToBody:boolean;
    noBoard:boolean;
    store:Store<unknown>;
    hoverAble:Boolean;
    resetTime:Number;
    constructor ( options ) {
        options = options || {} ;
        /**
         * @description 内容文字
         */
        this.content = options.content || `` ;
        /**
         * @description 图片
         */
        this.image = options.image || null ;
        /**
         * @description 模板视图
         */
        this.template = options.template || Hintvue ;
        /**
         * @description 多少时间后消失（0：不消失）
         * @type {*|number}
         */
        this.fadeDelay = options.fadeDelay || 0 ;
        /**
         * @description 自定义类名
         * @type {string}
         */
        this.leaveToType = options.leaveToType || `` ;
        /**
         * @description 是否要边框
         * @type {boolean}
         */
        this.noBoard = options.noBoard || false ;
        /**
         * @description 自定义类名
         * @type {string}
         */
        this.theme = options.theme || `collection-tipsbox` ;
        /**
         * @description 视图
         * @type {null}
         */
        this.view = null ;
        /**
         * @description 子视图
         */
        this.child = options.child || null ;
        /**
         * @description 传递给子视图的数据
         */
        this.PropsData = options.PropsData || {} ;
        /**
         * @description Hint要 不要插入body里面，预设 true
         */
        this.appendToBody = options.appendToBody || document.body ;
        /**
         * @description Hint能不能鼠标悬停 使hint不消失 预设不可以
         */
        this.hoverAble = options.hoverAble || false ;
        /**
         * @description Hint能鼠标悬停时 鼠标移出时 重置的消失时间
         */
        this.resetTime = options.resetTime || options.fadeDelay || null ;
        /** store */
        this.store = options.store || new Store({ state: {} });
        this.init () ;
    }

    init () {
        var _template = Vue.extend ( this.template || Hintvue ) ;
        this.view = new _template ( {
            store: this.store,
            el: document.createElement ( `div` )
        } ) ;
        this.view.appendToBody = this.appendToBody ;
        this.view.image = this.image ;
        this.view.content = this.content ;
        this.view.theme = this.theme ;
        this.view.child = this.child ;
        this.view.leaveToType = this.leaveToType ;
        this.view.PropsData = this.PropsData ;
        this.view.hoverAble = this.hoverAble ;
        this.view.resetTime = this.resetTime ;
        this.view.noBoard = this.noBoard ;
        this.show () ;
    }

    show () {
        var _self = this ;
        this.view.show () ;
        if ( this.fadeDelay ) {
            this.view.setTimeoutEvent = window.setTimeout ( function () {
                _self.hide () ;
            }, _self.fadeDelay ) ;
        }
    }

    hide () {
        this.view.hide () ;
    }
}
